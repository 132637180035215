import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Exercise } from "@/utils/entities";
import { getDateFormatText } from "@/utils/functions/date-transform";

type Props = {
  isOpened: boolean;
  exercise: Exercise;
  handleClose: () => void;
};

export default function SheetDialogExerciseExecution({
  exercise,
  isOpened = false,
  handleClose,
}: Props) {
  return (
    <Dialog open={isOpened} onOpenChange={handleClose}>
      <DialogContent className="sm:max-w-md bg-white">
        <DialogHeader>
          <DialogTitle>Lista de execuções</DialogTitle>
        </DialogHeader>
        <div className="flex items-center space-x-2 mt-3">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead className="text-gray-400 md:w-[200px]">
                  Data
                </TableHead>
                <TableHead className="text-gray-500 min-w-[200px]">
                  Carga utilizada
                </TableHead>
                <TableHead />
              </TableRow>
            </TableHeader>
            <TableCaption>
              {!exercise?.execution?.trainingExerciseExecutions?.length ? (
                <span>
                  Não há execuções realizada para o exercício selecionado
                </span>
              ) : null}
            </TableCaption>
            <TableBody>
              {exercise?.execution?.trainingExerciseExecutions?.map((item) => (
                <TableRow key={item?.id}>
                  <TableCell>{getDateFormatText(item?.date)}</TableCell>
                  <TableCell>{item.load_used}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
        <DialogFooter className="sm:justify-end">
          <Button type="button" variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
