import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { EditIcon, Plus } from "lucide-react";
import { useCallback, useEffect, useState } from "react";
import MemberCategoryForm from "./form";
import { MemberCategory, StudentGroup } from "@/utils/entities";
import ModalConfirmDelete from "@/components/modal-confirm";
import { useQuery } from "@tanstack/react-query";
import { MemberCategoryService } from "@/services/api/member-category";

const MemberCategoryList: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [formIsOpen, setFormIsOpen] = useState(false);
  const [deleteIsOpen, setDeleteIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<MemberCategory>();

  const { data, refetch } = useQuery({
    queryKey: ["members_categories"],
    queryFn: MemberCategoryService.getAll,
  });

  const openToDelete = (item: MemberCategory) => {
    setSelectedItem(item);
    setDeleteIsOpen(true);
  };

  const cancelToDelete = () => {
    setSelectedItem(undefined);
    setDeleteIsOpen(false);
  };

  const openForm = (item?: MemberCategory) => {
    if (item) {
      setSelectedItem(item);
    }

    setFormIsOpen(true);
  };

  const closeForm = () => {
    setFormIsOpen(false);
    setSelectedItem(undefined);
  };

  const saveForm = async (values: { name: string }) => {
    await MemberCategoryService.createOrUpdate({ ...selectedItem, ...values });

    closeForm();

    refetch();
  };

  const deleteStudentGroup = () => {};

  return (
    <Card className="bg-gray-800">
      <CardHeader>
        <div className="flex justify-between">
          <CardTitle className="flex text-gray-200 items-center justify-between">
            Categoria para grupos de membros
          </CardTitle>

          <Button className="px-4" onClick={() => openForm()}>
            <Plus className="mr-2" /> Nova categoria
          </Button>
        </div>
      </CardHeader>
      <CardContent className="grid w-full overflow-auto">
        {isLoading ? (
          "Loading..."
        ) : (
          <Table>
            <TableCaption>Categorias de conteudo para membros.</TableCaption>
            <TableHeader>
              <TableRow>
                <TableHead className="text-gray-400 w-[calc(50% - 300px)]">
                  Nome da categoria
                </TableHead>
                <TableHead className="w-[10px]" />
              </TableRow>
            </TableHeader>
            <TableBody className="text-gray-200">
              {data?.map((item) => (
                <TableRow key={item?.id}>
                  <TableCell>{item.name}</TableCell>
                  <TableCell className="text-right space-x-2 pr-0 flex items-center justify-end">
                    <Button
                      variant="outline"
                      size="sm"
                      onClick={() => openForm(item)}
                    >
                      <EditIcon className="h-4 w-4 mr-2" /> Editar
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </CardContent>

      <MemberCategoryForm
        onSubmit={saveForm}
        onClose={closeForm}
        isOpen={formIsOpen}
        initialValues={selectedItem}
      />

      <ModalConfirmDelete
        isVisible={!!deleteIsOpen}
        onCancel={cancelToDelete}
        onContinue={deleteStudentGroup}
        title="Confirmação de deletação"
        description={
          <>
            Você tem certeza que quer
            <strong> EXCLUIR {selectedItem?.name}</strong>?
          </>
        }
      />
    </Card>
  );
};

export default MemberCategoryList;
