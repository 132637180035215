import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import Routes from "@/routes/paths";
import { Link } from "react-router-dom";

export default function SignUpScreen() {
  return (
    <div className="relative flex flex-col justify-center items-center min-h-screen overflow-hidden bg-dark-background">
      <div className="w-full m-auto lg:max-w-lg">
        <Card>
          <CardHeader className="space-y-1">
            <CardTitle className="text-2xl">GymAPP</CardTitle>
            <CardDescription>Crie uma conta para iniciar</CardDescription>
          </CardHeader>
          <CardContent className="grid gap-4">
            <div className="grid gap-2">
              <Label htmlFor="username">Nome de usuário</Label>
              <Input id="username" name="username" />
            </div>
            <div className="grid gap-2">
              <Label htmlFor="email">Email</Label>
              <Input id="email" type="email" name="email" />
            </div>
            <div className="grid gap-2">
              <Label htmlFor="password">Senha</Label>
              <Input id="password" type="password" name="password" />
            </div>
          </CardContent>
          <CardFooter>
            <Button className="w-full">Acessar</Button>
          </CardFooter>
          <p className="text-sm text-center text-gray-700 mb-3">
            <Link
              to={Routes.base}
              className="hover:text-brand underline underline-offset-4 ml-2"
            >
              Voltar
            </Link>
          </p>
        </Card>
      </div>
    </div>
  );
}
