import ButtonDropdown from "@/components/button-dropdown";
import RootLayout from "@/components/layout";
import ModalConfirmDelete from "@/components/modal-confirm";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import {
  DropdownMenuItem,
  DropdownMenuSeparator,
} from "@/components/ui/dropdown-menu";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { useToast } from "@/components/ui/use-toast";
import Routes from "@/routes/paths";
import { ProfessionalService } from "@/services/api/professional";
import { UserService } from "@/services/api/user";
import { Professional, UserStatus } from "@/utils/entities";
import { getToastErrorContent } from "@/utils/functions/api-error";
import { useQuery } from "@tanstack/react-query";
import { PlusIcon } from "lucide-react";
import { useState } from "react";
import { Link, generatePath } from "react-router-dom";

export default function ProfessionalListScreen() {
  const { toast } = useToast();
  const [professional, setProfessional] = useState<Professional | null>(null);

  const { data, refetch } = useQuery({
    queryKey: ["professionals"],
    queryFn: ProfessionalService.getAll,
  });

  const onUpdateStatus = (item: Professional) => async () => {
    try {
      if (!item || !item.user) {
        return;
      }

      const { user } = item;

      const status =
        user.status === UserStatus.ACTIVE
          ? UserStatus.INACTIVE
          : UserStatus.ACTIVE;

      await UserService.update({ id: user.id, status });

      refetch();

      setProfessional(null);

      toast({
        variant: "success",
        description: "Profissional atualizado com sucesso.",
      });
    } catch (error) {
      toast(getToastErrorContent(error));
    }
  };

  return (
    <RootLayout>
      <Card className="bg-gray-800">
        <CardHeader>
          <CardTitle className="flex text-gray-200 items-center justify-between">
            Profissionais
            <Button
              asChild
              variant="outline"
              size="default"
              className="bg-transparent"
            >
              <Link to={Routes.professional.create}>
                <PlusIcon size={18} className="mr-2" />
                Novo profissional
              </Link>
            </Button>
          </CardTitle>
        </CardHeader>
        <CardContent className="grid gap-6">
          <Table>
            <TableCaption>Lista de profissionais.</TableCaption>
            <TableHeader>
              <TableRow>
                <TableHead className="w-[100px]" />
                <TableHead className="text-gray-400">Nome</TableHead>
                <TableHead className="text-gray-400 w-[200px]">
                  Telefone
                </TableHead>
                <TableHead className="text-gray-400">email</TableHead>
                <TableHead className="text-gray-400">Data Nascimento</TableHead>
                <TableHead className="text-gray-400  w-[100px]">
                  Status
                </TableHead>
                <TableHead className="w-[100px] text-right" />
              </TableRow>
            </TableHeader>
            <TableBody className="text-gray-200">
              {data?.map((item) => (
                <TableRow key={item?.id}>
                  <TableCell className="font-medium">
                    <Avatar className="border">
                      <AvatarImage src={item?.user?.image_url as string} />
                      <AvatarFallback className="uppercase text-zinc-800">
                        {item?.user?.name.substring(0, 2)}
                      </AvatarFallback>
                    </Avatar>
                  </TableCell>
                  <TableCell>
                    {item?.user?.full_name}
                  </TableCell>
                  <TableCell>{item?.user?.phone}</TableCell>
                  <TableCell>{item?.user?.email}</TableCell>
                  <TableCell>{item?.birth_date_text}</TableCell>
                  <TableCell>{item?.user?.statusText}</TableCell>
                  <TableCell className="text-right">
                    <ButtonDropdown>
                      <Link
                        to={generatePath(Routes.professional.edit, {
                          id: item?.id,
                        })}
                      >
                        <DropdownMenuItem className="cursor-pointer">
                          Editar
                        </DropdownMenuItem>
                      </Link>

                      <DropdownMenuSeparator />

                      <DropdownMenuItem
                        className="cursor-pointer"
                        onClick={() => setProfessional(item)}
                      >
                        {item?.user?.status === UserStatus.INACTIVE
                          ? "Ativar"
                          : "Inativar"}
                      </DropdownMenuItem>
                    </ButtonDropdown>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>

      <ModalConfirmDelete
        isVisible={!!professional}
        onCancel={() => setProfessional(null)}
        onContinue={onUpdateStatus(professional as Professional)}
        description={
          professional?.user.status === UserStatus.ACTIVE ? (
            <>
              Isso irá <strong>bloquear</strong> o acesso do profissional e
              todos seus alunos ao site web e aplicativo.
            </>
          ) : (
            <>
              Isso irá <strong>desbloquear</strong> o acesso do profissional e
              todos seus alunos ao site web e aplicativo.
            </>
          )
        }
      />
    </RootLayout>
  );
}
