import { useState } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { getToastErrorContent } from "@/utils/functions/api-error";
import ModalConfirmDelete from "@/components/modal-confirm";
import ButtonDropdown from "@/components/button-dropdown";
import { Link, generatePath } from "react-router-dom";
import { useToast } from "@/components/ui/use-toast";
import { SheetService } from "@/services/api/sheet";
import { SheetTemplate } from "@/utils/entities";
import { useQuery } from "@tanstack/react-query";
import { Button } from "@/components/ui/button";
import RootLayout from "@/components/layout";
import { PlusIcon } from "lucide-react";
import Routes from "@/routes/paths";
import {
  DropdownMenuItem,
  DropdownMenuSeparator,
} from "@/components/ui/dropdown-menu";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import ModalSaveSheetTemplate from "../student/components/modal/modal-save-sheet-template";

export default function TemplateListScreen() {
  const { toast } = useToast();
  const [newTemplate, setNewTemplate] = useState(false);
  const [template, setTemplate] = useState<SheetTemplate>();

  const { data, refetch } = useQuery({
    queryKey: ["templates"],
    queryFn: SheetService.getAllTemplates,
  });

  const closeDeleteModal = () => setTemplate(undefined);

  const onDelete = (item: SheetTemplate) => async () => {
    try {
      if (!item.id) {
        return;
      }

      await SheetService.deleteTemplate(item.id);

      refetch();
      closeDeleteModal();
      toast({
        variant: "success",
        description: "Template deletado com sucesso.",
      });
    } catch (error) {
      toast(getToastErrorContent(error));
    }
  };

  return (
    <RootLayout>
      <Card className="bg-gray-800">
        <CardHeader>
          <CardTitle className="flex text-gray-200 items-center justify-between">
            <div className="flex flex-col">
              Templates de ficha
              <span className="text-base font-normal">
                Você pode criar fichas para reaproveitar com seus alunos
              </span>
            </div>

            <Button
              size="default"
              variant="outline"
              className="bg-transparent"
              onClick={() => setNewTemplate(true)}
            >
              <PlusIcon size={18} className="mr-2" /> Novo template
            </Button>
          </CardTitle>
        </CardHeader>
        <CardContent className="grid gap-6">
          <Table>
            <TableCaption>Lista de templates.</TableCaption>
            <TableHeader>
              <TableRow>
                <TableHead className="text-gray-400">
                  Nome do template
                </TableHead>
                <TableHead className="w-[100px] text-right" />
              </TableRow>
            </TableHeader>
            <TableBody className="text-gray-200">
              {data?.map((item) => (
                <TableRow key={item?.id}>
                  <TableCell>{item?.name}</TableCell>
                  <TableCell className="text-right">
                    <ButtonDropdown>
                      <Link
                        to={generatePath(Routes.template.edit, {
                          id: item?.id,
                        })}
                      >
                        <DropdownMenuItem className="cursor-pointer">
                          Editar
                        </DropdownMenuItem>
                      </Link>

                      <DropdownMenuSeparator />

                      <DropdownMenuItem
                        className="cursor-pointer"
                        onClick={() => setTemplate(item)}
                      >
                        Deletar
                      </DropdownMenuItem>
                    </ButtonDropdown>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>

      <ModalConfirmDelete
        isVisible={!!template}
        onCancel={closeDeleteModal}
        onContinue={onDelete(template!)}
        description={
          <>
            Apenas o template será deletado, nenhuma ficha criada apartir dele
            será afetada.
          </>
        }
      />
      <ModalSaveSheetTemplate
        isOpen={newTemplate}
        onSuccess={() => {
          refetch();
          setNewTemplate(false);
        }}
        onClose={() => setNewTemplate(false)}
      />
    </RootLayout>
  );
}
