import * as React from "react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { DropzoneUpload } from "@/components/ui/dropzone-upload";
import { XIcon } from "lucide-react";
import { useToast } from "@/components/ui/use-toast";
import { getToastErrorContent } from "@/utils/functions/api-error";
import { ExerciseVideoService } from "@/services/api/exercise-video";

type FormVideoUploadProps = {
  isOpened: boolean;
  exerciseId: string;
  onCancel?: () => void;
  onSuccessful?: () => void;
};

const bytesToMegaBytes = (bytes: number) => (bytes / 1024 ** 2).toFixed(2);

export default function FormVideoUpload({
  exerciseId,
  isOpened,
  onCancel,
}: FormVideoUploadProps) {
  const { toast } = useToast();
  const [file, setFile] = React.useState<File | null>(null);

  function handleChangeFile(files: File[]) {
    const data = files.length ? files[0] : null;

    setFile(data);
  }

  function handleCancel() {
    setFile(null);
    onCancel?.();
  }

  const handleSubmit = async () => {
    try {
      if (!file) {
        return;
      }

      const videoUrl = await ExerciseVideoService.generateVideoBucketUrl({
        file,
        folder: "exercises",
      });

      const response = await ExerciseVideoService.uploadVideoToBucket({
        ...videoUrl,
        file,
      });

      console.log(response);
    } catch (error) {
      console.log(error);

      toast(getToastErrorContent(error));
    }
  };

  return (
    <AlertDialog open={isOpened}>
      <AlertDialogContent className="bg-white">
        <AlertDialogHeader>
          <AlertDialogTitle>Upload do vídeo do exercício</AlertDialogTitle>

          <AlertDialogDescription>
            Essa ação pode demorar de acordo com o tamanho do vídeo
          </AlertDialogDescription>
        </AlertDialogHeader>
        <div className="grid">
          <DropzoneUpload onChangeFile={(files) => handleChangeFile(files)} />

          {file ? (
            <div className="mt-5 mb-2 rounded-md bg-[#F5F7FB] p-4">
              <div className="flex items-center justify-between">
                <div className="flex flex-col">
                  <span className="truncate font-medium text-[#07074D]">
                    {file?.name}
                  </span>
                  <span className="truncate text-xs text-zinc-600">
                    Tamanho: {bytesToMegaBytes(file?.size)} MB
                  </span>
                </div>
                <button
                  type="button"
                  className="text-[#07074D]"
                  onClick={() => handleChangeFile([])}
                >
                  <XIcon size={18} />
                </button>
              </div>
            </div>
          ) : null}
        </div>
        <AlertDialogFooter>
          <AlertDialogCancel onClick={handleCancel}>Cancelar</AlertDialogCancel>
          <AlertDialogAction onClick={handleSubmit}>Salvar</AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
