import { StudentGroup } from "@/utils/entities";
import api from "./config";

const getAll = async () => {
  const { studentGroups } = (await api.get("/student-group")).data;

  return studentGroups as StudentGroup[];
};

const create = async (data: StudentGroup) => {
  const { studentGroup } = (await api.post("/student-group", data)).data;

  return studentGroup as StudentGroup;
};

const update = async (data: StudentGroup) => {
  const { studentGroup } = (await api.put("/student-group", data)).data;

  return studentGroup as StudentGroup;
};

export async function createOrUpdate(data: StudentGroup) {
  if (data?.id) {
    return update(data);
  }

  return create(data);
}

export const StudentGroupService = {
  getAll,
  createOrUpdate,
};
