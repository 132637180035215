import { cn } from "@/utils";
import { cva, type VariantProps } from "class-variance-authority";
import React from "react";

const inputIconVariants = cva("absolute inset-y-0 flex items-center px-3", {
  variants: {
    variant: {
      default: "right-0",
      left: "left-0",
    },
  },
  defaultVariants: {
    variant: "default",
  },
});

export interface InputIconProps
  extends React.ButtonHTMLAttributes<HTMLElement>,
    VariantProps<typeof inputIconVariants> {
  icon: any;
  children: any;
  onClick: () => void;
}

export const InputIcon = ({
  children,
  icon,
  onClick,
  className,
  variant,
}: InputIconProps) => {
  return (
    <div className="relative">
      {children}

      <span className={cn(inputIconVariants({ variant, className }))}>
        <button
          type="button"
          onClick={onClick}
          className="focus:outline-none focus:shadow-outline"
        >
          {icon}
        </button>
      </span>
    </div>
  );
};
