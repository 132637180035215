import ButtonBack from "@/components/button-back";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useToast } from "@/components/ui/use-toast";
import Routes from "@/routes/paths";
import { ProfessionalService } from "@/services/api/professional";
import { Professional } from "@/utils/entities";
import { getToastErrorContent } from "@/utils/functions/api-error";
import { getDateFormatInput } from "@/utils/functions/date-transform";
import { applyPhoneMask } from "@/utils/functions/phone-mask";
import { zodResolver } from "@hookform/resolvers/zod";
import { Loader2 } from "lucide-react";
import { useForm } from "react-hook-form";
import { generatePath, useNavigate } from "react-router-dom";
import * as z from "zod";

const FormSchema = z.object({
  id: z
    .string()
    .optional()
    .transform((e) => (e === "" ? undefined : e)),
  name: z.string().min(1, { message: "Campo obrigatório" }),
  surname: z.string().min(1, { message: "Campo obrigatório" }),
  phone: z
    .string()
    .min(1, { message: "Campo obrigatório" })
    .min(15, { message: "Telefone inválido" }),
  email: z
    .string()
    .min(1, { message: "Campo obrigatório" })
    .email("Email inválido"),
  birth_date: z.string().min(1, { message: "Campo obrigatório" }),
  contract_date: z.string().optional(),
  students_amount_enroll: z.coerce.number().optional().default(0),
});

type FormSchemaType = z.infer<typeof FormSchema>;

type ProfessionalFormProps = {
  mode: "CREATE" | "UPDATE";
  professional?: Professional;
};

const formTexts = {
  CREATE: {
    formHeaderTitle: "Cadastro de Profissional",
    feedbackSuccess: "Profissional cadastrado com sucesso.",
  },
  UPDATE: {
    formHeaderTitle: "Edição de Profissional",
    feedbackSuccess: "Profissional atualizado com sucesso.",
  },
};

export default function ProfessionalForm({
  mode = "CREATE",
  professional,
}: ProfessionalFormProps) {
  const navigate = useNavigate();
  const { toast } = useToast();

  const defaultValues = {
    id: professional?.id ?? "",
    name: professional?.user?.name ?? "",
    surname: professional?.user?.surname ?? "",
    email: professional?.user?.email ?? "",
    phone: applyPhoneMask(professional?.user?.phone) ?? "",
    birth_date: getDateFormatInput(professional?.birth_date) ?? "",
    contract_date: getDateFormatInput(professional?.user?.contract_date) ?? "",
    students_amount_enroll: professional?.students_amount_enroll ?? 0,
  };

  const form = useForm<FormSchemaType>({
    resolver: zodResolver(FormSchema),
    defaultValues,
  });

  async function onSubmit(data: FormSchemaType) {
    try {
      const formData = FormSchema.parse(data) as any;

      const professional = await ProfessionalService.createOrUpdate(formData);

      toast({
        variant: "success",
        description: formTexts[mode].feedbackSuccess,
      });

      if (mode === "CREATE") {
        const formProfessionalEditPath = generatePath(
          Routes.professional.edit,
          {
            id: professional.id,
          }
        );

        form.reset(defaultValues);

        navigate(formProfessionalEditPath, { replace: true });
      }
    } catch (error) {
      toast(getToastErrorContent(error));
    }
  }

  function handleBack() {
    navigate(-1);
  }

  const { isSubmitting } = form.formState;

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Card className="bg-gray-800">
          <CardHeader>
            <CardTitle className="text-gray-200 items-start justify-start">
              <ButtonBack />

              {formTexts[mode].formHeaderTitle}
            </CardTitle>
          </CardHeader>
          <CardContent className="grid gap-10">
            <div className="grid grid-cols-5 gap-4">
              <div className="grid gap-3 col-span-2">
                <FormField
                  name="name"
                  control={form.control}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-gray-300">Nome</FormLabel>

                      <FormControl>
                        <Input
                          className="bg-gray-800 text-gray-300"
                          {...field}
                        />
                      </FormControl>

                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="grid gap-3 col-span-2">
                <FormField
                  name="surname"
                  control={form.control}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-gray-300">Sobrenome</FormLabel>

                      <FormControl>
                        <Input
                          className="bg-gray-800 text-gray-300"
                          {...field}
                        />
                      </FormControl>

                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="grid gap-3">
                <FormField
                  name="phone"
                  control={form.control}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-gray-300">Whastapp</FormLabel>

                      <FormControl>
                        <Input
                          type="tel"
                          className="bg-gray-800 text-gray-300"
                          placeholder="(85) 91234-1234"
                          {...field}
                          onChange={(event) =>
                            field.onChange(applyPhoneMask(event.target.value))
                          }
                        />
                      </FormControl>

                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </div>
            <div className="grid grid-cols-5 gap-4">
              <div className="grid gap-3 col-span-2">
                <FormField
                  name="email"
                  control={form.control}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-gray-300">Email</FormLabel>

                      <FormControl>
                        <Input
                          className="bg-gray-800 text-gray-300"
                          {...field}
                        />
                      </FormControl>

                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="grid gap-3">
                <FormField
                  name="birth_date"
                  control={form.control}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-gray-300">
                        Data de nascimento
                      </FormLabel>

                      <FormControl>
                        <Input
                          type="date"
                          className="bg-gray-800 text-gray-300"
                          {...field}
                        />
                      </FormControl>

                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="grid gap-3">
                <FormField
                  name="contract_date"
                  control={form.control}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-gray-300">
                        Data do contrato
                      </FormLabel>

                      <FormControl>
                        <Input
                          type="date"
                          className="bg-gray-800 text-gray-300"
                          {...field}
                        />
                      </FormControl>

                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="grid gap-3">
                <FormField
                  name="students_amount_enroll"
                  control={form.control}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-gray-300">
                        Quantidade de alunos
                      </FormLabel>

                      <FormControl>
                        <Input
                          min={0}
                          type="number"
                          className="bg-gray-800 text-gray-300"
                          {...field}
                        />
                      </FormControl>

                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </div>
          </CardContent>
          <CardFooter className="justify-end space-x-2">
            <Button
              type="button"
              variant="ghost"
              disabled={isSubmitting}
              className="text-gray-300"
              onClick={handleBack}
            >
              Cancelar
            </Button>
            <Button disabled={isSubmitting} className="px-8 hover:bg-gray-900">
              {isSubmitting && (
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              )}
              Salvar
            </Button>
          </CardFooter>
        </Card>
      </form>
    </Form>
  );
}
