import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from "@/components/ui/sheet";

type Props = {
  userName: string | undefined;
  isVisible?: boolean;
  form_answer: any | undefined;
  handleClose: () => void;
};

export default function ModalUserFormAnswers({
  userName = "",
  isVisible = false,
  form_answer = null,
  handleClose,
}: Props) {
  if (!isVisible) {
    return null;
  }

  return (
    <Sheet open={true} onOpenChange={() => handleClose()}>
      <SheetContent className="bg-white overflow-scroll max-h-screen">
        <SheetHeader>
          <SheetTitle>{userName}</SheetTitle>
          {form_answer &&
            Object.keys(form_answer).map((questionnaireKey, index) => {
              if (questionnaireKey === "title") return "";

              return (
                <div key={questionnaireKey + index}>
                  <h3>
                    {index + 1 + " - " + form_answer[questionnaireKey].title}
                  </h3>
                  <div className="pl-4">
                    {Object.keys(form_answer[questionnaireKey]).map(
                      (questionKey, j) => {
                        return (
                          <div
                            className="py-2 overflow-scroll border-t-2"
                            key={questionnaireKey + "_" + questionnaireKey + j}
                          >
                            <p className="font-bold text-xs">
                              {form_answer[questionnaireKey][questionKey].title}
                            </p>
                            {form_answer[questionnaireKey][questionKey].answer}
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              );
            })}
        </SheetHeader>
      </SheetContent>
    </Sheet>
  );
}
