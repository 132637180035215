import Routes from "@/routes/paths";
import { StorageService } from "@/services/storage";
import StorageUser from "@/services/storage/user";
import { User } from "@/utils/entities";
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";

type UserContextStateValues = {
  user: User;
};

type UserContextStateDispatchs = {
  setUser: (data: any) => void;
  onSignOut: () => void;
  updateUser: (data?: Partial<User>) => void;
};

type UserContextState = UserContextStateValues & UserContextStateDispatchs;

type UserProviderProps = {
  children: ReactNode;
};

const UserContext = createContext<UserContextState>({} as UserContextState);

function UserProvider({ children }: UserProviderProps) {
  const navigate = useNavigate();
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    setUser(StorageUser.get());
  }, []);

  const onSignOut = () => {
    StorageService.clear();

    setUser(null);

    navigate(Routes.signIn);
  };

  const updateUser = (data: Partial<User>) => {
    setUser((prev: any) => {
      if (!prev) {
        return;
      }

      StorageUser.set({ ...prev, ...data });

      return { ...prev, ...data };
    });
  };

  const providerValues = {
    user,
  } as UserContextStateValues;

  const providerDispatchs = {
    setUser,
    onSignOut,
    updateUser,
  } as UserContextStateDispatchs;

  return (
    <UserContext.Provider value={{ ...providerValues, ...providerDispatchs }}>
      {children}
    </UserContext.Provider>
  );
}

function useUserProvider(): UserContextState {
  const context = useContext(UserContext);

  return context;
}

export { UserProvider, useUserProvider };
