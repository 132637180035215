import { Button } from "@/components/ui/button";
import { DropzoneUpload } from "@/components/ui/dropzone-upload";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from "@/components/ui/sheet";
import { useToast } from "@/components/ui/use-toast";
import { StudentFoodPlanService } from "@/services/api/student-food-plan";
import { getToastErrorContent } from "@/utils/functions/api-error";
import { zodResolver } from "@hookform/resolvers/zod";
import { Loader2Icon, XIcon } from "lucide-react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import * as z from "zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import useFileBucket from "@/utils/hooks/use-file-bucket";

type Props = {
  isOpened: boolean;
  onSuccessAttach: () => void;
  onVisibleChange: (state: boolean) => void;
};

const FormSchema = z.object({
  file: z.custom<File | null>().refine((file) => !!file, "Arquivo obrigatório"),
  title: z.string().min(1, { message: "Campo obrigatório" }),
});

type FormSchemaType = z.infer<typeof FormSchema>;

export default function FoodPlanAdd({
  isOpened,
  onSuccessAttach,
  onVisibleChange,
}: Props) {
  const { id } = useParams();
  const { toast } = useToast();
  const { getSignedUrl } = useFileBucket();

  const form = useForm<FormSchemaType>({
    resolver: zodResolver(FormSchema),
  });

  function handleChangeFile(files: File[]) {
    const file = files.length ? files[0] : null;

    form.setValue("file", file, { shouldValidate: true });
  }

  function handleCancel(opened: boolean) {
    form.reset();

    onVisibleChange(opened);
  }

  async function onSubmit(data: FormSchemaType) {
    try {
      const { title, file } = FormSchema.parse(data);

      const file_url = await getSignedUrl(file, `students/${id}/food-plan`);

      await StudentFoodPlanService.create({
        title,
        file_url,
        studentId: String(id),
      });

      toast({
        variant: "success",
        description: "Plano alimentar salvo com sucesso",
      });

      form.reset();

      onSuccessAttach();
    } catch (error) {
      toast(getToastErrorContent(error));
    }
  }

  const file = form.watch("file");

  const { isSubmitting, errors } = form.formState;

  return (
    <Sheet open={isOpened} onOpenChange={handleCancel}>
      <SheetContent className="bg-white py-3">
        <SheetHeader>
          <SheetTitle>Plano alimentar</SheetTitle>
        </SheetHeader>

        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className="flex items-center justify-center mt-6">
              <div className="mx-auto w-full max-w-[550px] bg-white">
                <FormField
                  name="title"
                  control={form.control}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="-text-gray-300">
                        Nome de exibição
                      </FormLabel>

                      <FormControl>
                        <Input
                          className="text-gray-900 border border-gray-300"
                          {...field}
                        />
                      </FormControl>

                      <FormMessage />
                    </FormItem>
                  )}
                />

                <div className="mt-5">
                  <DropzoneUpload
                    onChangeFile={(files) => handleChangeFile(files)}
                    optionsDropzone={{
                      accept: {
                        "application/pdf": [".pdf"],
                      },
                    }}
                  />

                  <p className="mt-2 text-sm font-medium text-destructive">
                    {errors?.file?.message}
                  </p>

                  {file ? (
                    <div className="mt-5 mb-2 rounded-md bg-[#F5F7FB] py-4 px-8">
                      <div className="flex items-center justify-between">
                        <span className="truncate pr-3 text-base font-medium text-[#07074D]">
                          {file?.name}
                        </span>
                        <button
                          type="button"
                          className="text-[#07074D]"
                          onClick={() => handleChangeFile([])}
                        >
                          <XIcon size={18} />
                        </button>
                      </div>
                    </div>
                  ) : null}
                </div>

                <Button
                  size="xlg"
                  disabled={isSubmitting}
                  className="mt-6 w-full px-8 hover:bg-gray-900"
                >
                  {isSubmitting && (
                    <Loader2Icon className="mr-2 h-4 w-4 animate-spin" />
                  )}
                  Salvar
                </Button>
              </div>
            </div>
          </form>
        </Form>
      </SheetContent>
    </Sheet>
  );
}
