import axios from "axios";
import api from "./config";

type VideoSignUrlInput = {
  file: File;
  folder?: string;
};

type VideoSignUrlOutput = {
  bucketUrl: string;
  bucketVideoUrl: string;
  bucketFileName: string;
};

type VideoUploadSignUrlInput = VideoSignUrlInput & VideoSignUrlOutput;

async function generateFileBucketUrl({ file, folder }: VideoSignUrlInput) {
  const payload = {
    folder,
    fileName: file.name,
    fileType: file.type,
  };

  const response = await api.post("/exercises/video/generate-url", payload);

  return response.data as VideoSignUrlOutput;
}

async function uploadFileToBucket({
  file,
  bucketUrl,
}: VideoUploadSignUrlInput) {
  const options = {
    headers: {
      "x-amz-acl": "public-read",
      "Content-Type": file.type,
    },
  };

  return await axios.put(bucketUrl, file, options);
}

export const ExerciseVideoService = {
  getFileBucketUrl: generateFileBucketUrl,
  uploadVideoToBucket: uploadFileToBucket,
  generateVideoBucketUrl: generateFileBucketUrl,
};

export const FileService = ExerciseVideoService