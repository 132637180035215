import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { ScrollArea, ScrollBar } from "@/components/ui/scroll-area";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from "@/components/ui/sheet";
import type {
  PhysicalAssessment,
  PhysicalAssessmentField,
} from "@/utils/entities";
import { getDateFormatText } from "@/utils/functions/date-transform";
import { handleOpenEnternalLink } from "@/utils/functions/link";

type Props = {
  data?: PhysicalAssessment[];
  isVisible?: boolean;
  handleClose: () => void;
};

function CardImages({
  back_photo,
  side_photo,
  front_photo,
}: PhysicalAssessment) {
  const photos = [
    { id: "back_photo", url: back_photo },
    { id: "front_photo", url: front_photo },
    { id: "side_photo", url: side_photo },
  ];
  return (
    <ScrollArea className="w-full whitespace-nowrap rounded-md border">
      <div className="flex w-max space-x-4 p-4">
        {photos.map((photo) => (
          <button
            key={photo?.id}
            onClick={() => handleOpenEnternalLink(photo?.url)}
          >
            <figure className="shrink-0">
              <div className="overflow-hidden rounded-md">
                <img
                  alt={photo?.id}
                  src={photo?.url}
                  className="aspect-[3/4] h-[200px] object-cover"
                />
              </div>
            </figure>
          </button>
        ))}
      </div>

      <ScrollBar orientation="horizontal" />
    </ScrollArea>
  );
}

function CardQuestionAndAnswers({ form_answer }: PhysicalAssessment) {
  if (!form_answer) {
    return <span className="mx-auto py-3">Sem avaliação</span>;
  }

  const data = JSON.parse(form_answer as string) as PhysicalAssessmentField[];

  return (
    <div className="border rounded-md p-4 flex flex-col gap-8">
      {data?.map((item, index) => (
        <div key={item.id} className="flex flex-col">
          <span className="text-md font-semibold uppercase">
            {index + 1}. {item?.title}
          </span>

          <div className="flex flex-col px-4 mt-3">
            {item.questions.map((question, index) => (
              <div className="flex flex-col gap-1">
                {index > 0 ? <hr className="my-2" /> : null}

                <span className="text-sm font-semibold">{question?.title}</span>

                <span>{question?.answer}</span>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}

export default function ModalPhysicalEvaluation({
  data = [],
  isVisible = false,
  handleClose,
}: Props) {
  if (!isVisible) {
    return null;
  }

  return (
    <Sheet open={true} onOpenChange={handleClose}>
      <SheetContent className="bg-white overflow-scroll max-h-screen min-w-[40vw]">
        <SheetHeader>
          <SheetTitle>Avaliações Físicas</SheetTitle>
        </SheetHeader>
        <Accordion type="multiple" className="mt-6">
          {data?.map((item) => (
            <AccordionItem value={item?.id}>
              <AccordionTrigger className="border border-b-0 px-3 font-semibold">
                Avaliação {getDateFormatText(item.createdAt)}
              </AccordionTrigger>
              <AccordionContent className="py-4 border border-b-0 px-5">
                <div className="flex flex-col gap-2">
                  <span className="text-lg font-semibold">Imagens</span>

                  <CardImages {...item} />
                </div>

                <div className="flex flex-col gap-2 mt-4">
                  <span className="text-lg font-semibold">Formulário</span>

                  <CardQuestionAndAnswers {...item} />
                </div>
              </AccordionContent>
            </AccordionItem>
          ))}
        </Accordion>
      </SheetContent>
    </Sheet>
  );
}
