import RootLayout from "@/components/layout";
import AppLayout from "./components/app-layout";
import { useState } from "react";
import UserGroupList from "./components/user-group/list";
import MemberCategoryList from "./components/membro-category/list";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";

type TabItem = { name: string; title: string; component: React.ReactNode };

const tabs: TabItem[] = [
  {
    name: "APP",
    title: "Aplicativo",
    component: <AppLayout />,
  },
  {
    name: "USER_GROUP",
    title: "Grupos de usuário",
    component: <UserGroupList />,
  },
  {
    name: "CATEGORY_CONTENT",
    title: "Categorias para membros",
    component: <MemberCategoryList />,
  },
];

function TabsTrainingHeader() {
  return (
    <div className="flex">
      <TabsList className="bg-gray-600 text-gray-800">
        {tabs?.map((item) => (
          <TabsTrigger key={item?.name} value={item?.name ?? ""}>
            {item?.title}
          </TabsTrigger>
        ))}
      </TabsList>
    </div>
  );
}

export default function ConfigScreen() {
  const [activeTab, setActiveTab] = useState<TabItem>(tabs[0]);

  return (
    <RootLayout>
      <div className="w-full flex flex-col">
        <h1 className="text-white text-2xl mb-4">Configurações</h1>
        <Tabs defaultValue={activeTab.name} className="mt-5">
          <TabsTrainingHeader />

          {/* {tabs.map((item) => (
              <button
                key={item.name}
                className={cn(
                  "px-4 py-2 mr-1 border-[1px] rounded-md",
                  item.name === activeTab.name
                    ? "bg-black text-white border-white"
                    : "bg-white text-black"
                )}
                onClick={() => {
                  setActiveTab(item);
                }}
              >
                {item.title}
              </button>
            ))} */}

          {tabs.map((item) => (
            <TabsContent key={item.name} value={item.name ?? ""}>
              {item.component}
            </TabsContent>
          ))}
        </Tabs>
      </div>
    </RootLayout>
  );
}
