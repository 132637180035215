import RootLayout from "@/components/layout";
import ProfessionalForm from "./components/form";

export default function ProfessionalCreateScreen() {
  return (
    <RootLayout>
      <ProfessionalForm mode="CREATE" />
    </RootLayout>
  );
}
