import { Button } from "@/components/ui/button";
import * as z from "zod";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { useToast } from "@/components/ui/use-toast";
import { getToastErrorContent } from "@/utils/functions/api-error";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
} from "@/components/ui/sheet";
import { Separator } from "@/components/ui/separator";
import Spinner from "@/components/ui/spinner";
import DropzoneFile from "@/components/dropzone-file";
import { MemberContent, MemberContentFile } from "@/utils/entities";
import useFileBucket from "@/utils/hooks/use-file-bucket";
import { MemberContentFileService } from "@/services/api/member-content-file";
import { Viewer } from "@react-pdf-viewer/core";
import { TrashIcon } from "lucide-react";
import { Card, CardContent, CardFooter } from "@/components/ui/card";
import { useState } from "react";
import ModalConfirmDelete from "@/components/modal-confirm";
import { useQuery } from "@tanstack/react-query";
import { useUserProvider } from "@/utils/providers/user-provider";
import useRefetchRefetchQueries from "@/utils/hooks/use-invalidate-query-client";

const FormSchema = z.object({
  type: z.string({ required_error: "Arquivo obrigatório" }),
  file: z.custom<File | null>().refine((file) => !!file, "Arquivo obrigatório"),
});

type FormSchemaType = z.infer<typeof FormSchema>;

type Props = {
  isOpen: boolean;
  memberContent: MemberContent | null;
  onClose: () => void;
  onSuccess: () => void;
};

type FileListProps = {
  memberContent: MemberContentFile;
};

type FilePreviewProps = {
  content: MemberContentFile;
};

type FileFormCreateProps = {
  memberContent: MemberContent;
  onSuccess: () => void;
};

const FilePreviewContent = ({ content }: FilePreviewProps) => {
  if (!content) {
    return null;
  }

  switch (content.type) {
    case "pdf": {
      return <Viewer fileUrl={content.fileUrl} />;
    }
    case "video": {
      return (
        <video
          controls
          src={content.fileUrl}
          className="h-full w-full object-contain portrait aspect-[3/4]"
        />
      );
    }
    default:
      return null;
  }
};

function FileList({ memberContent }: FileListProps) {
  const { toast } = useToast();
  const [fileDelete, setFileDelete] = useState<MemberContentFile>();

  const { data, refetch } = useQuery({
    queryKey: ["members_content_files", memberContent?.id],
    queryFn: () => MemberContentFileService.list(memberContent?.id as string),
    enabled: !!memberContent,
  });

  async function onSubmitDelete() {
    try {
      if (!fileDelete || !memberContent) {
        throw new Error("File to delete not found");
      }

      const fileId = fileDelete.id as string;
      const memberContentId = memberContent.id as string;

      await MemberContentFileService.delete(memberContentId, fileId);

      setFileDelete(undefined);

      toast({
        variant: "success",
        description: "Arquivo removido com sucesso",
      });

      refetch?.();
    } catch (error) {
      toast(getToastErrorContent(error));
    }
  }

  return (
    <>
      <div className="flex flex-col gap-3">
        <span>Arquivos</span>

        <Separator />

        <div className="flex flex-row gap-6">
          {data?.map((item, index) => (
            <Card key={index}>
              <CardContent className="px-0 py-1 flex flex-col min-w-[250px] min-h-[300px] h-[300px]">
                <FilePreviewContent content={item} />
              </CardContent>
              <CardFooter className="p-2">
                <Button
                  size="sm"
                  variant="outline"
                  className="w-full gap-1"
                  onClick={() => setFileDelete(item)}
                >
                  <TrashIcon className="w-3 h-3" /> Apagar
                </Button>
              </CardFooter>
            </Card>
          ))}
        </div>
      </div>

      <ModalConfirmDelete
        isVisible={!!fileDelete}
        onCancel={() => setFileDelete(undefined)}
        onContinue={() => onSubmitDelete()}
        description={
          <span>
            Isso irá <strong>remover</strong> o arquivo para todos os membros
            associados.
          </span>
        }
      />
    </>
  );
}

function FormCreate({ memberContent, onSuccess }: FileFormCreateProps) {
  const { toast } = useToast();
  const { getSignedUrl } = useFileBucket();
  const { refetchMemberCategoryFiles } = useRefetchRefetchQueries();

  const form = useForm<FormSchemaType>({
    resolver: zodResolver(FormSchema),
  });

  async function onSubmit(data: FormSchemaType) {
    try {
      if (!memberContent) {
        throw new Error("Member content not found");
      }

      const memberContentId = memberContent.id as string;

      const { type, file } = FormSchema.parse(data);

      const fileUrl = await getSignedUrl(
        file,
        `members/${memberContentId}/files`
      );

      await MemberContentFileService.create(memberContentId, {
        type,
        fileUrl,
      });

      toast({
        variant: "success",
        description: "Arquivo salvo com sucesso",
      });

      form?.reset();

      refetchMemberCategoryFiles(memberContentId);

      onSuccess?.();
    } catch (error) {
      console.log(error);

      toast(getToastErrorContent(error));
    }
  }

  const { errors, isSubmitting } = form.formState;

  return (
    <Form {...form}>
      <div className="flex flex-col gap-3 overflow-scroll">
        <span>Conteúdo</span>

        <Separator />
      </div>

      <FormField
        control={form.control}
        name="type"
        render={({ field }) => (
          <FormItem className="space-y-3">
            <FormLabel>Tipo de arquivo</FormLabel>
            <FormControl>
              <RadioGroup
                onValueChange={field.onChange}
                defaultValue={field.value}
                className="flex flex-col space-y-1"
              >
                <FormItem className="flex items-center space-x-3 space-y-0">
                  <FormControl>
                    <RadioGroupItem value="video" />
                  </FormControl>
                  <FormLabel className="font-normal">Vídeo</FormLabel>
                </FormItem>
                <FormItem className="flex items-center space-x-3 space-y-0">
                  <FormControl>
                    <RadioGroupItem value="pdf" />
                  </FormControl>
                  <FormLabel className="font-normal">PDF</FormLabel>
                </FormItem>
              </RadioGroup>
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      <div className="mt-4">
        <DropzoneFile
          onChange={(file) =>
            form.setValue("file", file, { shouldValidate: true })
          }
        />

        <p className="mt-2 text-sm font-medium text-destructive">
          {errors?.file?.message}
        </p>
      </div>

      <Button
        type="button"
        className="w-full"
        disabled={isSubmitting}
        onClick={form.handleSubmit(onSubmit)}
      >
        {isSubmitting ? <Spinner className="h-[14px] w-[14px] mr-2" /> : null}
        Salvar
      </Button>
    </Form>
  );
}

function FormManagerFiles({ isOpen, onClose, memberContent }: Props) {
  return (
    <Sheet open={isOpen}>
      <SheetContent
        side="bottom"
        className="bg-white min-h-[80%] flex flex-col gap-0 p-0"
        onClose={onClose}
      >
        <SheetHeader className="p-4">
          <SheetTitle>Gerenciar arquivos</SheetTitle>
          <SheetDescription>
            Gerencie os Vídeos ou PDFs para seus grupos de membros
          </SheetDescription>
        </SheetHeader>

        <Separator />

        {isOpen && memberContent ? (
          <div className="grid flex-1 h-full grid-cols-4">
            <div className="flex flex-col gap-4 p-4 col-span-1 border-r">
              <FormCreate memberContent={memberContent} onSuccess={() => {}} />
            </div>
            <div className="flex p-4 col-span-3">
              <FileList memberContent={memberContent} />
            </div>
          </div>
        ) : null}
      </SheetContent>
    </Sheet>
  );
}

export default FormManagerFiles;
