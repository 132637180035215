import { Exercise, Sheet, Training } from "@/utils/entities";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useState } from "react";
import { Button } from "@/components/ui/button";
import { SheetGridExercisesAdded } from "./sheet-list-exercises-added";
import {
  ChevronLeftCircleIcon,
  ChevronRightCircleIcon,
  PlusIcon,
} from "lucide-react";
import SheetDialogEditExercise from "./sheet-dialog-edit-exercise";
import SheetDialogAddExercise from "./sheet-dialog-add-exercise";
import SheetDialogExerciseExecution from "./sheet-dialog-exercise-execution";
import ModalConfirmDelete from "@/components/modal-confirm";
import { useSearchParams } from "react-router-dom";
import { SheetTrainingService } from "@/services/api/sheet-training";
import { useToast } from "@/components/ui/use-toast";

type Props = {
  data: Sheet | undefined;
  sheetId?: string | undefined;
  isLoading: boolean;
  onSuccess: () => void;
  onRemoveExercise: (exercise: Exercise) => void;
};

type SheetDialogTypes = "ADD" | "EDIT" | "DELETE" | "LIST_EXECUTION";

function TabsTrainingHeader({
  isLoading,
  trainings,
  currentTab,
  onMoveToNext,
  onMoveToPrevious,
}: {
  isLoading?: boolean;
  trainings: Training[];
  currentTab?: string;
  onMoveToNext: (id: string, index: number) => void;
  onMoveToPrevious: (id: string, index: number) => void;
}) {
  return (
    <div className="flex">
      <TabsList
        className={`bg-gray-600 text-gray-800 ${isLoading && "animate-pulse"}`}
      >
        {trainings?.map((item, training_index) => (
          <TabsTrigger
            disabled={isLoading}
            key={item?.name}
            value={item.name ?? ""}
            className="flex flex-col"
          >
            {(!currentTab && training_index === 0) ||
            currentTab === item.name ? (
              <div className="flex flex-row items-center gap-2">
                {training_index > 0 && (
                  <div
                    className="hover:bg-black hover:text-white transition-all rounded-full"
                    onClick={() => onMoveToPrevious(item.id!, training_index)}
                  >
                    <ChevronLeftCircleIcon />
                  </div>
                )}
                {(item?.index || 0) + 1}ª - {item?.name}
                {training_index < trainings.length - 1 && (
                  <div
                    className="hover:bg-black hover:text-white transition-all rounded-full"
                    onClick={() => onMoveToNext(item.id!, training_index)}
                  >
                    <ChevronRightCircleIcon />
                  </div>
                )}
              </div>
            ) : (
              <>
                {(item?.index || 0) + 1}ª - {item?.name}
              </>
            )}
          </TabsTrigger>
        ))}
      </TabsList>
    </div>
  );
}

export default function SheetTrainingContent({
  data,
  sheetId,
  isLoading,
  onSuccess,
  onRemoveExercise,
}: Props) {
  const { toast } = useToast();
  const [exercise, setExercise] = useState<Exercise>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [dialogType, setDialogType] = useState<SheetDialogTypes>();
  const [activeTraining, setActiveTraining] = useState<Training>();
  const [isUpdatingOrder, setUpdatingOrder] = useState(false);

  const tabLetterValue = searchParams.get("tabLetterKey") || undefined;

  function handleEdit(item: Exercise) {
    setExercise(item);
    setDialogType("EDIT");
  }

  function handleDelete(item: Exercise) {
    setExercise(item);
    setDialogType("DELETE");
  }

  function handleListExecution(item: Exercise) {
    setExercise(item);
    setDialogType("LIST_EXECUTION");
  }

  function handleCloseDialog() {
    setExercise(undefined);
    setDialogType(undefined);
    setActiveTraining(undefined);
  }

  function handleAddExercise(item: Training) {
    setActiveTraining(item);
    setDialogType("ADD");
  }

  function handleRemoveExercise(item: Exercise) {
    setExercise(undefined);
    setDialogType(undefined);
    setActiveTraining(undefined);

    onRemoveExercise?.(item);
  }

  function handleTrainingUpdated() {
    handleCloseDialog();
    onSuccess?.();
  }

  function handleChangeTabLetter(value: string) {
    searchParams.set("tabLetterKey", value);

    setSearchParams(searchParams, { replace: true });
  }

  async function updateOrderListOfExercises(
    trainingId: string,
    items: Exercise[]
  ) {
    setUpdatingOrder(true);
    try {
      if (!sheetId) return;

      const exercises = items.map(({ execution }, order) => ({
        id: execution!.id!,
        order,
      }));

      await SheetTrainingService.updateExerciseOrder({
        sheetId,
        exercises,
        trainingId,
      });

      toast({
        variant: "success",
        description: "Ordem dos exerícios alterada com sucesso",
      });

      handleTrainingUpdated();
    } catch (error) {
      toast({
        variant: "default",
        description: "Erro ao reordenar exercíceos",
      });
    }
    setUpdatingOrder(false);
  }

  const moveTrainingToNext = async (trainingId: string, index: number) => {
    if (!sheetId || index >= trainings.length - 1 || !data?.trainings) {
      return;
    }
    setUpdatingOrder(true);
    await SheetTrainingService.updateTrainingOrder({
      sheetId,
      index,
      trainingId,
      type: "NEXT",
    });

    onSuccess?.();
    setUpdatingOrder(false);
  };

  const moveTrainingToPrevious = async (trainingId: string, index: number) => {
    if (!sheetId || index <= 0 || !data?.trainings) {
      return;
    }
    setUpdatingOrder(true);

    await SheetTrainingService.updateTrainingOrder({
      sheetId,
      index,
      trainingId,
      type: "PREVIOUS",
    });

    onSuccess?.();
    setUpdatingOrder(false);
  };

  if (!sheetId || !data?.trainings || data?.trainings?.length === 0) {
    return (
      <div className="flex min-h-[100px]">
        <span className="m-auto text-zinc-300">
          Não há treino cadastrado para a letra selecionada{" "}
        </span>
      </div>
    );
  }

  const trainings = data?.trainings;

  return (
    <>
      <div className="grid gap-5">
        <Tabs
          className="mt-5"
          value={tabLetterValue}
          defaultValue={trainings[0].name}
          onValueChange={handleChangeTabLetter}
        >
          <TabsTrainingHeader
            trainings={trainings}
            isLoading={isUpdatingOrder}
            currentTab={tabLetterValue}
            onMoveToNext={moveTrainingToNext}
            onMoveToPrevious={moveTrainingToPrevious}
          />

          {trainings.map((training) => (
            <TabsContent key={training.name} value={training.name ?? ""}>
              <div className="grid">
                <SheetGridExercisesAdded
                  isLoading={isLoading || isUpdatingOrder}
                  sheetId={sheetId as string}
                  exercises={training.exercises as Exercise[]}
                  handleEdit={(exercise: Exercise) => handleEdit(exercise)}
                  handleRemove={(exercise: Exercise) => handleDelete(exercise)}
                  handleShowExecutions={(exercise: Exercise) =>
                    handleListExecution(exercise)
                  }
                  handleUpdateOrderListOfExercises={(exercises) =>
                    updateOrderListOfExercises(training.id!, exercises)
                  }
                />

                <Button
                  size="sm"
                  type="button"
                  variant="secondary"
                  className="mt-5"
                  onClick={() => handleAddExercise(training)}
                >
                  <PlusIcon className="h-4 w-4 mr-1" /> Adicionar exercício
                </Button>
              </div>
            </TabsContent>
          ))}
        </Tabs>
      </div>

      {dialogType === "ADD" && activeTraining?.id ? (
        <SheetDialogAddExercise
          isOpened={true}
          trainingId={activeTraining.id}
          onCancel={() => handleCloseDialog()}
          onChange={() => handleTrainingUpdated()}
        />
      ) : null}

      {dialogType === "EDIT" && exercise ? (
        <SheetDialogEditExercise
          isOpened={true}
          exercise={exercise}
          onCancel={() => handleCloseDialog()}
          onChange={() => handleTrainingUpdated()}
        />
      ) : null}

      {dialogType === "LIST_EXECUTION" && exercise ? (
        <SheetDialogExerciseExecution
          isOpened={true}
          exercise={exercise}
          handleClose={() => handleCloseDialog()}
        />
      ) : null}

      {dialogType === "DELETE" && exercise ? (
        <ModalConfirmDelete
          title="Confirmação"
          isVisible={true}
          onCancel={() => handleCloseDialog()}
          onContinue={() => handleRemoveExercise(exercise)}
          description={
            <span>
              Você tem certeza que quer <strong>remover</strong> o exercício{" "}
              {exercise?.name} do treino?
            </span>
          }
        />
      ) : null}
    </>
  );
}
