import * as React from "react";
import { DropzoneUpload } from "./ui/dropzone-upload";
import { bytesToMegaBytes } from "@/utils/functions/date-transform";
import { XIcon } from "lucide-react";

type DropzoneVideoProps = {
  error?: string;
  onChange: (file: File | null) => void;
};

export default function DropzoneVideo({ error, onChange }: DropzoneVideoProps) {
  const [file, setFile] = React.useState<File | null>(null);

  function handleChangeFile(files: File[]) {
    const data = files.length ? files[0] : null;

    setFile(data);
    onChange(data);
  }

  return (
    <div>
      <DropzoneUpload
        optionsDropzone={{ multiple: false }}
        onChangeFile={(files) => handleChangeFile(files)}
      >
        <div>
          <span className="mb-2 block text-lg font-semibold text-[#07074D]">
            Solte o vídeo aqui
          </span>
          <span className="mb-2 block text-sm font-medium text-[#6B7280]">
            ou
          </span>
          <span className="inline-flex rounded border border-[#e0e0e0] py-2 px-7 text-sm font-medium text-[#07074D]">
            Selecionar arquivo
          </span>
        </div>
      </DropzoneUpload>

      {error ? (
        <p className="mt-2 text-sm font-medium text-destructive">{error}</p>
      ) : null}

      {file ? (
        <div className="mt-5 mb-2 rounded-md bg-[#F5F7FB] p-4">
          <div className="flex items-center justify-between">
            <div className="flex flex-col">
              <span className="truncate font-medium text-[#07074D]">
                {file?.name}
              </span>
              <span className="truncate text-xs text-zinc-600">
                Tamanho: {bytesToMegaBytes(file?.size)} MB
              </span>
            </div>
            <button
              type="button"
              className="text-[#07074D]"
              onClick={() => handleChangeFile([])}
            >
              <XIcon size={18} />
            </button>
          </div>
        </div>
      ) : null}
    </div>
  );
}
