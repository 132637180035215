const Routes = {
  base: "/",
  signIn: "/sign-in",
  signUp: "/sign-up",
  config: "/configuracao",
  members: "/area-de-membros",
  account: {
    activate: "/account/active",
    forgotPassword: "/account/forgot-password",
  },
  home: "/home",
  professional: {
    list: "/profissional/listar",
    edit: "/profissional/:id/editar",
    create: "/profissional/cadastrar",
    subscribe: "/profissional/:id/subscribe",
  },
  template: {
    list: "/template/listar",
    edit: "/template/:id/editar",
  },
  student: {
    list: "/aluno/listar",
    edit: "/aluno/:id/editar",
    create: "/aluno/cadastrar",
    createSheet: "/aluno/:id/ficha/:sheetId?",
  },
  exerciseType: {
    list: "/exercicio-tipo/listar",
  },
  exercise: {
    list: "/exercicio/listar",
  },
};

export default Routes;
