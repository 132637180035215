import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useToast } from "@/components/ui/use-toast";
import { SheetService } from "@/services/api/sheet";
import { getToastErrorContent } from "@/utils/functions/api-error";
import { Loader2Icon } from "lucide-react";
import { useState } from "react";

type Props = {
  isOpen: boolean;
  onSuccess: () => void;
  onClose: () => void;
};

export default function ModalSaveSheetTemplate({
  isOpen,
  onClose,
  onSuccess,
}: Props) {
  const { toast } = useToast();
  const [name, setName] = useState("");
  const [error, setError] = useState<string>();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChangeInput = (value: string) => {
    if (error) {
      setError(undefined);
    }

    setName(value);
  };

  const handleSave = async () => {
    if (!name || name.length === 0) {
      setError("Adicione um nome para o template de ficha");

      return;
    }

    try {
      setError("");
      setIsSubmitting(true);

      await SheetService.saveTemplate(name);

      toast({
        variant: "success",
        description: "Template de ficha salvo com sucesso",
      });

      onSuccess();

      setIsSubmitting(false);
    } catch (error) {
      setIsSubmitting(false);

      toast(getToastErrorContent(error));
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-md bg-white">
        <DialogHeader>
          <DialogTitle>Criar template de ficha</DialogTitle>
          <DialogDescription>
            Adicione o nome para o template de ficha.
          </DialogDescription>
        </DialogHeader>
        <div className="flex items-center space-x-2">
          <div className="grid flex-1 gap-2">
            <Label htmlFor="link" className="sr-only">
              Link
            </Label>
            <Input
              id="link"
              value={name}
              className={error && "border-red-600 border-[1px] border-solid"}
              onChange={(e) => handleChangeInput(e.target.value)}
            />
            {error && <div className="text-sm text-red-600 mt-0">{error}</div>}
          </div>
        </div>
        <DialogFooter className="sm:justify-end">
          <Button
            type="button"
            variant="secondary"
            onClick={onClose}
            disabled={isSubmitting}
          >
            Cancelar
          </Button>
          <Button type="button" onClick={handleSave} disabled={isSubmitting}>
            {isSubmitting && (
              <Loader2Icon className="mr-2 h-4 w-4 animate-spin" />
            )}
            Salvar
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
