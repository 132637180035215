import { ReactNode } from "react";
import Sidebar from "./sidebar";
import { cn } from "@/utils";

type LayoutProps = {
  children: ReactNode;
} & React.HTMLAttributes<HTMLDivElement>;

export default function RootLayout({ children, className }: LayoutProps) {
  return (
    <div className="bg-gray-900 w-screen min-h-screen grid lg:grid-cols-5">
      <Sidebar />

      <div
        className={cn(
          "py-5 px-8 pl-6 relative w-full overflow-scroll max-w-[100vw] mt-[50px] lg:mt-0 lg:ml-[220px] lg:w-[calc(100vw-220px)] ",
          className
        )}
      >
        {children}
      </div>
    </div>
  );
}
