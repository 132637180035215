import { ExerciseTypeService } from '@/services/api/exercise-type';
import { useQuery } from '@tanstack/react-query';

type GetExercisesTypesList = {
  enabled?: boolean
}

export function useExercisesTypesList({ enabled = true }: GetExercisesTypesList) {
  return useQuery({
    enabled,
    queryKey: ["execise_types_only_data"],
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    queryFn: ExerciseTypeService.getOnlyData,
  });
}