export function getDateFormatInput(date: string | undefined) {
  return date?.split("/").reverse().join("-");
}

export function getDateFormatText(date: string | undefined) {
  return date?.substring(0, 10).split("-").reverse().join("/");
}

export function bytesToMegaBytes(bytes: number) {
  return (bytes / 1024 ** 2).toFixed(2);
}
