import { ReactNode } from "react";

type Props = {
  children: ReactNode;
};

export default function FormPublicWrapper({ children }: Props) {
  return (
    <div className="w-full flex justify-center items-center p-8 min-h-screen overflow-hidden bg-dark-background">
      <div className="w-full lg:max-w-lg">{children}</div>
    </div>
  );
}
