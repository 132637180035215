import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { PlusCircleIcon } from "lucide-react";
import { useState } from "react";

type Props = {
  onSave: (name: string) => Promise<void>;
};

export default function ModalAddLetter({ onSave }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const [name, setName] = useState("");
  const [error, setError] = useState<string>();

  const handleChangeInput = (value: string) => {
    if (error) {
      setError(undefined);

      return;
    }

    setName(value);
  };

  const onOpen = () => {
    setIsOpen(true);
  };

  const onClose = () => {
    setName("");

    setIsOpen(false);
  };

  const handleSave = async () => {
    if (!name) {
      setError("Adicione um nome para a 'Letra'");

      return;
    }

    await onSave(name);

    onClose();
  };

  return (
    <Dialog open={isOpen}>
      <Button onClick={onOpen}>
        <PlusCircleIcon className="mr-2 w-4 h-4" /> Adicionar "Letra"
      </Button>

      <DialogContent className="sm:max-w-md bg-white">
        <DialogHeader>
          <DialogTitle>Crie uma nova "Letra"</DialogTitle>
          <DialogDescription className="text-xs">
            Exemplo: A, B, C ou Segunda á Sexta.
          </DialogDescription>
        </DialogHeader>

        <div className="flex items-center space-x-2 mt-3">
          <div className="grid flex-1 gap-2">
            <Label htmlFor="link">Nome</Label>
            <Input
              id="link"
              value={name}
              className={error && "border-red-600 border-[1px] border-solid"}
              onChange={(e) => handleChangeInput(e.target.value)}
            />
            {error && <div className="text-sm text-red-600 mt-0">{error}</div>}
          </div>
        </div>

        <DialogFooter className="sm:justify-end">
          <Button type="button" variant="secondary" onClick={onClose}>
            Cancelar
          </Button>
          <Button type="button" onClick={handleSave}>
            Salvar
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
