import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { SheetService } from "@/services/api/sheet";
import { DialogDescription } from "@radix-ui/react-dialog";
import {
  CheckCircleIcon,
  CircleIcon,
  ImportIcon,
  Loader2Icon,
} from "lucide-react";
import { useEffect, useState } from "react";

type Props = {
  onCopyTemplate: (name: string) => Promise<void>;
};

type SheetTemplate = {
  id: string;
  name: string;
  data: any;
};

const ModalCopyTemplateSheet: React.FC<Props> = ({ onCopyTemplate }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [templates, setTemplates] = useState<SheetTemplate[]>([]);
  const [templateId, setTemplateId] = useState<string>();
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (isOpen) setIsLoading(true);
    setTemplateId(undefined);
    SheetService.getAllTemplates().then((data) => {
      setIsLoading(false);
      setTemplates(data);
    });
  }, [isOpen]);

  const onOpen = () => {
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  const onSave = async () => {
    if (!templateId) {
      return;
    }

    try {
      setIsSubmitting(true);

      await onCopyTemplate(templateId);

      setIsOpen(false);
      setIsSubmitting(false);
    } catch (error) {
      setIsSubmitting(false);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <Button onClick={onOpen} className="">
        <ImportIcon className="mr-2" /> Copiar template
      </Button>
      <DialogContent className="sm:max-w-md bg-white">
        <DialogHeader>
          <DialogTitle>Importar template de ficha</DialogTitle>
          <DialogDescription>
            Selecione um template para importar
          </DialogDescription>
        </DialogHeader>
        {templates.length === 0 && (
          <div className="flex items-center space-x-2">
            Não possui template de ficha
          </div>
        )}
        <div className="h-[300px] overflow-scroll">
          {templates.map((item) => (
            <div
              key={item.id}
              className={`border-[2px] p-2 rounded-lg  flex cursor-pointer hover:bg-gray-100 transition-all items-center mb-2 ${
                templateId === item.id && "border-black"
              }`}
              onClick={() => setTemplateId(item.id)}
            >
              {templateId === item.id ? (
                <CheckCircleIcon size={20} />
              ) : (
                <CircleIcon size={20} />
              )}
              <span className="ml-2">{item.name}</span>
            </div>
          ))}
          {isLoading && (
            <Loader2Icon className="h-5 w-5 animate-spin m-5 mx-auto" />
          )}
        </div>
        <DialogFooter className="sm:justify-end">
          <Button
            type="button"
            variant="secondary"
            onClick={onClose}
            disabled={isSubmitting}
          >
            Cancelar
          </Button>
          {templateId && (
            <Button type="button" onClick={onSave} disabled={isSubmitting}>
              {isSubmitting && (
                <Loader2Icon className="mr-2 h-4 w-4 animate-spin" />
              )}
              Criar nova ficha
            </Button>
          )}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default ModalCopyTemplateSheet;
