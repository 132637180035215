import { getAllExercises } from '@/services/api/exercise';
import { useInfiniteQuery } from '@tanstack/react-query';

type GetInfinityListProps = {
  type?: string | undefined
  name?: string | undefined
  take?: number | undefined
  category?: string | undefined
  enabled?: boolean | undefined
}

export function useExercisesInfinityList({ type, name, category, take = 8, enabled = true }: GetInfinityListProps) {
  return useInfiniteQuery({
    enabled,
    queryKey: ["execises", type, name, category],
    initialPageParam: 1,
    refetchOnWindowFocus: false,
    queryFn: ({ pageParam: page = 1 }) => getAllExercises({ page, name, type, take, category }),
    getNextPageParam: ({ pagination }) => pagination?.nextPage
  });
}