import { useUserProvider } from "@/utils/providers/user-provider";
import { Avatar, AvatarFallback, AvatarImage } from "./ui/avatar";
import { useRef, useState } from "react";
import { Button } from "./ui/button";
import { CameraIcon, Loader2Icon } from "lucide-react";
import { Input } from "./ui/input";
import { ProfessionalService } from "@/services/api/professional";
import useFileBucket from "@/utils/hooks/use-file-bucket";

export default function UserProfileImage() {
  const inputFileRef = useRef<HTMLInputElement>(null);
  const { getSignedUrl } = useFileBucket();
  const { user, updateUser } = useUserProvider();
  const [imageUrl, setImageUrl] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmitFile = async (file: File) => {
    try {
      setIsSubmitting(true);

      const image_url = await getSignedUrl(file, "profile", true);

      await ProfessionalService.updateImage({ image_url });

      updateUser({ image_url });

      setIsSubmitting(false);
    } catch (error) {
      console.log(error);
      setIsSubmitting(false);
    }
  };

  const handleChangeFile = async (files: FileList | null) => {
    if (!files?.length) {
      return;
    }

    setImageUrl(URL.createObjectURL(files[0]));

    handleSubmitFile(files[0]);
  };

  const handleSelectFile = () => () => {
    inputFileRef?.current?.click();
  };

  function ButtonUploadIcon() {
    if (isSubmitting) {
      return <Loader2Icon className="h-5 w-5 animate-spin" />;
    }

    return <CameraIcon className="w-5 h-5" />;
  }

  return (
    <>
      <div className="flex flex-col items-center">
        <div className="relative mb-5">
          <Avatar className="w-24 h-24 border-4 border-zinc-200">
            <AvatarImage src={user?.image_url ?? imageUrl} />
            <AvatarFallback className="uppercase">
              {user?.full_name?.slice(0, 2)}
            </AvatarFallback>
          </Avatar>

          <div className="absolute -bottom-2 -right-2">
            <Button
              type="button"
              size="icon"
              variant="secondary"
              disabled={isSubmitting}
              className="rounded-full"
              onClick={handleSelectFile()}
            >
              <ButtonUploadIcon />

              <Input
                id="picture"
                type="file"
                ref={inputFileRef}
                accept="image/*"
                className="hidden"
                onChange={(event) => handleChangeFile(event?.target?.files)}
              />
            </Button>
          </div>
        </div>

        <span className="text-xl font-semibold text-zinc-100">
          {user?.full_name}
        </span>

        <span className="text-sm text-zinc-400 mt-1">{user?.email}</span>
      </div>
    </>
  );
}
