import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useToast } from "@/components/ui/use-toast";
import { StudentService } from "@/services/api/student";
import { StudentGroupService } from "@/services/api/student-group";
import { cn } from "@/utils";
import { Student, StudentGroup } from "@/utils/entities";
import useRefetchRefetchQueries from "@/utils/hooks/use-invalidate-query-client";
import { format } from "date-fns";
import { CalendarIcon, Loader2Icon } from "lucide-react";
import { useCallback, useEffect, useState } from "react";

type Props = {
  isOpen: boolean;
  student?: Student;
  onSave: (data: { groupId: string; contract_date: string }) => void;
  onClose: () => void;
};

export default function ModalChangeGroup({ isOpen, onClose, student }: Props) {
  const { toast } = useToast();
  const [groups, setGroups] = useState<StudentGroup[]>([]);
  const [newDate, setNewDate] = useState<string>();
  const { refetchStudentData } = useRefetchRefetchQueries();
  const [newGroupId, setNewGroupId] = useState<string>();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const loadLists = useCallback(async () => {
    const groupsResult = await StudentGroupService.getAll();
    setGroups(groupsResult);
  }, []);

  useEffect(() => {
    loadLists();
  }, [loadLists]);

  useEffect(() => {
    if (student) {
      setNewDate(student.user.contract_date);
      setNewGroupId(student.groupId);
    } else {
      setNewDate(undefined);
      setNewGroupId(undefined);
    }
  }, [student]);

  const onChangeDate = (day: Date | undefined) => {
    if (day && student) {
      console.log({ day });
      setNewDate(day.toJSON());
    }
  };

  const handleSave = async () => {
    if (!student || !newDate || !newGroupId) {
      return;
    }

    try {
      setIsSubmitting(true);

      await StudentService.update({
        id: student.id,
        user: { id: student.user.id, contract_date: newDate },
        groupId: newGroupId,
      } as any);

      toast({
        variant: "success",
        description: "Informações atualizadas com sucesso",
      });

      await refetchStudentData(student.id);

      setIsSubmitting(false);

      onClose();
    } catch (error) {
      console.log(error);

      setIsSubmitting(false);

      toast({
        variant: "destructive",
        description: "Não foi possível atualizar as informações",
      });
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px] bg-white">
        <DialogHeader className="border-b-[1px] border-black pb-2 mb-4">
          <DialogTitle>
            {student?.user.name + " " + student?.user.surname}
          </DialogTitle>
        </DialogHeader>

        <Label>Grupo do usuário</Label>

        <Select value={newGroupId} onValueChange={setNewGroupId}>
          <SelectTrigger className="data-[placeholder]:text-gray-500">
            <SelectValue placeholder="Selecione um grupo" />
          </SelectTrigger>
          <SelectContent>
            {groups.map((item) => (
              <SelectItem key={item.id} value={item.id || ""}>
                {item.name}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>

        <Label className="mt-4">Nova data de vencimento do contrato</Label>
        <Popover>
          <PopoverTrigger asChild>
            <Button
              variant={"outline"}
              className={cn(
                "w-full justify-start text-left font-normal",
                !newDate && "text-muted-foreground"
              )}
            >
              <CalendarIcon className="mr-2 h-4 w-4" />
              {newDate ? (
                format(newDate, "dd/MM/yyyy")
              ) : (
                <span>Selecione uma data</span>
              )}
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-auto p-0" align="start">
            {newDate && (
              <Calendar
                className="w-full"
                mode="single"
                onSelect={onChangeDate}
                selected={new Date(newDate)}
                initialFocus
              />
            )}
          </PopoverContent>
        </Popover>

        <DialogFooter className="mt-4">
          <Button
            type="button"
            variant="outline"
            onClick={onClose}
            disabled={isSubmitting}
          >
            Cancelar
          </Button>
          <Button
            type="button"
            disabled={!newGroupId || isSubmitting}
            onClick={handleSave}
          >
            {isSubmitting && (
              <Loader2Icon className="mr-2 h-4 w-4 animate-spin" />
            )}
            Salvar
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
