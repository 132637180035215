import { cn } from "@/utils";
import { Loader2Icon } from "lucide-react";

type SpinnerProps = {
  className?: string;
};

export default function Spinner({ className }: SpinnerProps) {
  return <Loader2Icon className={cn("h-5 w-5 animate-spin", className)} />;
}
