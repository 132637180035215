import api from "./config";

type UpdateExerciseOrderInput = {
  sheetId: string;
  trainingId: string;
  exercises: Array<{ id: string; order: number }>;
};

type UpdateTrainingOrderInput = {
  sheetId: string;
  index: number;
  trainingId: string;
  type: "NEXT" | "PREVIOUS";
};
async function updateExerciseOrder(data: UpdateExerciseOrderInput) {
  const { sheetId, trainingId, exercises } = data;

  return (
    await api.put(`/sheets/${sheetId}/training/${trainingId}/order`, {
      exercises,
    })
  ).data;
}
async function updateTrainingOrder(data: UpdateTrainingOrderInput) {
  const { sheetId, index, type, trainingId } = data;

  return (
    await api.put(`/sheets/${sheetId}/training/order`, {
      index,
      type,
      trainingId,
    })
  ).data;
}

export const SheetTrainingService = {
  updateExerciseOrder,
  updateTrainingOrder,
};
