import { Button } from "@/components/ui/button";
import { UploadIcon } from "lucide-react";
import { Card } from "@/components/ui/card";
import { useUserProvider } from "@/utils/providers/user-provider";
import { useRef, useState } from "react";
import { Input } from "@/components/ui/input";
import { ProfessionalService } from "@/services/api/professional";
import DefaultImage from "@/assets/default-icon.png";
import useFileBucket from "@/utils/hooks/use-file-bucket";
import { Loading } from "@/components/ui/loading";
import { useToast } from "@/components/ui/use-toast";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";

const defaultColor = "#ffffff";

type MessageTexts = {
  terms_of_use?: string | undefined;
  welcome_message?: string | undefined;
  benefits_message?: string | undefined;
};

const AppLayout = () => {
  const { toast } = useToast();
  const inputFileRef = useRef<HTMLInputElement>(null);
  const { getSignedUrl } = useFileBucket();
  const { user, updateUser } = useUserProvider();
  const [newImage, setNewImage] = useState<File | undefined>();
  const [newColor, setNewColor] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [messageTexts, setMessageTexts] = useState<MessageTexts>();

  const handleSelectFile = () => () => {
    inputFileRef?.current?.click();
  };

  const handleChangeFile = async (files: FileList | null) => {
    if (!files?.length) {
      return;
    }

    setNewImage(files[0]);
  };

  const handleSubmit = async () => {
    if (!user?.professional?.id) {
      return;
    }

    setIsLoading(true);

    const settings = { ...messageTexts } as any;

    if (newImage) {
      settings.logo_image_url = await getSignedUrl(newImage, "app", true);
    }

    if (newColor) {
      settings.primary_color = newColor;
    }

    if (!settings) {
      return;
    }

    await ProfessionalService.updateSettings(settings);

    updateUser({
      professional: {
        ...user.professional,
        theme: {
          ...user.professional.theme,
          ...settings,
        },
      },
    });

    setIsLoading(false);
    setIsEditing(false);

    toast({
      variant: "success",
      description: "App atualizado com sucesso!",
    });
  };

  const handleCancel = () => {
    setNewColor(undefined);
    setNewImage(undefined);
    setIsEditing(false);
  };

  const sourceImage =
    (newImage && URL.createObjectURL(newImage)) ||
    user?.professional?.theme?.logo_image_url;

  const colorOfApp = newColor ?? user?.professional?.theme?.primary_color;

  return (
    <>
      <Card className="flex flex-row bg-gray-800 p-4 gap-12">
        <div className="flex min-w-[500px] flex-col gap-10">
          <div className="flex flex-col gap-3">
            <span className="text-white">Imagem de exibição do aplicativo</span>

            <div className="flex flex-row items-center gap-4">
              <Avatar
                className="w-20 h-20 border-solid border-[1px] cursor-pointer"
                onClick={handleSelectFile()}
              >
                <AvatarImage src={sourceImage ?? DefaultImage} />
                <AvatarFallback className="uppercase">AI</AvatarFallback>
              </Avatar>

              <Button
                type="button"
                variant="secondary"
                className="rounded-xl"
                onClick={handleSelectFile()}
              >
                <UploadIcon className="w-5 h-5 mr-2" />
                Atualizar imagem
              </Button>

              <Input
                id="picture"
                type="file"
                ref={inputFileRef}
                accept="image/*"
                className="hidden"
                onChange={(event) => handleChangeFile(event?.target?.files)}
              />
            </div>
          </div>

          <div className="flex flex-col gap-3">
            <span className="text-white">Cor primária do aplicativo</span>

            <div className="flex flex-row gap-4 border-[1px] py-2 px-3 w-fit rounded-lg min-w-[180px]">
              <input
                id="theme_color"
                name=""
                type="color"
                value={colorOfApp ?? defaultColor}
                onChange={(e) => setNewColor(e.target.value)}
              />
              <label
                htmlFor="theme_color"
                className="text-white cursor-pointer mx-auto"
              >
                Atualizar cor primaria
              </label>
            </div>
          </div>

          <div className="flex flex-col w-full gap-5">
            <div className="flex flex-col flex-1">
              <label className="text-white mb-2">Banefícios do app</label>
              <textarea
                defaultValue={user?.professional?.theme?.benefits_message}
                className="min-h-[200px] rounded-lg p-2"
                placeholder={`Adicione aqui oque seu cliente deve ver ao acessar o seu link de cadastro:\n- Exemplo 1\n- Exemplo 2`}
                onChange={(event) =>
                  setMessageTexts((prev) => ({
                    ...prev,
                    benefits_message: event.target.value,
                  }))
                }
              ></textarea>
            </div>
            <div className="flex flex-col flex-1">
              <label className="text-white mb-2">Mensagem de boas vindas</label>
              <textarea
                className="min-h-[200px] rounded-lg p-2"
                defaultValue={user?.professional?.theme?.welcome_message}
                placeholder="- Digite aqui uma mensagem para seu cliente, e ele verá ao finalizar o cadastro"
                onChange={(event) =>
                  setMessageTexts((prev) => ({
                    ...prev,
                    welcome_message: event.target.value,
                  }))
                }
              ></textarea>
            </div>
          </div>
        </div>

        <div className="flex flex-1 flex-col">
          <label className="text-white mb-2">Termo de uso do aplicativo</label>
          <textarea
            className="min-h-[200px] h-full rounded-lg p-2"
            defaultValue={user?.professional?.theme?.terms_of_use}
            onChange={(event) =>
              setMessageTexts((prev) => ({
                ...prev,
                terms_of_use: event.target.value,
              }))
            }
          ></textarea>
        </div>
      </Card>

      <div className="mt-6 flex gap-3">
        <Button variant="ghost" className="text-white" onClick={handleCancel}>
          Cancelar
        </Button>
        <Button
          onClick={handleSubmit}
          className="bg-white text-gray-800 hover:bg-gray-300"
        >
          Aplicar mudanças
        </Button>
      </div>

      {isLoading || isEditing ? <Loading /> : null}
    </>
  );
};

export default AppLayout;
