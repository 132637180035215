import { Button } from "@/components/ui/button";
import { Input, InputProps } from "@/components/ui/input";
import { cn } from "@/utils";
import { SearchIcon, XIcon } from "lucide-react";
import * as React from "react";

type SearchProps = InputProps & {
  defaultValue?: string;
  onClear: () => void;
  onSearch: (value: string) => void;
};

export default function Search({
  onClear,
  onSearch,
  defaultValue,
  className,
  ...props
}: SearchProps) {
  const [value, setValue] = React.useState(defaultValue || "");

  function handleClear() {
    setValue("");

    onClear?.();
  }

  return (
    <div className="my-5 flex gap-4">
      <Input
        value={value}
        placeholder="Busque pelo nome do exercício ..."
        className={cn("text-zinc-200", className)}
        onChange={(e) => setValue(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onSearch(value.trim());
          }
        }}
        {...props}
      />

      <Button variant="secondary" onClick={() => onSearch(value.trim())}>
        <SearchIcon className="h-4 w-4 mr-2" /> Buscar
      </Button>

      {value ? (
        <Button variant="secondary" onClick={() => handleClear()}>
          <XIcon className="h-4 w-4 mr-2" /> Limpar
        </Button>
      ) : null}
    </div>
  );
}
