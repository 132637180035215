import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import * as z from "zod";

const FormSchema = z.object({
  name: z.string().min(1, { message: "Campo obrigatório" }),
  description: z.string().min(1, { message: "Campo obrigatório" }),
});
export type FormSchemaType = z.infer<typeof FormSchema>;

type Props = {
  isOpen: boolean;
  initialValues?: FormSchemaType;
  onClose: () => void;
  onSubmit: (values: FormSchemaType) => void;
};

const UserGroupForm: React.FC<Props> = ({
  isOpen,
  initialValues,
  onClose,
  onSubmit,
}) => {
  const form = useForm<FormSchemaType>({
    resolver: zodResolver(FormSchema),
    values: { name: "", description: "" },
  });

  useEffect(() => {
    form.reset(initialValues);
  }, [isOpen]);

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px] bg-white">
        <DialogHeader className="border-b-[1px] border-black pb-2">
          <DialogTitle>
            {initialValues ? "Atualizar grupo" : "Novo grupo"}
          </DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className="flex flex-col gap-4">
              <div className="grid gap-3">
                <FormField
                  name="name"
                  control={form.control}
                  render={({ field }) => (
                    <FormItem className="">
                      <FormLabel className="text-gray-600">Nome</FormLabel>

                      <FormControl>
                        <Input
                          className="bg-gray-100 text-gray-600"
                          {...field}
                        />
                      </FormControl>

                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <FormField
                name="description"
                control={form.control}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-gray-600">Descrição</FormLabel>

                    <FormControl>
                      <Input
                        className="bg-gray-100 text-gray-600 "
                        {...field}
                      />
                    </FormControl>

                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <DialogFooter className="mt-6">
              <Button variant="outline" onClick={onClose} type="button">
                Cancelar
              </Button>
              <Button type="submit">Salvar</Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default UserGroupForm;
