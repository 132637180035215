import RootLayout from "@/components/layout";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import Routes from "@/routes/paths";
import { StudentService } from "@/services/api/student";
import { Student, UserStatus } from "@/utils/entities";
import { getDateFormatText } from "@/utils/functions/date-transform";
import { useQuery } from "@tanstack/react-query";
import { ArrowRightCircle, Loader2Icon } from "lucide-react";
import { generatePath, useNavigate, useSearchParams } from "react-router-dom";
import Search from "../exercise/components/search";
import { Separator } from "@/components/ui/separator";
import UseGroupStudents from "@/utils/hooks/use-group-students";

type FilterType = "name" | "sheet" | "status" | "contract" | "group";

export default function StudentListScreen() {
  const navigate = useNavigate();
  const { studentGroups } = UseGroupStudents();
  const [searchParams, setSearchParams] = useSearchParams();

  const name = searchParams.get("name") ?? undefined;
  const group = searchParams.get("group") ?? undefined;
  const sheet = searchParams.get("sheet") ?? undefined;
  const status = searchParams.get("status") ?? undefined;
  const contract = searchParams.get("contract") ?? undefined;

  const { data, isLoading } = useQuery({
    queryKey: ["students", name, group, sheet, status, contract],
    retry: 3,
    refetchOnWindowFocus: false,
    queryFn: () =>
      StudentService.getAll({ name, group, sheet, status, contract }),
  });

  function handleChangeFilter(prop: FilterType, value: string) {
    if (value.trim()) {
      searchParams.set(prop, value);
    } else {
      searchParams.delete(prop);
    }

    setSearchParams(searchParams, { replace: true });
  }

  function handleClearSearch(prop: FilterType) {
    if (searchParams.has(prop)) {
      searchParams.delete(prop);

      setSearchParams(searchParams, { replace: true });
    }
  }

  const TableFilters = () => {
    return (
      <div className="flex items-center gap-4">
        <Select
          value={group}
          onValueChange={(value) => handleChangeFilter("group", value)}
        >
          <SelectTrigger className="w-[180px] text-white">
            <SelectValue placeholder="Grupo" className="text-white" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value={" "}>Todos</SelectItem>
            {studentGroups?.map((item) => (
              <SelectItem key={item?.id} value={String(item?.id)}>
                {item?.name}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>

        <Select
          value={status}
          onValueChange={(value) => handleChangeFilter("status", value)}
        >
          <SelectTrigger className="w-[180px] text-white">
            <SelectValue placeholder="Status" className="text-white" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value={" "}>Todos</SelectItem>
            <SelectItem value="ACTIVE">Ativos</SelectItem>
            <SelectItem value="INACTIVE">Inativos</SelectItem>
          </SelectContent>
        </Select>

        <Select
          value={contract}
          onValueChange={(value) => handleChangeFilter("contract", value)}
        >
          <SelectTrigger className="w-[180px] text-white">
            <SelectValue placeholder="Contrato" className="text-white" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value={" "}>Todos</SelectItem>
            <SelectItem value="EXPIRED">Vencidos</SelectItem>
            <SelectItem value="EXPIRING">Vencendo</SelectItem>
          </SelectContent>
        </Select>

        <Select
          value={sheet}
          onValueChange={(value) => handleChangeFilter("sheet", value)}
        >
          <SelectTrigger className="w-[180px] text-white">
            <SelectValue placeholder="Ficha" className="text-white" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value={" "}>Todos</SelectItem>
            <SelectItem value="EXPIRED">Vencidos</SelectItem>
            <SelectItem value="EXPIRING">Vencendo</SelectItem>
          </SelectContent>
        </Select>
      </div>
    );
  };

  return (
    <RootLayout>
      <div className="flex flex-col">
        <TableFilters />

        <Search
          placeholder="Busque pelo nome do aluno ..."
          defaultValue={name}
          onClear={() => handleClearSearch("name")}
          onSearch={(value) => handleChangeFilter("name", value)}
        />
      </div>

      <Separator />

      <Card className="bg-gray-800 mt-5">
        <CardHeader>
          <CardTitle className="flex flex-col text-gray-200">Alunos</CardTitle>
        </CardHeader>
        <CardContent className="grid w-full overflow-auto">
          <Table>
            <TableCaption>
              {isLoading && (
                <Loader2Icon className="mx-auto h-10 w-10 animate-spin" />
              )}
            </TableCaption>
            <TableHeader>
              <TableRow>
                <TableHead className="text-gray-400 md:w-[100px]">
                  Status
                </TableHead>
                <TableHead className="text-gray-400 min-w-[200px]">
                  Nome
                </TableHead>
                <TableHead className="text-gray-400 md:w-[200px]">
                  Telefone
                </TableHead>
                <TableHead className="text-gray-400 md:w-[300px]">
                  Grupo
                </TableHead>
                <TableHead className="text-gray-400 md:w-[180px]">
                  Data do contrato
                </TableHead>
                <TableHead />
              </TableRow>
            </TableHeader>
            <TableBody className="text-gray-200">
              {data?.map((item) => (
                <TableRow
                  key={item?.id}
                  className="cursor-pointer hover:bg-gray-600"
                  onClick={() => {
                    navigate(
                      generatePath(Routes.student.edit, {
                        id: item?.id,
                      })
                    );
                  }}
                >
                  <TableCell>
                    {item?.user.status === UserStatus.ACTIVE ? "🟢 " : "🔴 "}
                    {item?.user?.statusText}
                  </TableCell>
                  <TableCell>{item?.user?.full_name}</TableCell>
                  <TableCell>{item.user?.phone}</TableCell>
                  <TableCell>{item?.group?.name}</TableCell>
                  <TableCell>
                    {getDateFormatText(item?.user?.contract_date)}
                  </TableCell>

                  <TableCell>
                    <ArrowRightCircle />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </RootLayout>
  );
}
