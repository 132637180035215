import { Student, User } from "@/utils/entities";
import api from "./config";
import { parseUser } from "./user";

type SubscribeStudentInput = { professionalId: string } & Partial<User>;

type CreateOrUpdateInput = Omit<Student, "status" | "statusText" | "image_url">;

type StudentListGetInput = {
  name?: string | undefined;
  group?: string | undefined;
  sheet?: string | undefined;
  status?: string | undefined;
  contract?: string | undefined;
};

export async function listStudents(params: StudentListGetInput) {
  const { students } = (await api.get("/students", { params })).data;

  const data = students.map((item: Student) => ({
    ...item,
    user: parseUser(item.user),
  }));


  return data as Student[]
}

export async function getOneStudent(id: string) {
  const { student } = (await api.get(`/student/${id}`)).data;

  return student as Student;
}

async function subscribeStudent(data: SubscribeStudentInput) {
  const { student } = (await api.post("/student-subscribe", data)).data;

  return student as Student;
}

async function updateStudent(data: CreateOrUpdateInput) {
  const { student } = (await api.put(`/student/${data.id}`, data)).data;

  return student as Student;
}

export async function createOrUpdateStudent(data: CreateOrUpdateInput) {
  if (data?.id) {
    return updateStudent(data);
  }
}

export async function listStudentsWithoutGroup() {
  const { students } = (await api.get("/students-for-approval")).data;

  return students.map((item: Student) => ({
    ...item,
    user: parseUser(item.user),
  })) as Student[];
}

export const StudentService = {
  getAll: listStudents,
  getOne: getOneStudent,
  update: updateStudent,
  subscribe: subscribeStudent,
  getWithoutGroup: listStudentsWithoutGroup,
};
