import { useNavigate } from "react-router-dom";
import { Button } from "../ui/button";
import { ArrowLeftIcon } from "lucide-react";

export default function ButtonBack() {
  const navigate = useNavigate();

  function handleBack() {
    navigate(-1);
  }

  return (
    <Button
      type="button"
      size="icon"
      variant="ghost"
      className="mr-3"
      onClick={handleBack}
    >
      <ArrowLeftIcon className="h-4 w-4" />
    </Button>
  );
}
