import { GenericStatus, ExerciseType, UserType } from "@/utils/entities";
import api from "./config";
import { getProfessional } from "../storage/professional";
import StorageUser from "../storage/user";

type CreateInput = {
  name: string;
};

type UpdateInput = {
  id?: string;
  name?: string;
  status?: string;
};

function getStatusText(status: string) {
  switch (status) {
    case GenericStatus.ACTIVE: {
      return "Ativo";
    }
    case GenericStatus.INACTIVE: {
      return "Inativo";
    }
    case GenericStatus.PENDING: {
      return "Pendente";
    }
    default: {
      return "Pendente";
    }
  }
}

function parseItem(item: ExerciseType) {
  return {
    ...item,
    isActive: item.status === GenericStatus.ACTIVE,
    statusText: getStatusText(item.status as GenericStatus),
  };
}

async function getAllExerciseType(): Promise<ExerciseType[]> {
  const response = await api.get("/exercise-types");

  return response.data.map((item: ExerciseType) => parseItem(item));
}

async function getAllOnlyDataExerciseType(): Promise<ExerciseType[]> {
  const user = await StorageUser.get();

  if (user?.type === UserType.ADMIN) {
    return getAllExerciseType()
  }

  const id = user?.professional?.id

  const response = await api.get(`/exercise-types?professionalId=${id}`);

  return response.data.map((item: ExerciseType) => parseItem(item));
}

async function createExerciseType(data: CreateInput) {
  return (await api.post("/exercise-types", data)).data;
}

async function updateExerciseType(data: UpdateInput) {
  return (await api.put(`/exercise-types/${data.id}`, data)).data;
}

async function deleteExerciseType(id: string) {
  return (await api.delete(`/exercise-types/${id}`)).data;
}

async function createOrUpdateExerciseType(data: UpdateInput) {
  if (data?.id) {
    return updateExerciseType(data as UpdateInput);
  }

  return createExerciseType(data as CreateInput);
}

export const ExerciseTypeService = {
  getAll: getAllExerciseType,
  create: createExerciseType,
  update: updateExerciseType,
  delete: deleteExerciseType,
  getOnlyData: getAllOnlyDataExerciseType,
  createOrUpdate: createOrUpdateExerciseType,
};
