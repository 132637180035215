import { MemberContent } from "@/utils/entities";
import api from "./config";

export type CreateInput = {
  title: string;
  description: string;
  fileUrl: string;
  categoryId: string;
  isPublished?: boolean;
  userGroupId?: string[]
}

export type UpdateInput = {
  id: string;
} & CreateInput

const getAll = async () => {
  const { memberContent } = (await api.get("/members/content")).data;

  return memberContent as MemberContent[];
};

const create = async (data: CreateInput) => {
  const { memberContent } = (await api.post("/members/content", data)).data;

  return memberContent as MemberContent;
};

const update = async (data: UpdateInput) => {
  const { MemberContent } = (await api.put(`/members/content/${data.id}`, data)).data;

  return MemberContent as MemberContent;
};

const deleteMemberContent = async (id: string) => {
  return (await api.delete(`/members/content/${id}`)).data;
};

export async function createOrUpdate(data: UpdateInput) {
  if (data?.id) {
    return update(data as UpdateInput);
  }

  return create(data as CreateInput);
}

const updateVisibilityContent = async (id: string, data: Partial<MemberContent>) => {
  const { MemberContent } = (await api.put(`/members/content/${id}`, data)).data;

  return MemberContent as MemberContent;
};

export const MemberContentService = {
  getAll,
  delete: deleteMemberContent,
  create,
  update,
  createOrUpdate,
  updateVisibilityContent
};
