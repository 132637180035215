import { MemberCategory } from "@/utils/entities";
import api from "./config";

const getAll = async () => {
  const { categoriesMembers } = (await api.get("/members/category")).data;

  return categoriesMembers as MemberCategory[];
};

const create = async (data: MemberCategory) => {
  const { memberCategory } = (await api.post("/members/category", data)).data;

  return memberCategory as MemberCategory;
};

const update = async (data: MemberCategory) => {
  const { memberCategory } = (await api.put(`/members/category/${data.id}`, data)).data;

  return memberCategory as MemberCategory;
};

export async function createOrUpdate(data: MemberCategory) {
  if (data?.id) {
    return update(data);
  }

  return create(data);
}

export const MemberCategoryService = {
  getAll,
  createOrUpdate,
};
