import * as React from "react";

import { cn } from "@/utils";
import { InputIcon } from "../input-icon";
import { EyeIcon, EyeOffIcon } from "lucide-react";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, ...props }, ref) => {
    return (
      <input
        type={type}
        className={cn(
          "flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
          className
        )}
        ref={ref}
        {...props}
      />
    );
  }
);

const InputPassword = React.forwardRef<HTMLInputElement, InputProps>(
  (props, ref) => {
    const [passwordType, setPasswordType] = React.useState("password");

    const togglePasswordInputType = () =>
      setPasswordType((prev) => (prev === "password" ? "text" : "password"));

    const InputPasswordIcon =
      passwordType === "password" ? EyeIcon : EyeOffIcon;

    return (
      <InputIcon
        icon={<InputPasswordIcon size={20} className="text-zinc-700" />}
        onClick={togglePasswordInputType}
      >
        <Input {...props} type={passwordType} ref={ref} />
      </InputIcon>
    );
  }
);

Input.displayName = "Input";

export { Input, InputPassword };
