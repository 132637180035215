import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import Spinner from "../ui/spinner";
import { ReactNode } from "react";

type Props = {
  title?: string;
  isVisible?: boolean;
  description?: ReactNode;
  isLoading?: boolean;
  onCancel: () => void;
  onContinue: () => void;
};

export default function ModalConfirmDelete({
  title,
  description,
  onContinue,
  onCancel,
  isVisible = false,
  isLoading = false,
}: Props) {
  return (
    <AlertDialog open={isVisible}>
      <AlertDialogContent className="bg-white">
        <AlertDialogHeader>
          <AlertDialogTitle>
            {title ?? "Você tem certeza absoluta?"}
          </AlertDialogTitle>

          {description && (
            <AlertDialogDescription>{description}</AlertDialogDescription>
          )}
        </AlertDialogHeader>
        <AlertDialogFooter className="mt-3">
          {!isLoading ? (
            <AlertDialogAction onClick={onCancel} disabled={isLoading}>
              Cancelar
            </AlertDialogAction>
          ) : null}
          <AlertDialogCancel onClick={onContinue} disabled={isLoading}>
            {isLoading ? (
              <>
                <Spinner className="w-3 h-3 mr-2" /> Enviando
              </>
            ) : (
              "Continuar"
            )}
          </AlertDialogCancel>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
