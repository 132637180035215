import axios, { HttpStatusCode } from "axios";
import { StorageService } from "../storage";
import { config } from "@/utils/constants";
import Routes from "@/routes/paths";

const publicUrls = ["/user/login"];

const api = axios.create({
  baseURL: config.apiUrl,
});

api.interceptors.request.use((config) => {
  const token = StorageService.Token.get();

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    const isPublicUrlRequest = publicUrls.some((url) =>
      error?.response?.request?.responseURL?.includes(url)
    );

    if (!isPublicUrlRequest) {
      if (error?.response?.status === HttpStatusCode.Unauthorized) {
        StorageService.clear();

        window.location.href = Routes.base;
      }
    }

    return Promise.reject(error);
  }
);

export default api;
