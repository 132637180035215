import { cn } from "@/utils";
import { DropzoneOptions, useDropzone } from "react-dropzone";

type Props = {
  label?: string;
  children?: any;
  onChangeFile: (files: File[]) => void;
  optionsDropzone?: DropzoneOptions | undefined;
};

export const DropzoneUpload = ({
  label = "Arquivo",
  children,
  onChangeFile,
  optionsDropzone,
}: Props) => {
  function onDrop(acceptedFiles: any[]) {
    onChangeFile(acceptedFiles);
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
    multiple: false,
    autoFocus: false,
    ...optionsDropzone,
  });

  return (
    <div {...getRootProps()}>
      <label htmlFor="file" className="mb-2 block text-sm font-medium">
        {label}
      </label>

      <input
        id="file"
        type="file"
        name="file"
        className="sr-only"
        {...getInputProps()}
      />

      <label
        className={cn(
          "relative flex min-h-[200px] items-center justify-center rounded-md border border-dashed p-12 text-center cursor-pointer",
          isDragActive ? "border-[#333]" : "border-[#e0e0e0]"
        )}
      >
        {children ?? (
          <div>
            <span className="mb-2 block text-lg font-semibold text-[#07074D]">
              Solte os arquivos aqui
            </span>
            <span className="mb-2 block text-sm font-medium text-[#6B7280]">
              ou
            </span>
            <span className="inline-flex rounded border border-[#e0e0e0] py-2 px-7 text-sm font-medium text-[#07074D]">
              Selecionar arquivo
            </span>
          </div>
        )}
      </label>
    </div>
  );
};
