import ButtonDropdown from "@/components/button-dropdown";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import {
  DropdownMenuItem,
  DropdownMenuSeparator,
} from "@/components/ui/dropdown-menu";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { useToast } from "@/components/ui/use-toast";
import { StudentFoodPlanService } from "@/services/api/student-food-plan";
import { GenericStatus, StudentFoodPlan } from "@/utils/entities";
import { getToastErrorContent } from "@/utils/functions/api-error";
import { useQuery } from "@tanstack/react-query";
import {
  DownloadIcon,
  Loader2Icon,
  PlusIcon,
  RefreshCwIcon,
  Trash2Icon,
} from "lucide-react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import FoodPlanCreate from "./food-plan-add";

export default function FoodPlanList() {
  const { id } = useParams();
  const { toast } = useToast();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFormAddOpened, setIsFormAddOpened] = useState(false);

  const { data, refetch, isLoading } = useQuery({
    queryKey: ["student_food_plans"],
    retry: 2,
    retryDelay: 3000,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    queryFn: () => StudentFoodPlanService.getAll(id as string),
  });

  function handleOpen({ file_url }: StudentFoodPlan) {
    const link = document.createElement("a");

    link.href = file_url;

    link.click();
  }

  function onSuccessAttach() {
    refetch();

    setIsFormAddOpened(false);
  }

  async function handleDelete(mealPlan: StudentFoodPlan) {
    setIsSubmitting(true);

    StudentFoodPlanService.delete({
      studentId: mealPlan.studentId,
      mealPlanId: mealPlan.id,
    })
      .then(() => {
        toast({
          variant: "success",
          description: "Plano alimentar deletado com sucesso",
        });

        setIsSubmitting(false);

        refetch();
      })
      .catch((error) => {
        setIsSubmitting(false);

        toast(getToastErrorContent(error));
      });
  }

  async function handleChangeStatus(mealPlan: StudentFoodPlan) {
    setIsSubmitting(true);

    const data = {
      id: mealPlan.id,
      status: mealPlan.isActive ? GenericStatus.INACTIVE : GenericStatus.ACTIVE,
      studentId: mealPlan.studentId,
    };

    StudentFoodPlanService.update(data)
      .then(() => {
        toast({
          variant: "success",
          description: "Plano alimentar atualizado com sucesso",
        });

        setIsSubmitting(false);

        refetch();
      })
      .catch((error) => {
        setIsSubmitting(false);

        toast(getToastErrorContent(error));
      });
  }

  return (
    <div className="flex-1">
      <Card className="bg-gray-800">
        <CardHeader>
          <CardTitle className="flex text-gray-200 items-center justify-between">
            Plano alimentar
            {isSubmitting || isLoading ? (
              <Loader2Icon className="mr-2 h-4 w-4 animate-spin" />
            ) : (
              <Button
                size="default"
                variant="outline"
                className="bg-transparent"
                onClick={() => setIsFormAddOpened(true)}
              >
                <PlusIcon size={18} className="mr-2" />
                Adicionar
              </Button>
            )}
          </CardTitle>
        </CardHeader>
        <CardContent className="grid gap-10 w-full overflow-auto">
          <Table>
            <TableCaption>Lista de planos alimentares.</TableCaption>
            <TableHeader>
              <TableRow>
                <TableHead className="text-gray-400 sm:min-w-[200px]">
                  Título
                </TableHead>
                <TableHead className="text-gray-400 min-w-[150px]">
                  Data de cadastro
                </TableHead>
                <TableHead className="text-gray-400">Status</TableHead>
                <TableHead className="w-[100px] text-right" />
              </TableRow>
            </TableHeader>
            <TableBody className="text-gray-200">
              {data?.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.title}</TableCell>
                  <TableCell>{item.createdAtText}</TableCell>
                  <TableCell>{item.statusText}</TableCell>
                  <TableCell className="text-right pr-0">
                    <ButtonDropdown>
                      <DropdownMenuItem
                        className="cursor-pointer"
                        onClick={() => handleOpen(item)}
                      >
                        <DownloadIcon size={14} className="mr-2" /> Baixar
                        arquivo
                      </DropdownMenuItem>

                      <DropdownMenuSeparator />

                      <DropdownMenuItem
                        className="cursor-pointer"
                        onClick={() => handleDelete(item)}
                      >
                        <Trash2Icon size={14} className="mr-2" /> Apagar
                      </DropdownMenuItem>

                      <DropdownMenuItem
                        className="cursor-pointer"
                        onClick={() => handleChangeStatus(item)}
                      >
                        <RefreshCwIcon size={14} className="mr-2" />{" "}
                        {item.isActive ? "Inativar" : "Ativar"}
                      </DropdownMenuItem>
                    </ButtonDropdown>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>

      <FoodPlanCreate
        isOpened={isFormAddOpened}
        onVisibleChange={setIsFormAddOpened}
        onSuccessAttach={onSuccessAttach}
      />
    </div>
  );
}
