import { Card, CardContent, CardFooter, CardTitle } from "@/components/ui/card";
import { Theme } from "@/utils/entities";
import AndroidDownload from "../../assets/android-download.png";
import IosDownload from "../../assets/ios-download.png";

type Props = {
  theme?: Theme;
  imageUrl?: string;
  professionalName: string;
  studentName: string;
  studentEmail: string;
};

const SubscribeSuccess: React.FC<Props> = ({
  theme,
  professionalName,
  imageUrl,
}) => {
  return (
    <Card className="mx-4">
      <CardTitle className="flex justify-center items-center  h-28">
        <div className="ml-[-16px] mr-4 h-20 w-20 flex items-center justify-center rounded-full bg-gray-200">
          {imageUrl && <img src={imageUrl} className="h-full" />}
        </div>
        <div>{professionalName}</div>
      </CardTitle>
      <CardContent className="p-10  border-solid border-t-[1px] border-b-[1px]">
        {theme?.welcome_message}
      </CardContent>
      <CardFooter className="flex justify-center gap-10 mt-6">
        <a
          href="https://play.google.com/store/apps/details?id=com.gymlab"
          target="_blank"
        >
          <img src={AndroidDownload} className="h-12" />
        </a>
        <a
          href="https://apps.apple.com/br/app/gymlab/id6478815250"
          target="_blank"
        >
          <img src={IosDownload} className="h-12" />
        </a>
      </CardFooter>
    </Card>
  );
};

export default SubscribeSuccess;
