import { StudentGroupService } from "@/services/api/student-group";
import { useQuery } from "@tanstack/react-query";

export default function UseGroupStudents() {
  const { data, isLoading, ...rest } = useQuery({
    retry: 3,
    queryKey: ["student_groups"],
    refetchOnWindowFocus: false,
    queryFn: () => StudentGroupService.getAll()
  });

  return { queryClient: rest, studentGroups: data, isLoadingStudentGroups: isLoading }
}