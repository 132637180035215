import ModalConfirmSignOut from "@/components/modal-confirm";
import { Button } from "@/components/ui/button";
import UserProfileImage from "@/components/user-profile-image";
import Routes from "@/routes/paths";
import { UserType } from "@/utils/entities";
import { useUserProvider } from "@/utils/providers/user-provider";
import {
  DumbbellIcon,
  HomeIcon,
  LogOutIcon,
  MenuIcon,
  Presentation,
  Settings,
  UsersIcon,
  ClipboardEditIcon,
  X,
} from "lucide-react";
import { useState } from "react";
import { Link } from "react-router-dom";

const classNameIcon =
  "w-5 h-5 text-gray-400 transition duration-75 text-gray-200 group-hover:text-gray-400 group-hover:text-white";

export default function Sidebar() {
  const { user, onSignOut } = useUserProvider();
  const [isOpen, setIsOpen] = useState(false);
  const [isVisibleModalSignout, setIsVisibleModalSignout] = useState(false);

  const handleLogout = () => async () => {
    setIsVisibleModalSignout(true);
  };

  const onSubmitLogout = () => async () => {
    onSignOut();
  };

  const menuItems = {
    [UserType.ADMIN]: [
      {
        title: "Home",
        to: Routes.home,
        icon: <HomeIcon className={classNameIcon} />,
      },
      {
        title: "Profissionais",
        to: Routes.professional.list,
        icon: <UsersIcon className={classNameIcon} />,
      },
      {
        title: "Tipos de Exercícios",
        to: Routes.exerciseType.list,
        icon: <DumbbellIcon className={classNameIcon} />,
      },
      {
        title: "Exercícios",
        to: Routes.exercise.list,
        icon: <DumbbellIcon className={classNameIcon} />,
      },
    ],
    [UserType.PROFESSIONAL]: [
      {
        title: "Home",
        to: Routes.home,
        icon: <HomeIcon className={classNameIcon} />,
      },
      {
        title: "Alunos",
        to: Routes.student.list,
        icon: <UsersIcon className={classNameIcon} />,
      },
      {
        title: "Exercícios",
        to: Routes.exercise.list,
        icon: <DumbbellIcon className={classNameIcon} />,
      },
      {
        title: "Templates",
        to: Routes.template.list,
        icon: <ClipboardEditIcon className={classNameIcon} />,
      },
      {
        title: "Área de membros",
        to: Routes.members,
        icon: <Presentation className={classNameIcon} />,
      },
      {
        title: "Configurações",
        to: Routes.config,
        icon: <Settings className={classNameIcon} />,
      },
    ],
    [UserType.STUDENT]: [
      {
        title: "Home",
        to: Routes.home,
        icon: <HomeIcon className={classNameIcon} />,
      },
    ],
  };

  return (
    <>
      <nav className="lg:hidden flex justify-end py-2 px-4 fixed top-0 left-0 right-0 bg-gray-800 border-b-[1px] border-white z-30">
        <h1 className="mr-auto my-auto text-white font-bold text-xl">GymLab</h1>
        <Button
          className=""
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          {isOpen ? <X /> : <MenuIcon color="white" className="" />}
        </Button>
      </nav>

      <aside
        id="logo-sidebar"
        className={`${
          isOpen ? "left-0" : "left-full"
        } fixed top-[50px] lg:top-0 w-full z-40 h-screen pt-5 transition-all  border-r bg-gray-800 border-gray-700 lg:left-0 lg:w-[220px]`}
        aria-label="Sidebar"
      >
        <div className="h-full px-3 pb-4 overflow-y-auto bg-gray-800">
          <UserProfileImage />

          <div className="border-t border-gray-700 mt-8 mb-6"></div>

          <ul className="space-y-2 font-normal">
            {menuItems[user?.type ?? UserType.PROFESSIONAL].map((menu) => (
              <li key={menu.title}>
                <Link
                  to={menu.to}
                  className="flex items-center p-2 transition duration-75 rounded-lg hover:bg-gray-700 text-white group cursor-pointer"
                >
                  {menu.icon}

                  <span className="flex-1 ml-3 whitespace-nowrap">
                    {menu.title}
                  </span>
                </Link>
              </li>
            ))}
          </ul>

          <ul className="pt-4 mt-4 space-y-2 font-normal border-t border-gray-700">
            <li
              className="flex items-center p-2 transition duration-75 rounded-lg hover:bg-gray-700 text-white group cursor-pointer"
              onClick={handleLogout()}
            >
              <LogOutIcon className="flex-shrink-0 w-5 h-5 transition duration-75 text-gray-200 group-hover:text-white" />
              <span className="ml-3">Sair</span>
            </li>
          </ul>
        </div>
      </aside>

      <ModalConfirmSignOut
        title={"Tem certeza que deseja sair ?"}
        isVisible={isVisibleModalSignout}
        onCancel={() => setIsVisibleModalSignout(false)}
        onContinue={onSubmitLogout()}
      />
    </>
  );
}
