import { Professional, Theme } from "@/utils/entities";
import { getDateFormatText } from "@/utils/functions/date-transform";
import api from "./config";
import { parseUser } from "./user";

type CreateProfessionalInput = Omit<
  Professional,
  "id" | "status" | "statusText" | "image_url"
>;

type createOrUpdateData = Omit<
  Professional,
  "status" | "statusText" | "image_url"
>;

type UpdateProfileFileInput = {
  image_url: string;
};

type UpdateThemeInput = {
  primary_color?: string;
  logo_image_url?: string;
};

type UpdateSettingsInput = {
  terms_of_use?: string | undefined;
  primary_color?: string;
  logo_image_url?: string;
  welcome_message?: string | undefined;
  benefits_message?: string | undefined;
};

export async function listProfessional() {
  const { professionals } = (await api.get("/professionals")).data;

  const data = (professionals as Professional[]).map((item) => ({
    ...item,
    user: parseUser(item.user),
    birth_date_text: getDateFormatText(item.birth_date),
  }));

  return data as Professional[];
}

export async function getOneProfessional(id: string) {
  const { professional } = (await api.get(`/professional/${id}`)).data;

  return professional as Professional;
}

export async function getProfessionalForm(id: string) {
  const data = (
    await api.get<{ name: string; theme: Theme }>(`/professional/${id}/form`)
  ).data;

  return data;
}

async function createProfessional(data: CreateProfessionalInput) {
  const { professional } = (await api.post("/professional", data)).data;

  return professional as Professional;
}

async function updateProfessional(data: createOrUpdateData) {
  const { id } = data;

  const { professional } = (await api.put(`/professional/${id}`, data)).data;

  return professional as Professional;
}

export async function createOrUpdateProfessional(data: createOrUpdateData) {
  if (data?.id) {
    return await updateProfessional(data);
  }

  return await createProfessional(data);
}

export async function updateImageProfessional({
  image_url,
}: UpdateProfileFileInput) {
  const response = await api.post(`/user/image-profile`, { image_url });

  return response?.data;
}

export async function updateThemeProfessional(data: UpdateThemeInput) {
  const response = await api.post("/professional/update-theme", data);

  return response.data;
}

export async function updateProfessionalSettings(data: UpdateSettingsInput) {
  const response = await api.post("/professional/update-theme", data);

  return response?.data;
}

export const ProfessionalService = {
  getAll: listProfessional,
  getOne: getOneProfessional,
  getForm: getProfessionalForm,
  updateImage: updateImageProfessional,
  createOrUpdate: createOrUpdateProfessional,
  updateThemeProfessional,
  updateSettings: updateProfessionalSettings
};
