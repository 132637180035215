import FormPublicWrapper from "@/components/form-public-wrapper";
import { InputIcon } from "@/components/input-icon";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useToast } from "@/components/ui/use-toast";
import Routes from "@/routes/paths";
import { UserService } from "@/services/api/user";
import { getToastErrorContent } from "@/utils/functions/api-error";
import { zodResolver } from "@hookform/resolvers/zod";
import { EyeIcon, EyeOffIcon, Loader2 } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import * as z from "zod";

const FormSchema = z
  .object({
    password: z.string().min(1, { message: "Campo obrigatório" }),
    confirmPassword: z.string().min(1, { message: "Campo obrigatório" }),
  })
  .refine((data) => data.password === data.confirmPassword, {
    path: ["confirmPassword"],
    message: "As senhas não correspondem",
  });

type FormSchemaType = z.infer<typeof FormSchema>;

type ScreenProps = {
  token?: string;
};

export default function UpdatePasswordScreen({ token }: ScreenProps) {
  const navigate = useNavigate();
  const { toast } = useToast();
  const [passwordType, setPasswordType] = useState("password");
  const [passwordConfirmType, setPasswordConfirmType] = useState("password");

  const form = useForm<FormSchemaType>({
    resolver: zodResolver(FormSchema),
    defaultValues: { password: "", confirmPassword: "" },
  });

  async function onSubmit(data: FormSchemaType) {
    try {
      if (!token) {
        return;
      }

      const { password } = FormSchema.parse(data);

      await UserService.updatePassword({ token, password });

      toast({
        variant: "success",
        description: "Sua senha foi atualizada com sucesso.",
      });

      navigate(Routes.base, { replace: true });
    } catch (error) {
      toast(getToastErrorContent(error));
    }
  }

  const { isSubmitting } = form.formState;

  const InputPasswordIcon = passwordType === "password" ? EyeOffIcon : EyeIcon;

  const InputPasswordConfirmIcon =
    passwordConfirmType === "password" ? EyeOffIcon : EyeIcon;

  return (
    <FormPublicWrapper>
      <Form {...form}>
        <form
          className="m-auto sm:w-2/3 md:w-2/3"
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <Card>
            <CardHeader className="space-y-1">
              <CardTitle className="text-2xl">GymAPP</CardTitle>

              <CardDescription>Atualize sua senha</CardDescription>
            </CardHeader>

            <CardContent className="grid gap-4">
              <FormField
                control={form.control}
                name="password"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Senha</FormLabel>

                    <FormControl>
                      <InputIcon
                        icon={
                          <InputPasswordIcon
                            size={20}
                            className="text-zinc-700"
                          />
                        }
                        onClick={() =>
                          setPasswordType((prev) =>
                            prev === "password" ? "text" : "password"
                          )
                        }
                      >
                        <Input
                          {...field}
                          type={passwordType}
                          className="pr-10"
                        />
                      </InputIcon>
                    </FormControl>

                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="confirmPassword"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Confirmação de senha</FormLabel>

                    <FormControl>
                      <InputIcon
                        icon={
                          <InputPasswordConfirmIcon
                            size={20}
                            className="text-zinc-700"
                          />
                        }
                        onClick={() =>
                          setPasswordConfirmType((prev) =>
                            prev === "password" ? "text" : "password"
                          )
                        }
                      >
                        <Input
                          {...field}
                          type={passwordConfirmType}
                          className="pr-10"
                        />
                      </InputIcon>
                    </FormControl>

                    <FormMessage />
                  </FormItem>
                )}
              />
            </CardContent>

            <CardFooter>
              <Button type="submit" className="w-full" disabled={isSubmitting}>
                {isSubmitting && (
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                )}
                Atualizar
              </Button>
            </CardFooter>

            <p className="text-sm text-center text-gray-700 mb-3">
              Já possui uma conta?
              <Link
                to={Routes.base}
                className="ml-1 text-blue-600 hover:underline"
              >
                Acessar
              </Link>
            </p>
          </Card>
        </form>
      </Form>
    </FormPublicWrapper>
  );
}
