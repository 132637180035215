import { User } from "@/utils/entities";

const StorageUser = {
  set: (data: any) => {
    localStorage.setItem('@gympass:user', JSON.stringify(data))
  },
  get: (): User | null => {
    const data = localStorage.getItem('@gympass:user')

    return data ? JSON.parse(data) : null
  },
}

export default StorageUser;