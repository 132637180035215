import { ExerciseVideoService } from "@/services/api/exercise-video";
import imageCompression from "browser-image-compression";

export default function useFileBucket() {
  const getSignedUrl = async (
    file: File | null,
    folder: string,
    toCompress = false
  ) => {
    if (!file) {
      throw new Error("File not found");
    }

    try {
      const responseUrl = await ExerciseVideoService.generateVideoBucketUrl({
        file,
        folder,
      });

      if (toCompress) {
        const newFile = await imageCompression(file, { maxSizeMB: 0.5 });

        await ExerciseVideoService.uploadVideoToBucket({
          ...responseUrl,
          file: newFile,
        });
      } else {
        await ExerciseVideoService.uploadVideoToBucket({
          ...responseUrl,
          file,
        });
      }

      return responseUrl.bucketVideoUrl;
    } catch (error) {
      console.log(error);

      throw new Error("Do not generate signed url");
    }
  };

  return { getSignedUrl };
}
