import { Exercise, ExerciseExecution } from "@/utils/entities";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
} from "@/components/ui/sheet";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { useExercisesInfinityList } from "@/utils/hooks/use-exercises";
import Spinner from "@/components/ui/spinner";
import { Card } from "@/components/ui/card";
import SheetExerciseItem from "./sheet-exercise-item";
import { ArrowDownIcon, Trash2Icon } from "lucide-react";
import { useExercisesTypesList } from "@/utils/hooks/use-exercise-types";
import { Separator } from "@/components/ui/separator";
import { useParams, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import { cn } from "@/utils";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import Search from "@/features/exercise/components/search";
import { useToast } from "@/components/ui/use-toast";
import { SheetService } from "@/services/api/sheet";
import { getToastErrorContent } from "@/utils/functions/api-error";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";

type SheetDialogAddExerciseProps = {
  isOpened: boolean;
  trainingId?: string | undefined;
  onCancel?: () => void;
  onRemove?: () => void;
  onChange?: (item: Exercise) => void;
};

type SheetExerciseListProps = {
  selectedId?: string;
  className?: string | undefined;
  onSelect?: (item: Exercise) => void;
} & SheetDialogAddExerciseProps;

type FormSchemaType = ExerciseExecution;

function SheetExerciseList({
  isOpened,
  onSelect,
  onRemove,
  selectedId,
  className,
}: SheetExerciseListProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  const type = searchParams.get("type") || undefined;
  const name = searchParams.get("name") || undefined;
  const category = searchParams.get("category") || "all";

  const { data, isFetching, hasNextPage, fetchNextPage, isFetchingNextPage } =
    useExercisesInfinityList({
      type,
      name,
      take: 10,
      category,
      enabled: isOpened,
    });

  const { data: exerciseTypes } = useExercisesTypesList({ enabled: isOpened });

  const exercises = data?.pages.flatMap((page) => page.exercises);

  function handleChangeType(type?: string) {
    if (type?.trim()) {
      searchParams.set("type", type);
    } else {
      searchParams.delete("type");
    }

    setSearchParams(searchParams, { replace: true });
  }

  function handleChangeCategory(category?: string) {
    if (!category || category === "all") {
      searchParams.delete("category");
    } else {
      searchParams.set("category", category);
    }

    setSearchParams(searchParams, { replace: true });
  }

  function handleClearSearch() {
    if (searchParams.has("name")) {
      searchParams.delete("name");

      setSearchParams(searchParams, { replace: true });
    }
  }

  function handleSearch(value: string) {
    if (value) {
      searchParams.set("name", value);
    } else {
      searchParams.delete("name");
    }

    setSearchParams(searchParams, { replace: true });
  }

  function handleSelect(item: Exercise) {
    onSelect?.(item);
  }

  function handleCancel() {
    onRemove?.();
  }

  return (
    <div className={cn("flex flex-1 flex-col", className)}>
      <div className="flex justify-between  items-center">
        <div className="flex gap-3">
          <Select onValueChange={handleChangeType}>
            <SelectTrigger className="w-[180px] ">
              <SelectValue placeholder="Filtro de exercícios" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value={" "}>Todos</SelectItem>
              {exerciseTypes?.map((item, index) => (
                <SelectItem key={index} value={item.id}>
                  {item.name}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        {isFetching ? <Spinner className="ml-5 mr-2" /> : null}

        <Tabs
          activationMode="automatic"
          value="tab-exercises"
          className="h-full space-y-8"
          defaultValue="all"
          onValueChange={handleChangeCategory}
        >
          <div className="space-between flex items-center">
            <TabsList>
              <TabsTrigger
                value="all"
                className={cn(
                  category === "all" ? "bg-zinc-200 text-zinc-900" : ""
                )}
              >
                Todos os exercícios
              </TabsTrigger>

              <TabsTrigger
                value="my"
                className={cn(
                  category === "my" ? "bg-zinc-200 text-zinc-900" : ""
                )}
              >
                Meus exercícios
              </TabsTrigger>

              <TabsTrigger
                value="platform"
                className={cn(
                  category === "platform" ? "bg-zinc-200 text-zinc-900" : ""
                )}
              >
                Exercícios da plataforma
              </TabsTrigger>
            </TabsList>
          </div>
        </Tabs>
      </div>

      <div className="pr-0">
        <Search
          className="text-zinc-900"
          defaultValue={name}
          onClear={() => handleClearSearch()}
          onSearch={(value) => handleSearch(value)}
        />
      </div>

      <div className="overflow-y-auto max-h-[55vh]">
        {isFetching && !exercises?.length ? (
          <div className="flex flex-1 min-h-96 items-center justify-center mx-auto">
            <Spinner className="mr-2" />
            <span className="text-sm">Carregando</span>
          </div>
        ) : null}

        {!isFetching && !exercises?.length ? (
          <div className="flex flex-col flex-1 min-h-96 items-center justify-center mx-auto gap-2">
            <span className="text-zinc-700">
              Não encontramos resultados para a sua busca
            </span>
            <span>😞</span>
          </div>
        ) : null}

        <div className="grid grid-cols-[repeat(auto-fill,280px)] gap-y-4 pb-4 justify-between">
          {exercises?.map((item, index) => (
            <Card key={index} className="w-[280px]">
              <SheetExerciseItem exercise={item}>
                <div className="p-2 w-full">
                  <Button
                    size="xs"
                    onClick={() =>
                      selectedId === item.id
                        ? handleCancel()
                        : handleSelect(item)
                    }
                    variant="ghost"
                    className="w-full text-xs"
                  >
                    {selectedId === item.id ? "Cancelar" : "Selecionar"}
                  </Button>
                </div>
              </SheetExerciseItem>
            </Card>
          ))}
        </div>
      </div>

      {hasNextPage ? (
        <div className="grid justify-center py-3">
          <Button
            variant="outline"
            onClick={() => fetchNextPage()}
            disabled={isFetchingNextPage}
          >
            {isFetchingNextPage ? (
              <Spinner className="h-4 w-4 mr-2" />
            ) : (
              <ArrowDownIcon className="h-4 w-4 mr-2" />
            )}
            Ver mais
          </Button>
        </div>
      ) : null}
    </div>
  );
}

function SheetExerciseForm({ name, handleCancel }: any) {
  const { register } = useFormContext();

  return (
    <div className="flex flex-col col-span-1">
      <div className="flex flex-row items-center justify-between py-3 px-4">
        <span className="text-md font-semibold">{name}</span>

        <Button
          size="icon"
          variant="ghost"
          className="h-[32px] w-[32px]"
          onClick={handleCancel}
        >
          <Trash2Icon size={15} />
        </Button>
      </div>

      <Separator />

      <div className="flex flex-col p-4 gap-5">
        <div className="flex flex-col gap-2">
          <Label htmlFor="sets">Séries</Label>
          <Input id="sets" {...register("sets")} />
        </div>
        <div className="flex flex-col gap-2">
          <Label htmlFor="repetitions">Repetições</Label>
          <Input id="repetitions" {...register("repetitions")} />
        </div>
        <div className="flex flex-col gap-2">
          <Label htmlFor="load">Carga</Label>
          <Input id="load" {...register("load")} />
        </div>
        <div className="flex flex-col gap-2">
          <Label htmlFor="cadence">Cadência</Label>
          <Input id="cadence" {...register("cadence")} />
        </div>
        <div className="flex flex-col gap-2">
          <Label htmlFor="interval">Intervalo</Label>
          <Input id="interval" {...register("interval")} />
        </div>
        <div className="flex flex-col gap-2">
          <Label htmlFor="observation">Observação</Label>
          <Textarea id="observation" {...register("observation")} />
        </div>
      </div>
    </div>
  );
}

export default function SheetDialogAddExercise({
  isOpened,
  onCancel,
  onChange,
  trainingId,
}: SheetDialogAddExerciseProps) {
  const methods = useForm<FormSchemaType>();
  const { toast } = useToast();
  const { sheetId } = useParams();
  const [exercise, setExercise] = useState<Exercise | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();

  function clearSerachParams() {
    searchParams.delete("name");
    searchParams.delete("type");
    searchParams.delete("category");

    setSearchParams(searchParams, { replace: true });
  }

  const handleCancel = () => {
    setExercise(null);

    methods.reset();

    onCancel?.();

    clearSerachParams();
  };

  const handleCancelEdit = () => {
    setExercise(null);

    methods.reset();
  };

  const onSubmit = async (formData: FormSchemaType) => {
    try {
      const execution = { ...formData, exerciseId: exercise?.id, trainingId };

      if (!exercise || !sheetId) {
        onChange?.({ ...exercise, execution } as Exercise);

        setExercise(null);

        methods.reset();

        return;
      }

      await SheetService.addExercise(sheetId, execution);

      setExercise(null);

      methods.reset();

      toast({
        variant: "success",
        description: "O exercício adicionado ao treino com sucesso",
      });

      clearSerachParams();

      onChange?.({ ...exercise, execution });
    } catch (error) {
      console.log(error);

      toast(getToastErrorContent(error));
    }
  };

  const hasExercise = Boolean(exercise);
  const { isSubmitting } = methods.formState;

  return (
    <Sheet open={isOpened}>
      <SheetContent
        side="bottom"
        className="bg-white min-h-[80%] flex flex-col gap-0 p-0"
        onClose={handleCancel}
      >
        <SheetHeader className="p-4">
          <SheetTitle>Adicionar exercício</SheetTitle>
          <SheetDescription>
            Selecione um exercício e insira as informações complementares
          </SheetDescription>
        </SheetHeader>

        <Separator />

        <FormProvider {...methods}>
          <div className="grid flex-1 h-full grid-cols-3">
            <div
              className={cn(
                "grid col-span-3",
                hasExercise && "col-span-2 border-r"
              )}
            >
              <SheetExerciseList
                className="pt-4 px-5"
                {...{ isOpened, onCancel }}
                onRemove={handleCancelEdit}
                onSelect={(item) => setExercise(item)}
                selectedId={exercise?.id}
              />
            </div>

            {exercise ? (
              <SheetExerciseForm
                name={exercise.name}
                handleCancel={handleCancelEdit}
              />
            ) : null}
          </div>
        </FormProvider>

        <SheetFooter className="border-t py-5 px-4">
          <SheetClose asChild>
            <Button
              variant="outline"
              onClick={handleCancel}
              disabled={isSubmitting}
            >
              Cancelar
            </Button>
          </SheetClose>
          <SheetClose asChild>
            <Button
              disabled={!exercise || isSubmitting}
              onClick={methods.handleSubmit(onSubmit)}
            >
              {isSubmitting ? (
                <Spinner className="h-[14px] w-[14px] mr-2" />
              ) : null}
              Salvar
            </Button>
          </SheetClose>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  );
}
