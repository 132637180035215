import { Button } from "@/components/ui/button";
import { Card, CardContent, CardFooter, CardTitle } from "@/components/ui/card";
import { Theme } from "@/utils/entities";

type Props = {
  theme?: Theme;
  professionalName: string;
  imageUrl: string;
  onContinue: () => void;
};

const About: React.FC<Props> = ({
  imageUrl,
  theme,
  professionalName,
  onContinue,
}) => {
  return (
    <Card className="mx-4">
      <CardTitle className="flex justify-center items-center  h-28">
        <div className="ml-[-16px] mr-4 h-20 w-20 flex items-center justify-center rounded-full bg-gray-200">
          <img src={imageUrl} className="h-full" />
        </div>
        <div>{professionalName}</div>
      </CardTitle>
      <CardContent className="p-10  border-solid border-t-[1px] border-b-[1px]">
        {theme?.benefits_message}
      </CardContent>
      <CardFooter className="flex justify-center">
        <Button className="mt-4" onClick={onContinue}>
          Iniciar inscrição
        </Button>
      </CardFooter>
    </Card>
  );
};

export default About;
