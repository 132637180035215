import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Switch } from "@/components/ui/switch";
import { useToast } from "@/components/ui/use-toast";
import { ExerciseTypeService } from "@/services/api/exercise-type";
import { ExerciseType, GenericStatus } from "@/utils/entities";
import { getToastErrorContent } from "@/utils/functions/api-error";
import { zodResolver } from "@hookform/resolvers/zod";
import { Loader2Icon } from "lucide-react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import * as z from "zod";

type FormProps = {
  exercise?: ExerciseType | undefined;
  onCancel?: () => void;
  onSuccessful?: () => void;
};

const FormSchema = z.object({
  id: z
    .string()
    .optional()
    .transform((e) => (e === "" ? undefined : e)),
  name: z.string().min(1, { message: "Campo obrigatório" }),
  status: z.boolean().default(true).optional(),
});

type FormSchemaType = z.infer<typeof FormSchema>;

const formTexts = {
  create: {
    formHeaderTitle: "Cadastro",
    feedbackSuccess: "Tipo de exercício cadastrado com sucesso.",
  },
  update: {
    formHeaderTitle: "Edição",
    feedbackSuccess: "Tipo de exercício atualizado com sucesso.",
  },
};

const defaultValues = { id: "", name: "", status: true };

export default function ExerciseTypeForm({
  exercise,
  onCancel,
  onSuccessful,
}: FormProps) {
  const { toast } = useToast();

  const type = exercise ? "update" : "create";

  const form = useForm<FormSchemaType>({
    resolver: zodResolver(FormSchema),
    defaultValues,
  });

  useEffect(() => {
    if (exercise) {
      form.reset({
        id: exercise?.id ?? "",
        name: exercise?.name ?? "",
        status: exercise?.isActive,
      });
    }
  }, [exercise]);

  function handleReset() {
    form.reset(defaultValues);

    onCancel?.();
  }

  const onSubmit = async (data: FormSchemaType) => {
    try {
      const formData = FormSchema.parse(data);

      const status = formData.status ? "ACTIVE" : "INACTIVE";

      await ExerciseTypeService.createOrUpdate({ ...formData, status });

      toast({
        variant: "success",
        description: formTexts[type].feedbackSuccess,
      });

      form.reset(defaultValues);

      handleReset?.();

      onSuccessful?.();
    } catch (error) {
      console.log(error);

      toast(getToastErrorContent(error));
    }
  };

  const { isSubmitting } = form.formState;

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Card className="bg-gray-800">
          <CardHeader>
            <CardTitle className="text-gray-200 items-start justify-start">
              {formTexts[type].formHeaderTitle}
            </CardTitle>
          </CardHeader>
          <CardContent className="grid gap-10">
            <div className="grid grid-cols-5 gap-4 items-center">
              <div className="grid col-span-4">
                <FormField
                  name="name"
                  control={form.control}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-gray-300">
                        Nome do tipo de exercício
                      </FormLabel>

                      <FormControl>
                        <Input
                          className="bg-gray-800 text-gray-300"
                          placeholder="Ex.: Tríceps"
                          {...field}
                        />
                      </FormControl>

                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="flex justify-end">
                <FormField
                  control={form.control}
                  name="status"
                  render={({ field }) => (
                    <FormItem className="flex flex-col">
                      <FormLabel className="text-gray-300 mb-2">
                        Status
                      </FormLabel>
                      <FormControl>
                        <Switch
                          checked={field.value}
                          onCheckedChange={field.onChange}
                          className="data-[state=checked]:bg-input data-[state=unchecked]:bg-zinc-600"
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />
              </div>
            </div>
          </CardContent>
          <CardFooter className="justify-end space-x-2">
            <Button
              type="button"
              variant="ghost"
              disabled={isSubmitting}
              className="text-gray-300"
              onClick={handleReset}
            >
              Cancelar
            </Button>
            <Button disabled={isSubmitting} className="px-8 hover:bg-gray-900">
              {isSubmitting && (
                <Loader2Icon className="mr-2 h-4 w-4 animate-spin" />
              )}
              Salvar
            </Button>
          </CardFooter>
        </Card>
      </form>
    </Form>
  );
}
