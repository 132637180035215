import ButtonDropdown from "@/components/button-dropdown";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { DropdownMenuItem } from "@/components/ui/dropdown-menu";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { useToast } from "@/components/ui/use-toast";
import Routes from "@/routes/paths";
import { SheetService } from "@/services/api/sheet";
import { GenericStatus, Sheet } from "@/utils/entities";
import { getToastErrorContent } from "@/utils/functions/api-error";
import { useQuery } from "@tanstack/react-query";
import {
  EditIcon,
  Loader2Icon,
  PlaySquareIcon,
  RefreshCwIcon,
  TrashIcon,
  WrenchIcon,
} from "lucide-react";
import * as React from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import ModalFormSheet from "../modal/modal-create-sheet";
import ModalCopyTemplateSheet from "../modal/modal-import-sheet";
import ModalRemoveSheet from "../modal/modal-remove-sheet";

export default function SheetList() {
  const { id } = useParams();
  const params = useParams();
  const navigate = useNavigate();
  const { toast } = useToast();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [sheetToDelete, setSheetToDelete] = React.useState<Sheet>();
  const [sheetToUpdate, setSheetToUpdate] = React.useState<Sheet>();

  const { data, refetch, isLoading } = useQuery({
    queryKey: ["student_sheet_list", id],
    retry: 2,
    retryDelay: 3000,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    queryFn: () => SheetService.getAllOfStudent(id as string),
  });

  function handleSheetEdit(sheet: Sheet) {
    const params = { id, sheetId: sheet.id };

    const options = { state: { sheet } };

    navigate(generatePath(Routes.student.createSheet, params), options);
  }

  function handleSheetUpdateStatus(sheet: Sheet) {
    setIsSubmitting(true);

    const data = {
      id: sheet.id,
      status: sheet.isActive ? GenericStatus.INACTIVE : GenericStatus.ACTIVE,
      studentId: sheet.studentId,
    };

    SheetService.update(data)
      .then(() => {
        toast({
          variant: "success",
          description: "Prescrição de treino do aluno atualizada com sucesso",
        });

        setIsSubmitting(false);

        refetch();
      })
      .catch((error) => {
        setIsSubmitting(false);

        toast(getToastErrorContent(error));
      });
  }

  const handleSaveNewSheet = async ({ name, expire_date }: Partial<Sheet>) => {
    if (sheetToUpdate) {
      await SheetService.update({
        id: sheetToUpdate.id,
        name,
        expire_date,
      });
      refetch();
    } else {
      const sheet = await SheetService.create({
        name,
        expire_date,
        studentId: params.id,
      });
      handleSheetEdit(sheet);
    }
  };

  const onCopyTemplateSheet = async (templateId: string): Promise<void> => {
    if (!id) {
      return;
    }

    try {
      const sheet = await SheetService.copyTemplateSheet(templateId, id);

      handleSheetEdit(sheet);

      toast({
        variant: "success",
        description: "Ficha criada com sucesso!",
      });
    } catch (error) {
      toast(getToastErrorContent(error));
    }
  };

  const isVisibleModalRemoveSheet = !!id && !!sheetToDelete;

  return (
    <Card className="bg-gray-800 mt-10">
      <CardHeader>
        <CardTitle className="flex text-gray-200 items-center justify-between flex-col-reverse md:flex-row">
          Fichas do aluno
          {isSubmitting || isLoading ? (
            <Loader2Icon className="mr-2 h-4 w-4 animate-spin" />
          ) : (
            <div className="flex gap-4 mb-4 md:mb-0">
              <ModalCopyTemplateSheet onCopyTemplate={onCopyTemplateSheet} />
              <ModalFormSheet
                onClose={() => setSheetToUpdate(undefined)}
                onSave={handleSaveNewSheet}
                sheet={sheetToUpdate}
              />
            </div>
          )}
        </CardTitle>
      </CardHeader>
      <CardContent className="grid gap-10 w-full overflow-auto">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="text-gray-400">Status</TableHead>
              <TableHead className="text-gray-400 min-w-[280px]">
                Nome
              </TableHead>
              <TableHead className="text-gray-400 min-w-[150px]">
                Data de expiração
              </TableHead>
              <TableHead className="text-gray-400 min-w-[150px]">
                Data de cadastro
              </TableHead>
              <TableHead className="w-[100px]" />
            </TableRow>
          </TableHeader>

          <TableBody className="text-gray-200">
            {data?.map((item) => (
              <TableRow key={item.id}>
                <TableCell>{item.statusText}</TableCell>
                <TableCell>{item.name}</TableCell>
                <TableCell>{item.expireDateText}</TableCell>
                <TableCell>{item.createdAtText}</TableCell>
                <TableCell className="pr-0">
                  <ButtonDropdown>
                    <DropdownMenuItem
                      className="cursor-pointer"
                      onClick={() => handleSheetEdit(item)}
                    >
                      <PlaySquareIcon size={14} className="mr-2" /> Treinos
                    </DropdownMenuItem>

                    <DropdownMenuItem
                      className="cursor-pointer"
                      onClick={() => setSheetToUpdate(item)}
                    >
                      <WrenchIcon size={14} className="mr-2" /> Ficha
                    </DropdownMenuItem>

                    <DropdownMenuItem
                      className="cursor-pointer"
                      onClick={() => handleSheetUpdateStatus(item)}
                    >
                      <RefreshCwIcon size={14} className="mr-2" />
                      {item.isActive ? "Inativar" : "Ativar"}
                    </DropdownMenuItem>

                    {item?.status === "INACTIVE" ? (
                      <DropdownMenuItem
                        className="cursor-pointer"
                        onClick={() => setSheetToDelete(item)}
                      >
                        <TrashIcon size={14} className="mr-2" /> Excluir
                      </DropdownMenuItem>
                    ) : null}
                  </ButtonDropdown>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>

      <ModalRemoveSheet
        sheetId={sheetToDelete?.id}
        studentId={id}
        isVisible={isVisibleModalRemoveSheet}
        handleCancel={() => setSheetToDelete(undefined)}
        handleSuccess={() => {
          refetch();
          setSheetToDelete(undefined);
        }}
      />
    </Card>
  );
}
