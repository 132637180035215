import FormPublicWrapper from "@/components/form-public-wrapper";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useToast } from "@/components/ui/use-toast";
import Routes from "@/routes/paths";
import { UserService } from "@/services/api/user";
import { getToastErrorContent } from "@/utils/functions/api-error";
import { zodResolver } from "@hookform/resolvers/zod";
import { Loader2 } from "lucide-react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import * as z from "zod";

const FormSchema = z.object({
  email: z
    .string()
    .min(1, { message: "Campo obrigatório" })
    .email({ message: "E-mail inválido" }),
});

type FormSchemaType = z.infer<typeof FormSchema>;

export default function SendLinkEmailScreen() {
  const { toast } = useToast();
  const navigate = useNavigate();

  const form = useForm<FormSchemaType>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      email: "",
    },
  });

  async function onSubmit(data: FormSchemaType) {
    try {
      const { email } = FormSchema.parse(data);

      await UserService.forgotPassword(email);

      toast({
        variant: "success",
        description: `Um e-mail contendo as instruções foi enviado para ${email}.`,
      });

      navigate(Routes.base, { replace: true });
    } catch (error) {
      toast(getToastErrorContent(error));
    }
  }

  const { isSubmitting } = form.formState;

  return (
    <FormPublicWrapper>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="m-auto sm:w-2/3 md:w-2/3"
        >
          <Card>
            <CardHeader className="space-y-1">
              <CardTitle className="text-2xl">GymAPP</CardTitle>

              <CardDescription>Recupere sua senha</CardDescription>
            </CardHeader>
            <CardContent className="grid gap-4">
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>E-mail</FormLabel>

                    <FormControl>
                      <Input {...field} />
                    </FormControl>

                    <FormMessage />
                  </FormItem>
                )}
              />
            </CardContent>
            <CardFooter>
              <Button type="submit" className="w-full">
                {isSubmitting && (
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                )}
                Enviar
              </Button>
            </CardFooter>
            <p className="text-sm text-center text-gray-700 mb-3">
              Já possui uma conta?
              <Link
                to={Routes.base}
                className="ml-1 text-blue-600 hover:underline"
              >
                Acessar
              </Link>
            </p>
          </Card>
        </form>
      </Form>
    </FormPublicWrapper>
  );
}
