import ButtonDropdown from "@/components/button-dropdown";
import ModalConfirmDelete from "@/components/modal-confirm";
import { Button } from "@/components/ui/button";
import { DropdownMenuItem } from "@/components/ui/dropdown-menu";
import { Separator } from "@/components/ui/separator";
import { useToast } from "@/components/ui/use-toast";
import { MemberContentService } from "@/services/api/member-content";
import { MemberContent } from "@/utils/entities";
import { getToastErrorContent } from "@/utils/functions/api-error";
import useRefetchRefetchQueries from "@/utils/hooks/use-invalidate-query-client";
import { OpenInNewWindowIcon } from "@radix-ui/react-icons";
import {
  EyeIcon,
  EyeOffIcon,
  FileText,
  FilesIcon,
  Trash2Icon,
  Video,
} from "lucide-react";
import { useState } from "react";
import FormManagerFiles from "./form-manager-files";

type Props = {
  data: MemberContent[];
  onReload?: () => void;
};

type ModalTypes = "FILES" | "DELETE";

const MembersContentList = ({ data, onReload }: Props) => {
  const { toast } = useToast();
  const [content, setContent] = useState<MemberContent | null>(null);
  const [modalType, setModalType] = useState<ModalTypes | null>(null);
  const { refetchMembersContent } = useRefetchRefetchQueries();

  const onSubmitDelete = async () => {
    if (!content) {
      return;
    }

    try {
      await MemberContentService.delete(content.id as string);

      toast({
        variant: "success",
        description: "O conteúdo foi removido com sucesso.",
      });

      setContent(null);
      setModalType(null);

      refetchMembersContent();
    } catch (error) {
      console.log(error);

      toast(getToastErrorContent(error));
    }
  };

  function handleCloseModal() {
    setContent(null);

    setModalType(null);
  }

  const handleDelete = (item: MemberContent) => {
    setContent(item);

    setModalType("DELETE");
  };

  function handleManagerFiles(item: MemberContent) {
    setContent(item);

    setModalType("FILES");
  }

  async function updateStatusContent(item: MemberContent) {
    try {
      await MemberContentService.updateVisibilityContent(item.id as string, {
        ...item,
        isPublished: !item.isPublished,
      });

      toast({
        variant: "success",
        description: "A visualização do conteúdo foi atualizada com sucesso.",
      });

      refetchMembersContent();
    } catch (error) {
      console.log(error);

      toast(getToastErrorContent(error));
    }
  }

  return (
    <>
      <div className="grid grid-cols-3 mt-4 gap-4">
        {data?.map((item) => (
          <div
            key={item.id}
            className="flex flex-col bg-white rounded-md p-4 gap-4"
          >
            <div className="flex font-bold">
              <div className="flex flex-1">
                {item.type === "video" ? (
                  <Video className="mr-2" />
                ) : (
                  <FileText className="mr-2" />
                )}
                {item.title}
              </div>

              <ButtonDropdown>
                <DropdownMenuItem
                  className="cursor-pointer"
                  onClick={() => updateStatusContent(item)}
                >
                  {item?.isPublished ? (
                    <>
                      <EyeOffIcon className="w-4 h-4 mr-1" />
                      Inativar
                    </>
                  ) : (
                    <>
                      <EyeIcon className="w-4 h-4 mr-1" />
                      Publicar
                    </>
                  )}
                </DropdownMenuItem>
                <DropdownMenuItem className="cursor-pointer">
                  <a
                    href={item?.fileUrl}
                    target="_blank"
                    rel="noreferrer"
                    className="flex items-center"
                  >
                    <OpenInNewWindowIcon className="w-4 h-4 mr-1" /> Imagem de
                    capa
                  </a>
                </DropdownMenuItem>
              </ButtonDropdown>
            </div>

            <div className="flex">
              <div
                className={`rounded-full border-[1px] px-2 py-1 text-xs ${
                  item.isPublished ? "bg-green-300" : "bg-red-300"
                }
                `}
              >
                {item.isPublished ? "Publicado" : "Não publicado"}
              </div>
            </div>

            <div className="grid gap-1">
              <div className="text-sm">Categoria:</div>
              <div className="rounded-full border-[1px] px-2 py-1 w-fit text-xs bg-gray-100">
                {item?.category?.name}
              </div>
            </div>

            <div className="grid gap-1">
              <div className="text-sm">Disponível para:</div>
              <div className="flex gap-2 flex-wrap">
                {item?.groups?.map((group) => (
                  <div
                    key={group.id}
                    className="rounded-full border-[1px] px-2 py-1 w-fit text-xs bg-gray-100"
                  >
                    {group.name}
                  </div>
                ))}
              </div>
            </div>

            <div className="mt-auto">
              <Separator />

              <div className="flex flex-row mt-4">
                <Button
                  size="sm"
                  variant="outline"
                  onClick={() => handleDelete(item)}
                >
                  <Trash2Icon className="w-4 h-4 mr-1" /> Excluir
                </Button>

                <Button
                  size="sm"
                  className="ml-auto"
                  onClick={() => handleManagerFiles(item)}
                >
                  <FilesIcon className="w-4 h-4 mr-1" /> Arquivos
                </Button>
              </div>
            </div>
          </div>
        ))}
      </div>

      <ModalConfirmDelete
        title="Atenção"
        onCancel={() => handleCloseModal()}
        isVisible={modalType === "DELETE"}
        onContinue={() => onSubmitDelete()}
        description="O conteúdo será removido e não poderá mais ser utilizado. Deseja continuar ? "
      />

      <FormManagerFiles
        isOpen={modalType === "FILES"}
        onClose={() => handleCloseModal()}
        onSuccess={() => {}}
        memberContent={content}
      />
    </>
  );
};

export default MembersContentList;
