import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import * as z from "zod";
import MultipleSelector, { Option } from "@/components/ui/multiple-selector";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { MemberCategoryService } from "@/services/api/member-category";
import { StudentGroupService } from "@/services/api/student-group";
import { zodResolver } from "@hookform/resolvers/zod";
import { useQuery } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { useToast } from "@/components/ui/use-toast";
import { getToastErrorContent } from "@/utils/functions/api-error";
import { MemberContentService } from "@/services/api/member-content";
import { Textarea } from "@/components/ui/textarea";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
} from "@/components/ui/sheet";
import { Separator } from "@/components/ui/separator";
import Spinner from "@/components/ui/spinner";
import DropzoneFile from "@/components/dropzone-file";
import useFileBucket from "@/utils/hooks/use-file-bucket";
import useRefetchRefetchQueries from "@/utils/hooks/use-invalidate-query-client";

type Props = { isOpen: boolean; onSuccess: () => void; onClose: () => void };

const optionSchema = z.object({
  label: z.string(),
  value: z.string(),
});

const FormSchema = z.object({
  title: z.string().min(1, { message: "Campo obrigatório" }),
  description: z.string().min(1, { message: "Campo obrigatório" }),
  categoryId: z.string().min(1, { message: "Campo obrigatório" }),
  userGroupId: z.array(optionSchema).min(1, { message: "Campo obrigatório" }),
  image_cover: z
    .custom<File | null>()
    .refine((file) => !!file, "Arquivo obrigatório"),
});

type FormSchemaType = z.infer<typeof FormSchema>;

export default function MembersForm({ onClose, onSuccess, isOpen }: Props) {
  const { toast } = useToast();
  const { getSignedUrl } = useFileBucket();
  const { refetchMembersContent } = useRefetchRefetchQueries();

  const form = useForm<FormSchemaType>({
    resolver: zodResolver(FormSchema),
  });

  const { data: memberCategories } = useQuery({
    queryKey: ["members_categories"],
    queryFn: MemberCategoryService.getAll,
    enabled: isOpen,
  });

  const { data: studentGroups } = useQuery({
    queryKey: ["student_groups"],
    queryFn: StudentGroupService.getAll,
    enabled: isOpen,
  });

  const { errors, isSubmitting } = form.formState;

  async function onSubmit(data: FormSchemaType) {
    try {
      const { image_cover, ...formData } = FormSchema.parse(data);

      if (!image_cover) {
        return;
      }

      const response = await MemberContentService.create({
        title: formData.title,
        fileUrl: "default",
        description: formData.description,
        categoryId: formData.categoryId,
        userGroupId: formData.userGroupId.map((item) => item.value),
      });

      const fileUrl = await getSignedUrl(
        image_cover,
        `members/${response?.id}/files`
      );

      await MemberContentService.update({
        ...response,
        fileUrl,
      } as any);

      form.reset({
        title: "",
        categoryId: "",
        description: "",
        image_cover: null,
        userGroupId: [],
      });

      refetchMembersContent();

      onSuccess?.();
    } catch (error) {
      toast(getToastErrorContent(error));
    }
  }

  const multipleOptions = studentGroups
    ? (studentGroups?.map((item) => ({
        label: item.name,
        value: item.id,
      })) as Option[])
    : [];

  return (
    <Sheet open={isOpen}>
      <SheetContent
        side="right"
        className="bg-white min-h-[80%] flex flex-col gap-0 p-0"
        onClose={onClose}
      >
        <SheetHeader className="p-4">
          <SheetTitle>Cadastrar conteúdo</SheetTitle>
          <SheetDescription>
            Crie o conteúdo para seus grupos de membros
          </SheetDescription>
        </SheetHeader>

        <Separator />

        <Form {...form}>
          <div className="grid flex-1 h-full overflow-auto">
            <div className="flex flex-col gap-5 p-4 col-span-1 border-r">
              <div>
                <FormField
                  name="title"
                  control={form.control}
                  render={({ field }) => (
                    <FormItem className="">
                      <FormLabel className="text-gray-600">Título</FormLabel>

                      <FormControl>
                        <Input className=" text-gray-600" {...field} />
                      </FormControl>

                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <div>
                <FormField
                  name="description"
                  control={form.control}
                  render={({ field }) => (
                    <FormItem className="">
                      <FormLabel className="text-gray-600">Descrição</FormLabel>

                      <FormControl>
                        <Textarea placeholder="..." {...field} />
                        {/* <Plate>
                        <PlateContent placeholder="..." {...field} />
                      </Plate> */}
                      </FormControl>

                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <div>
                <FormField
                  name="categoryId"
                  control={form.control}
                  render={({ field }) => (
                    <FormItem className="">
                      <FormLabel className="text-gray-600">Categoria</FormLabel>

                      <FormControl>
                        <Select
                          onValueChange={field.onChange}
                          defaultValue={field.value}
                        >
                          <SelectTrigger className="w-full">
                            <SelectValue placeholder="Selecione uma catagoria" />
                          </SelectTrigger>
                          <SelectContent>
                            {memberCategories?.map((item) => (
                              <SelectItem
                                key={item.id}
                                value={item?.id as string}
                              >
                                {item?.name}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      </FormControl>

                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <div>
                <FormField
                  name="userGroupId"
                  control={form.control}
                  render={({ field }) => (
                    <FormItem className="">
                      <FormLabel className="text-gray-600">
                        Grupos de usuários
                      </FormLabel>

                      <FormControl>
                        <MultipleSelector
                          value={field.value}
                          onChange={field.onChange}
                          options={multipleOptions}
                          placeholder="Selecione 1 ou mais grupos"
                          emptyIndicator={
                            <p className="text-center text-lg leading-10 text-gray-600 dark:text-gray-400">
                              sem resultado.
                            </p>
                          }
                        />
                      </FormControl>

                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <div>
                <DropzoneFile
                  label="Foto de capa"
                  error={errors?.image_cover?.message}
                  optionsDropzone={{ accept: { "image/*": [".jpeg", ".png"] } }}
                  onChange={(file) =>
                    form.setValue("image_cover", file, { shouldValidate: true })
                  }
                />
              </div>
            </div>
          </div>
        </Form>

        <SheetFooter className="border-t py-5 px-4">
          <SheetClose asChild>
            <Button variant="outline" onClick={onClose} disabled={isSubmitting}>
              Cancelar
            </Button>
          </SheetClose>
          <SheetClose asChild>
            <Button
              disabled={isSubmitting}
              onClick={form.handleSubmit(onSubmit)}
            >
              {isSubmitting ? (
                <Spinner className="h-[14px] w-[14px] mr-2" />
              ) : null}
              Salvar
            </Button>
          </SheetClose>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  );
}
