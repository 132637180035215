import RootLayout from "@/components/layout";
import { useQuery } from "@tanstack/react-query";
import { useParams, useSearchParams } from "react-router-dom";
import { SheetService } from "@/services/api/sheet";
import { Loading } from "@/components/ui/loading";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import ButtonBack from "@/components/button-back";
import ModalAddLetter from "../student/components/modal/modal-add-letter";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Exercise, SheetTemplate, Training } from "@/utils/entities";
import { SheetGridExercisesAdded } from "../student/components/sheet/sheet-list-exercises-added";
import { Button } from "@/components/ui/button";
import { PlusIcon } from "lucide-react";
import { useState } from "react";
import SheetDialogAddExercise from "../student/components/sheet/sheet-dialog-add-exercise";

type SheetDialogTypes = "ADD" | "EDIT" | "DELETE" | "LIST_EXECUTION";

function TabsTrainingHeader({ trainings }: { trainings: Training[] }) {
  return (
    <div className="flex">
      <TabsList className="bg-gray-600 text-gray-800">
        {trainings?.map((item) => (
          <TabsTrigger key={item?.name} value={item.name ?? ""}>
            {item?.index + 1}ª - {item?.name}
          </TabsTrigger>
        ))}
      </TabsList>
    </div>
  );
}

export default function SheetTemplateEditScreen() {
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const tabLetterValue = searchParams.get("tabLetterKey") || undefined;

  const [dialogType, setDialogType] = useState<SheetDialogTypes>();
  const [activeTrainingIndex, setActiveTrainingIndex] = useState<number>();

  const {
    refetch,
    isFetching,
    data: template,
  } = useQuery({
    queryKey: ["template", id],
    queryFn: () => SheetService.getOneTemplate(id as string),
  });

  const onUpdateTemplate = async (newTemplate: Partial<SheetTemplate>) => {
    await SheetService.updateTemplate(id!, newTemplate);
    await refetch();
  };

  async function onCreateLetter(name: string) {
    await onUpdateTemplate({
      ...template,
      data: [
        ...(template?.data || []),
        { name, exercises: [], index: template?.data.length || 0 },
      ],
    });
  }

  function handleChangeTabLetter(value: string) {
    searchParams.set("tabLetterKey", value);

    setSearchParams(searchParams, { replace: true });
  }

  function handleAddExercise(index: number) {
    setActiveTrainingIndex(index);
    setDialogType("ADD");
  }

  function handleCloseDialog() {
    setDialogType(undefined);
    setActiveTrainingIndex(undefined);
  }

  const addExercise = (exercise: Exercise) => {
    console.log({ exercise });
    template!.data[activeTrainingIndex!].exercises.push({
      ...exercise,
      execution: {
        ...exercise.execution,
        order: template!.data[activeTrainingIndex!].exercises?.length || 1,
      },
    });
    onUpdateTemplate(template!);
  };

  const onRemoveExercise = (trainingIndex: number, exerciseIndex: number) => {
    template!.data[trainingIndex].exercises.splice(exerciseIndex, 1);
    onUpdateTemplate(template!);
  };

  async function updateOrderListOfExercises(
    trainingIndex: number,
    items: Exercise[]
  ) {
    template!.data[trainingIndex].exercises = items.map((item, order) => ({
      ...item,
      execution: { ...item.execution, order },
    }));
    onUpdateTemplate(template!);
  }

  return (
    <RootLayout>
      {isFetching ? (
        <Loading />
      ) : (
        <Card className="bg-gray-800 p-0">
          <CardHeader>
            <div className="flex text-white items-center">
              <ButtonBack />
              <div className="flex flex-col flex-1">
                <CardTitle className="text-gray-200 flex flex-col md:flex-row md:items-center justify-start">
                  <div>{template?.name}</div>
                </CardTitle>
                <CardDescription>
                  Você esta atualizando um template de ficha
                </CardDescription>
              </div>

              <div className="flex ml-auto mt-4 md-mt-0 gap-4">
                <ModalAddLetter onSave={onCreateLetter} />
              </div>
            </div>
          </CardHeader>

          <CardContent>
            <div className="grid gap-5">
              {!template?.data || !template?.data[0] ? (
                <div className="text-white">Nenhuma letra cadastrada</div>
              ) : (
                <Tabs
                  className="mt-5"
                  value={tabLetterValue}
                  defaultValue={template?.data[0].name}
                  onValueChange={handleChangeTabLetter}
                >
                  <TabsTrainingHeader trainings={template?.data || []} />

                  {template?.data.map((training, trainingIndex) => (
                    <TabsContent
                      key={training.id + "_" + trainingIndex}
                      value={training.name ?? ""}
                    >
                      <div className="grid">
                        <SheetGridExercisesAdded
                          sheetId={id!}
                          exercises={training.exercises as Exercise[]}
                          handleRemove={(_exercise, exerciseIndex) =>
                            onRemoveExercise(trainingIndex, exerciseIndex)
                          }
                          handleUpdateOrderListOfExercises={(exercises) =>
                            updateOrderListOfExercises(
                              trainingIndex!,
                              exercises
                            )
                          }
                        />

                        <Button
                          size="sm"
                          type="button"
                          className="mt-5"
                          variant="secondary"
                          onClick={() => handleAddExercise(trainingIndex)}
                        >
                          <PlusIcon className="h-4 w-4 mr-1" />
                          Adicionar exercício
                        </Button>
                      </div>
                    </TabsContent>
                  ))}
                </Tabs>
              )}
            </div>
          </CardContent>
        </Card>
      )}

      {dialogType === "ADD" && activeTrainingIndex !== undefined ? (
        <SheetDialogAddExercise
          isOpened={true}
          onChange={addExercise}
          onCancel={() => handleCloseDialog()}
        />
      ) : null}
    </RootLayout>
  );
}
