import { GenericStatus, StudentFoodPlan } from "@/utils/entities";
import api from "./config";
import { getDateFormatText } from "@/utils/functions/date-transform";

type AttachFileInput = {
  title: string;
  file_url: string;
  studentId: string;
};

type DeleteFoodPlanInput = {
  studentId: string;
  mealPlanId: string;
};

type UpdateFoodPlanInput = {
  id: string;
  status: string;
  studentId: string;
};

function parseItem(item: StudentFoodPlan) {
  return {
    ...item,
    isActive: item.status === GenericStatus.ACTIVE,
    createdAtText: getDateFormatText(item.createdAt),
  };
}

async function getAllFoodPlan(studentId: string): Promise<StudentFoodPlan[]> {
  const response = await api.get(`/student/${studentId}/food-plans`);

  const { food_plans } = response.data;

  return food_plans.map((item: StudentFoodPlan) => parseItem(item));
}

async function createFoodPlan({ title, file_url, studentId }: AttachFileInput) {
  const data = { title, file_url, studentId }

  const response = (await api.post(`/student/${studentId}/food-plan`, data))

  return response.data
}

async function deleteFoodPlan({ studentId, mealPlanId }: DeleteFoodPlanInput) {
  const data = { studentId, mealPlanId };

  return (await api.delete(`/student/${studentId}/food-plan`, { data })).data;
}

async function updateFoodPlan({ id, status, studentId }: UpdateFoodPlanInput) {
  const data = { id, studentId, status };

  return (await api.put(`/student/${studentId}/food-plan`, data)).data;
}

export const StudentFoodPlanService = {
  getAll: getAllFoodPlan,
  create: createFoodPlan,
  delete: deleteFoodPlan,
  update: updateFoodPlan,
};
