import FormPublicWrapper from "@/components/form-public-wrapper";
import { InputIcon } from "@/components/input-icon";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useToast } from "@/components/ui/use-toast";
import Routes from "@/routes/paths";
import { UserService, parseUser } from "@/services/api/user";
import StorageToken from "@/services/storage/token";
import StorageUser from "@/services/storage/user";
import { getToastErrorContent } from "@/utils/functions/api-error";
import { useUserProvider } from "@/utils/providers/user-provider";
import { zodResolver } from "@hookform/resolvers/zod";
import { EyeIcon, EyeOffIcon, Loader2 } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import * as z from "zod";

const FormSchema = z.object({
  email: z
    .string()
    .min(1, { message: "Campo obrigatório" })
    .email({ message: "E-mail inválido" }),
  password: z.string().min(1, { message: "Campo obrigatório" }),
});

type FormSchemaType = z.infer<typeof FormSchema>;

export default function SignInScreen() {
  const navigate = useNavigate();
  const { toast } = useToast();
  const { setUser } = useUserProvider();
  const [passwordType, setPasswordType] = useState("password");

  const form = useForm<FormSchemaType>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  async function onSubmit(data: FormSchemaType) {
    try {
      const { token, user } = await UserService.login(FormSchema.parse(data));

      const parsedUser = parseUser(user);

      setUser(parsedUser);

      StorageUser.set(parsedUser);
      StorageToken.set(token);

      navigate(Routes.home, { replace: true });
    } catch (error) {
      toast(getToastErrorContent(error));
    }
  }

  const togglePasswordInputType = () =>
    setPasswordType((prev) => (prev === "password" ? "text" : "password"));

  const { isSubmitting } = form.formState;

  const InputPasswordIcon = passwordType === "password" ? EyeOffIcon : EyeIcon;

  return (
    <FormPublicWrapper>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="m-auto sm:w-2/3 md:w-2/3"
        >
          <Card>
            <CardHeader className="space-y-1">
              <CardTitle className="text-2xl">GymAPP</CardTitle>

              <CardDescription>Faça login para iniciar</CardDescription>
            </CardHeader>

            <CardContent className="grid gap-4">
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>E-mail</FormLabel>

                    <FormControl>
                      <Input placeholder="" {...field} />
                    </FormControl>

                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="password"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Senha</FormLabel>

                    <FormControl>
                      <InputIcon
                        icon={
                          <InputPasswordIcon
                            size={20}
                            className="text-zinc-700"
                          />
                        }
                        onClick={togglePasswordInputType}
                      >
                        <Input
                          {...field}
                          type={passwordType}
                          className="pr-10"
                        />
                      </InputIcon>
                    </FormControl>

                    <p className="text-blue-600 hover:underline text-sm text-right">
                      <Link to={Routes.account.forgotPassword}>
                        Esqueceu sua senha ?
                      </Link>
                    </p>

                    <FormMessage />
                  </FormItem>
                )}
              />
            </CardContent>

            <CardFooter>
              <Button type="submit" className="w-full">
                {isSubmitting && (
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                )}
                Acessar
              </Button>
            </CardFooter>
          </Card>
        </form>
      </Form>
    </FormPublicWrapper>
  );
}
