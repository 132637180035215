import DropzoneVideo from "@/components/dropzone-video";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from "@/components/ui/sheet";
import { Switch } from "@/components/ui/switch";
import { useToast } from "@/components/ui/use-toast";
import {
  ExerciseCreateInput,
  ExerciseService,
  ExerciseUpdateInput,
} from "@/services/api/exercise";
import { ExerciseTypeService } from "@/services/api/exercise-type";
import { ExerciseVideoService } from "@/services/api/exercise-video";
import { Exercise } from "@/utils/entities";
import { getToastErrorContent } from "@/utils/functions/api-error";
import { zodResolver } from "@hookform/resolvers/zod";
import { useQuery } from "@tanstack/react-query";
import { Loader2Icon } from "lucide-react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import * as z from "zod";

type FormProps = {
  isOpened?: boolean;
  exercise: Exercise | null;
  onCancel?: () => void;
  onSuccessful?: () => void;
};

const formCreateSchema = z.object({
  id: z.string().optional(),
  name: z.string().min(1, { message: "Campo obrigatório" }),
  file: z.custom<File>().refine((file) => !!file, "Arquivo obrigatório"),
  status: z.boolean().default(true).optional(),
  video_url: z.string().optional(),
  exerciseTypeId: z.string().min(1, { message: "Campo obrigatório" }),
});

const formEditSchema = z.object({
  id: z.string(),
  name: z.string().min(1, { message: "Campo obrigatório" }),
  file: z.custom<File | null>().optional(),
  exerciseTypeId: z.string().min(1, { message: "Campo obrigatório" }),
  status: z.boolean().default(true).optional(),
  video_url: z.string().optional(),
});

const formSchema = z.union([formCreateSchema, formEditSchema]);

const formTexts = {
  create: {
    formHeaderTitle: "Cadastro de exercício",
    feedbackSuccess: "Exercício cadastrado com sucesso.",
  },
  update: {
    formHeaderTitle: "Edição de exercício",
    feedbackSuccess: "Exercício atualizado com sucesso.",
  },
};

const defaultValues: any = {
  id: "",
  name: "",
  exerciseTypeId: "",
  status: true,
};

export default function ExerciseFormCreate({
  isOpened,
  exercise,
  onCancel,
  onSuccessful,
}: FormProps) {
  const { toast } = useToast();

  const type = exercise ? "update" : "create";

  const form = useForm<typeof formSchema>({
    resolver: zodResolver(formSchema),
    defaultValues,
  });

  const { data: exerciseTypes } = useQuery({
    retry: false,
    queryFn: ExerciseTypeService.getAll,
    queryKey: ["execise_types"],
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (exercise) {
      form.reset({
        id: exercise?.id ?? "",
        name: exercise?.name ?? "",
        status: exercise?.isActive,
        video_url: exercise?.video_url ?? "",
        exerciseTypeId: exercise?.exerciseTypeId ?? "",
      });
    }
  }, [exercise]);

  function handleReset() {
    form.reset(defaultValues);

    onCancel?.();
  }

  const onSubmit = async (data: typeof formCreateSchema) => {
    try {
      const { file, ...formData } = formSchema.parse(data);

      const status = formData.status ? "ACTIVE" : "INACTIVE";

      if (file) {
        const responseUrl = await ExerciseVideoService.generateVideoBucketUrl({
          file,
          folder: "exercises",
        });

        const responseUpload = await ExerciseVideoService.uploadVideoToBucket({
          ...responseUrl,
          file,
        });

        console.log(responseUpload);

        formData.video_url = responseUrl.bucketVideoUrl;
      }

      if (formData.id) {
        await ExerciseService.update({
          ...formData,
          status,
        } as ExerciseUpdateInput);
      } else {
        await ExerciseService.create({
          ...formData,
          id: undefined,
          status,
        } as ExerciseCreateInput);
      }

      toast({
        variant: "success",
        description: formTexts[type].feedbackSuccess,
      });

      form.reset(defaultValues);

      handleReset?.();

      onSuccessful?.();
    } catch (error) {
      console.log(error);

      toast(getToastErrorContent(error));
    }
  };

  const { errors, isSubmitting } = form.formState;

  return (
    <Sheet open={isOpened} onOpenChange={handleReset}>
      <SheetContent className="bg-white py-3">
        <SheetHeader>
          <SheetTitle>{formTexts[type].formHeaderTitle}</SheetTitle>
        </SheetHeader>

        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="overflow-x-scroll h-full pb-10 w-full"
          >
            <div className="mt-6 grid gap-6">
              <div className="grid">
                <FormField
                  name="name"
                  control={form.control}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Nome</FormLabel>

                      <FormControl>
                        <Input {...field} />
                      </FormControl>

                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="grid">
                <FormField
                  control={form.control}
                  name="exerciseTypeId"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Tipo</FormLabel>
                      <Select
                        value={field.value}
                        onValueChange={field.onChange}
                      >
                        <FormControl>
                          <SelectTrigger className="data-[placeholder]:text-gray-500">
                            <SelectValue placeholder="Selecione um tipo de exercício" />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          {exerciseTypes?.map((item) => (
                            <SelectItem key={item.id} value={item.id}>
                              {item?.name}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="grid">
                <DropzoneVideo
                  error={errors?.file?.message}
                  onChange={(file) =>
                    form.setValue("file", file, { shouldValidate: true })
                  }
                />
              </div>
              <div className="grid">
                <FormField
                  control={form.control}
                  name="status"
                  render={({ field }) => (
                    <FormItem className="flex items-center space-y-0">
                      <FormLabel className="mr-3">Status</FormLabel>
                      <FormControl>
                        <Switch
                          checked={field.value}
                          onCheckedChange={field.onChange}
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />
              </div>
              <div className="grid mt-2">
                <Button disabled={isSubmitting} className=" hover:bg-gray-900">
                  {isSubmitting && (
                    <Loader2Icon className="mr-2 h-4 w-4 animate-spin" />
                  )}
                  Salvar
                </Button>
              </div>
            </div>
          </form>
        </Form>
      </SheetContent>
    </Sheet>
  );
}
