import React from "react";
import { Router } from "@/routes";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { Toaster } from "@/components/ui/toaster";
import pkg from "../package.json";
import { Worker } from "@react-pdf-viewer/core";

import "./styles/globals.css";
// Import the styles
import "@react-pdf-viewer/core/lib/styles/index.css";

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
      <RouterProvider router={Router} />
    </Worker>

    <Toaster />
  </React.StrictMode>
);

console.log("APP VERSION: ", pkg?.version);
