import { Button } from "@/components/ui/button";
import { Card, CardContent, CardTitle } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { useToast } from "@/components/ui/use-toast";
import { cn } from "@/utils";
import { Check, Copy } from "lucide-react";
import React, { useEffect, useState } from "react";

type Props = { id: string };

const ProfessionalLink: React.FC<Props> = ({ id }) => {
  const { toast } = useToast();
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied)
      setTimeout(() => {
        setCopied(false);
      }, 2000);
  }, [copied]);

  const link = `${location.origin}/profissional/${id}/subscribe`;

  const copy = () => {
    if ("clipboard" in navigator) {
      navigator.clipboard.writeText(link);

      setCopied(true);

      return;
    }

    toast({
      variant: "destructive",
      description: "Não foi possível copiar o link",
    });
  };

  return (
    <Card className="bg-gray-800 w-full ">
      <CardContent className="mt-5">
        <CardTitle className="flex text-gray-200 items-center justify-between">
          Link de inscrição
        </CardTitle>

        <div className="relative">
          <Input readOnly className="bg-white mt-4" value={link} />
          <Button
            onClick={copy}
            variant="outline"
            className={cn(
              "absolute right-0 top-0 transition-all",
              copied ? "bg-green-400" : "bg-gray-100"
            )}
          >
            {copied ? <Check className="mr-2" /> : <Copy className="mr-2" />}
            {copied ? "Copiado" : "Copiar"}
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};

export default ProfessionalLink;
