import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { EditIcon, Plus, Trash } from "lucide-react";
import { useCallback, useEffect, useState } from "react";
import UserGroupForm from "./form";
import { StudentGroup } from "@/utils/entities";
import { StudentGroupService } from "@/services/api/student-group";
import ModalConfirmDelete from "@/components/modal-confirm";

const UserGroupList: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [formIsOpen, setFormIsOpen] = useState(false);
  const [deleteIsOpen, setDeleteIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<StudentGroup>();

  const listGroups = useCallback(async () => {
    if (!isLoading) {
      setIsLoading(true);
      const groups = await StudentGroupService.getAll();
      setData(groups);
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    listGroups();
  }, [listGroups]);

  const openToDelete = (item: StudentGroup) => {
    setSelectedItem(item);
    setDeleteIsOpen(true);
  };

  const cancelToDelete = () => {
    setSelectedItem(undefined);
    setDeleteIsOpen(false);
  };

  const openForm = (item?: StudentGroup) => {
    if (item) {
      setSelectedItem(item);
    }

    setFormIsOpen(true);
  };

  const closeForm = () => {
    setFormIsOpen(false);
    setSelectedItem(undefined);
  };

  const saveForm = async (payload: StudentGroup) => {
    if (selectedItem) {
      payload.id = selectedItem.id;
    }

    await StudentGroupService.createOrUpdate(payload);

    closeForm();

    listGroups();
  };

  const deleteStudentGroup = () => {};

  return (
    <Card className="bg-gray-800">
      <CardHeader>
        <div className="flex justify-between">
          <CardTitle className="flex text-gray-200 items-center justify-between">
            Grupos de usuários
          </CardTitle>

          <Button className="px-4" onClick={() => openForm()}>
            <Plus className="mr-2" /> Novo grupo
          </Button>
        </div>
      </CardHeader>
      <CardContent className="grid w-full overflow-auto">
        {isLoading ? (
          "Loading..."
        ) : (
          <Table>
            <TableCaption>Lista de alunos.</TableCaption>
            <TableHeader>
              <TableRow>
                <TableHead className="text-gray-400 w-[calc(50% - 300px)]">
                  Nome do grupo
                </TableHead>
                <TableHead className="text-gray-400 w-[calc(50% - 300px)]">
                  Descrição
                </TableHead>
                <TableHead className="text-gray-400 w-[140px]">
                  Total de alunos
                </TableHead>
                <TableHead className="w-[10px]" />
              </TableRow>
            </TableHeader>
            <TableBody className="text-gray-200">
              {data?.map((item) => (
                <TableRow key={item?.id}>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{item.description}</TableCell>
                  <TableCell className="text-center">
                    {item.students.length}
                  </TableCell>
                  <TableCell className="text-right space-x-2 pr-0 flex items-center justify-end">
                    <Button
                      variant="outline"
                      size="sm"
                      onClick={() => openForm(item)}
                    >
                      <EditIcon className="h-4 w-4 mr-2" /> Editar
                    </Button>
                    {item.students.length === 0 && (
                      <Button
                        size="sm"
                        variant="outline"
                        onClick={() => openToDelete(item)}
                      >
                        <Trash className="h-4 w-4 mr-2" /> Deletar
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </CardContent>

      <UserGroupForm
        onSubmit={saveForm}
        onClose={closeForm}
        isOpen={formIsOpen}
        initialValues={selectedItem}
      />

      <ModalConfirmDelete
        isVisible={!!deleteIsOpen}
        onCancel={cancelToDelete}
        onContinue={deleteStudentGroup}
        title="Confirmação de deletação"
        description={
          <>
            Você tem certeza que quer
            <strong> EXCLUIR {selectedItem?.name}</strong>?
          </>
        }
      />
    </Card>
  );
};

export default UserGroupList;
