import { useQueryClient } from "@tanstack/react-query"


export default function useRefetchRefetchQueries() {
  const queryClient = useQueryClient()


  const refetchStudentData = (id: string) => {
    return queryClient.invalidateQueries({ queryKey: ["student", id], exact: true, refetchType: 'all' })
  }

  const refetchMembersContent = () => {
    return queryClient.invalidateQueries({ queryKey: ["members_content"], exact: true })
  }

  const refetchMemberCategoryFiles = (id: string) => {
    return queryClient.invalidateQueries({ queryKey: ["members_content_files", id], exact: true })
  }

  const refetchSheetDetails = (id: string) => {
    return queryClient.invalidateQueries({ queryKey: ["sheet_details", id], exact: true, refetchType: 'all' })
  }

  const refetchSheetListOfStudent = (id: string) => {
    return queryClient.invalidateQueries({ queryKey: ["student_sheet_list", id], exact: true, refetchType: 'all' })
  }

  return { refetchStudentData, refetchMembersContent, refetchMemberCategoryFiles, refetchSheetDetails, refetchSheetListOfStudent }
}