import { MemberContentFile } from "@/utils/entities";
import api from "./config";

const listFileContent = async (contentId: string) => {
  const { files } = (await api.get(`/members/content/${contentId}/files`)).data;

  return files as MemberContentFile[];
};

const createFileContent = async (contentId: string, data: Partial<MemberContentFile>) => {
  const { memberContentFile } = (await api.post(`/members/content/${contentId}/files`, data)).data;

  return memberContentFile;
};

const deleteFileContent = async (contentId: string, fileId: string) => {
  return (await api.delete(`/members/content/${contentId}/files/${fileId}`)).data;
};

export const MemberContentFileService = {
  list: listFileContent,
  create: createFileContent,
  delete: deleteFileContent,
};
