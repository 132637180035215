import StorageToken from "./token"
import StorageUser from "./user"

export const Storage = {
  User: StorageUser,
  Token: StorageToken,
  clear: () => {
    localStorage.clear()
  }
}

export const StorageService = Storage