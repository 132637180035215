import { Exercise, ExerciseType, GenericStatus } from "@/utils/entities";

export function getStatusText(status: string) {
  switch (status) {
    case GenericStatus.ACTIVE: {
      return "🟢 Ativo";
    }
    case GenericStatus.INACTIVE: {
      return "🔴 Inativo";
    }
    // case GenericStatus.PENDING: {
    //   return "Pendente";
    // }
    default: {
      return "🔴 Inativo";
    }
  }
}

export function getExerciseTypesByList(data: Exercise[] | undefined) {
  if (!data) {
    return [];
  }

  const uniqData = [
    ...new Set(data.map((item) => item.type).map((o) => JSON.stringify(o))),
  ];

  return uniqData.map((s) => JSON.parse(s)) as ExerciseType[];
}
