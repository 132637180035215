import { StudentAttachment } from "@/utils/entities";
import api from "./config";
import { getDateFormatText } from "@/utils/functions/date-transform";

type AttachFileInput = {
  title: string;
  file_url: string;
  studentId: string;
};

type DeleteAttachmentInput = {
  studentId: string;
  attachmentId: string;
};

function parseAttachmentItem(item: StudentAttachment) {
  return {
    ...item,
    createdAtText: getDateFormatText(item.createdAt),
  };
}

async function getAllAttachments(
  studentId: string
): Promise<StudentAttachment[]> {
  const response = await api.get(`/student/${studentId}/attachments`);

  const { attachments } = response.data;

  return attachments.map((item: StudentAttachment) =>
    parseAttachmentItem(item)
  );
}

async function createAttachment({
  title,
  file_url,
  studentId,
}: AttachFileInput) {
  const data = { title, file_url, studentId }

  const response = (await api.post(`/student/${studentId}/attachment`, data))

  return response.data
}

async function deleteAttachment({
  studentId,
  attachmentId,
}: DeleteAttachmentInput) {
  const data = { studentId, attachmentId };

  return (await api.delete(`/student/${studentId}/attachment`, { data })).data;
}

export const StudentAttachmentService = {
  getAll: getAllAttachments,
  create: createAttachment,
  delete: deleteAttachment,
};
