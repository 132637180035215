import { Button } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";
import { cn } from "@/utils";
import { Exercise } from "@/utils/entities";

interface SheetExerciseItemProps extends React.HTMLAttributes<HTMLDivElement> {
  exercise: Exercise;
  onSelect?: () => void;
  children?: any;
}

export default function SheetExerciseItem({
  exercise,
  onSelect,
  className,
  children,
  ...props
}: SheetExerciseItemProps) {
  return (
    <div className={cn("h-full flex flex-col", className)} {...props}>
      <div className="flex flex-col flex-1 gap-1 p-3">
        <h3 className="text-md font-semibold">{exercise?.name}</h3>
        <p className="text-sm text-zinc-700 mt-1">{exercise?.type?.name}</p>
        <p className="text-xs text-zinc-400">
          Atualizado em {exercise?.updatedAtText}
        </p>
      </div>
      <Separator className="border-zinc-500" />

      {children || (
        <div className="p-2 w-full">
          <Button
            size="xs"
            onClick={onSelect}
            variant="ghost"
            className="w-full text-xs"
          >
            Selecionar
          </Button>
        </div>
      )}
    </div>
  );
}
