import RootLayout from "@/components/layout";
import MembersContentList from "./components/list";
import { Button } from "@/components/ui/button";
import { FileUp } from "lucide-react";
import PublishToMembers from "./components/form";
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { MemberContentService } from "@/services/api/member-content";
import Spinner from "@/components/ui/spinner";

export default function MembersScreen() {
  const [isOpen, setIsOpen] = useState(false);

  const { data, isLoading, isFetched } = useQuery({
    queryKey: ["members_content"],
    queryFn: MemberContentService.getAll,
    refetchOnWindowFocus: false,
  });

  const onSuccessPublishFileToMembers = () => {
    setIsOpen(false);
  };

  return (
    <RootLayout>
      <div className="max-w-[1024px] m-auto flex flex-col">
        <h1 className="text-white text-2xl flex w-full justify-between">
          Área de membros
          <Button variant={"outline"} onClick={() => setIsOpen(true)}>
            <FileUp className="w-4 h-4 mr-1" /> Adicionar conteúdo
          </Button>
        </h1>

        {isLoading && !data ? (
          <div className="flex h-[70vh] items-center justify-center text-zinc-300">
            <Spinner className="mr-2" />
            <span className="text-sm">Carregando</span>
          </div>
        ) : null}

        {isFetched && !data?.length ? (
          <div className="flex h-[70vh] items-center justify-center text-zinc-300">
            <span className="text-sm">Sem conteúdo para exibir.</span>
          </div>
        ) : null}

        {isFetched && data?.length ? <MembersContentList data={data} /> : null}
      </div>

      <PublishToMembers
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onSuccess={() => onSuccessPublishFileToMembers()}
      />
    </RootLayout>
  );
}
