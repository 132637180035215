import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { ProfessionalService } from "@/services/api/professional";
import { Theme, User } from "@/utils/entities";
import { useToast } from "@/components/ui/use-toast";
import AccountForm from "./account-form";
import About from "./about";
import SubscribeSuccess from "./success";
import { StudentService } from "@/services/api/student";

export default function SubscribeScreen() {
  const { toast } = useToast();
  const { id } = useParams<{ id: string }>();
  const [startForm, setStartForm] = useState(false);
  const [professionalName, setProfessionalName] = useState<string>();
  const [student, setStudent] = useState<{ name: string; email: string }>();
  const [theme, setTheme] = useState<Theme>();

  useEffect(() => {
    if (id)
      ProfessionalService.getForm(id).then(({ name, theme }) => {
        setProfessionalName(name);
        setTheme(theme);
      });
  }, []);

  const onSubmit = async (values: Partial<User>) => {
    try {
      if (values.name && values.email) {
        await StudentService.subscribe({
          ...values,
          professionalId: id || "",
        });
        setStudent({ email: values.email, name: values.name });
      }
    } catch (err) {
      toast({
        variant: "destructive",
        description: (err as any)?.response?.data?.message || "Erro inesperado",
      });
    }
  };

  return (
    <div
      className="relative flex flex-col justify-center items-center min-h-screen overflow-hidden bg-dark-background text-white"
      style={theme && { background: theme.primary_color }}
    >
      <div className="w-full m-auto md:max-w-2xl">
        {!startForm && !!professionalName && (
          <About
            theme={theme}
            imageUrl={theme?.logo_image_url || ""}
            professionalName={professionalName}
            onContinue={() => {
              setStartForm(true);
            }}
          />
        )}

        {startForm && !student && <AccountForm onSubmit={onSubmit} />}

        {student && professionalName && (
          <SubscribeSuccess
            theme={theme}
            imageUrl={theme?.logo_image_url}
            professionalName={professionalName || ""}
            studentEmail={student.email}
            studentName={student.name}
          />
        )}
      </div>
    </div>
  );
}
