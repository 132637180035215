import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Exercise } from "@/utils/entities";
import SheetExerciseVideo from "./sheet-exercise-video";
import { Button } from "@/components/ui/button";
import { DumbbellIcon, EditIcon, Loader2Icon, Trash2Icon } from "lucide-react";
import { Card } from "@/components/ui/card";
import { useState } from "react";
import { cn } from "@/utils";

type Props = {
  isLoading?: boolean;
  sheetId: string;
  exercises: Exercise[];
  handleEdit?: (item: any) => void;
  handleRemove: (item: any, index: number) => void;
  handleUpdateOrderListOfExercises: (items: Exercise[]) => void;
  handleShowExecutions?: (item: any) => void;
};

const SheetTableExercisesAdded = ({
  exercises,
  handleEdit,
  handleRemove,
}: Props) => {
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>Vídeo</TableHead>
          <TableHead>Nome</TableHead>
          <TableHead>Tipo</TableHead>
          <TableHead>Plano de execução</TableHead>
          <TableHead>Observação</TableHead>
          <TableHead className="w-[100px]" />
        </TableRow>
      </TableHeader>

      <TableBody className="text-gray-200">
        {exercises?.map((item, index) => (
          <TableRow key={item.id + "_" + index}>
            <TableCell>
              <SheetExerciseVideo
                exercise={item}
                className="w-[100px] h-[100px]"
              />
            </TableCell>
            <TableCell>{item.name}</TableCell>
            <TableCell>{item.type.name}</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell>
              <div className="flex items-center gap-3">
                <Button
                  variant="secondary"
                  className="gap-1"
                  onClick={() => handleEdit?.(item)}
                >
                  <EditIcon size={14} /> Editar
                </Button>
                <Button
                  variant="secondary"
                  className="gap-1"
                  onClick={() => handleRemove?.(item, index)}
                >
                  <Trash2Icon size={14} /> Remover
                </Button>
              </div>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

const SheetGridExercisesAdded = ({
  sheetId,
  exercises,
  handleEdit,
  handleRemove,
  handleShowExecutions,
  handleUpdateOrderListOfExercises,
  isLoading,
}: Props) => {
  const [data, setData] = useState<Exercise[]>(exercises);
  const [draggingItem, setDraggingItem] = useState<Exercise | null>(null);

  function handleDragStart(event: any, exercise: Exercise) {
    setDraggingItem(exercise);

    event?.dataTransfer?.setData("text/plain", "");
  }

  function handleDragEnd() {
    setDraggingItem(null);
  }

  function handleDragOver(event: any) {
    event?.preventDefault();
  }

  function handleDrop(targetItem: Exercise) {
    if (!draggingItem) {
      console.log("draggingItem not provided");

      return;
    }

    const items = [...data];
    const sourceIndex = items.indexOf(draggingItem);
    const destinationIndex = items.indexOf(targetItem);

    if (sourceIndex === destinationIndex) {
      return;
    }

    if (sourceIndex === -1 || destinationIndex === -1) {
      return;
    }

    items.splice(sourceIndex, 1);
    items.splice(destinationIndex, 0, draggingItem);

    setData(items);
    handleUpdateOrderListOfExercises(items);
  }

  return (
    <div className="grid grid-cols-[repeat(auto-fill,330px)] gap-6 mt-4">
      {data.map((exercise, exercise_index) => (
        <Card
          draggable={true}
          onDragEnd={handleDragEnd}
          onDragOver={handleDragOver}
          onDrop={() => handleDrop(exercise)}
          key={exercise?.id + "_" + exercise_index}
          onDragStart={(e) => handleDragStart(e, exercise)}
          className={cn(
            "p-4 flex items-center bg-primary border-0 cursor-move relative",
            exercise?.id === draggingItem?.id
              ? "border-dashed border-2 border-white opacity-5"
              : ""
          )}
        >
          {isLoading && (
            <div className="absolute top-0 right-0 left-0 bottom-0 backdrop-blur-sm">
              <Loader2Icon className="h-5 w-5 animate-spin m-5 mx-auto" />
            </div>
          )}
          <div className="h-full flex flex-1 flex-col">
            <div className="flex flex-row text-zinc-300 mb-3 items-center">
              <span className="text-xl font-bold border rounded-md w-10 h-10 mr-2 justify-center items-center flex">
                {(exercise?.execution?.order || 0) + 1 + "º"}
              </span>
              <h3 className="text-sm font-semibold flex-1">{exercise?.name}</h3>
            </div>

            <div className="flex flex-row h-full">
              <SheetExerciseVideo exercise={exercise} />

              <div className="flex flex-col flex-1 ml-5">
                <div className="flex flex-col gap-1 bg-zinc-300 w-fit px-2 py-1 rounded-md text-black ml-auto text-sm">
                  {exercise?.type?.name}
                </div>
                <div className="flex flex-1 flex-col text-xs text-zinc-400 mt-4 gap-4">
                  <div className="flex flex-row justify-between">
                    {exercise?.execution?.sets ? (
                      <div className="flex flex-1 flex-col">
                        <span>Series</span>
                        <span className="text-[11px]">
                          {exercise?.execution?.sets}
                        </span>
                      </div>
                    ) : null}

                    {exercise?.execution?.repetitions ? (
                      <div className="flex flex-1 flex-col text-right">
                        <span>Repet.</span>
                        <span className="text-[11px]">
                          {exercise?.execution?.repetitions}
                        </span>
                      </div>
                    ) : null}
                  </div>

                  <div className="flex flex-row justify-between">
                    {exercise?.execution?.cadence ? (
                      <div className="flex flex-1 flex-col">
                        <span>Cadência</span>
                        <span className="text-[11px]">
                          {exercise?.execution?.cadence}
                        </span>
                      </div>
                    ) : null}

                    {exercise?.execution?.interval ? (
                      <div className="flex flex-1 flex-col text-right">
                        <span>Intervalo</span>
                        <span className="text-[11px]">
                          {exercise?.execution?.interval}
                        </span>
                      </div>
                    ) : null}
                  </div>

                  <div className="flex flex-row justify-between">
                    {exercise?.execution?.load ? (
                      <div className="flex flex-col">
                        <span>Carga Sugerida</span>
                        <span className="text-[11px]">
                          {exercise?.execution?.load}
                        </span>
                      </div>
                    ) : null}
                  </div>

                  <div className="flex flex-row justify-between">
                    {exercise?.execution?.observation ? (
                      <div className="flex flex-col">
                        <span>Observação</span>
                        <span className="text-[11px]">
                          {exercise?.execution?.observation}
                        </span>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>

            <div className="flex gap-2 mt-4 justify-between">
              {handleShowExecutions && (
                <Button
                  size="xs"
                  type="button"
                  onClick={() => handleShowExecutions(exercise)}
                  variant="secondary"
                  className="flex px-4"
                >
                  <DumbbellIcon size={16} />
                </Button>
              )}

              <div className="flex flex-row gap-2">
                {handleEdit && Boolean(sheetId) ? (
                  <Button
                    size="xs"
                    type="button"
                    onClick={() => handleEdit(exercise)}
                    variant="secondary"
                    className="flex px-4"
                  >
                    <EditIcon size={16} />
                  </Button>
                ) : null}

                {!exercise?.execution?.trainingExerciseExecutions?.length ? (
                  <Button
                    size="xs"
                    type="button"
                    onClick={() => handleRemove(exercise, exercise_index)}
                    variant="secondary"
                    className="flex px-4"
                  >
                    <Trash2Icon size={16} />
                  </Button>
                ) : null}
              </div>
            </div>
          </div>
        </Card>
      ))}
    </div>
  );
};

export { SheetTableExercisesAdded, SheetGridExercisesAdded };
