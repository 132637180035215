import RootLayout from "@/components/layout";
import { ExerciseTypeService } from "@/services/api/exercise-type";
import { ExerciseType } from "@/utils/entities";
import { useQuery } from "@tanstack/react-query";
import ExerciseTypeForm from "./form";
import { useState } from "react";
import TableDataTypesExercise from "./components/table-data";

export default function ExerciseTypeListScreen() {
  const [exercise, setExercise] = useState<ExerciseType>();

  const { data, refetch } = useQuery({
    queryKey: ["execise_types"],
    queryFn: ExerciseTypeService.getAll,
  });

  return (
    <RootLayout>
      <div className="grid grid-cols-5 gap-12">
        <div className="col-span-3">
          {data ? (
            <TableDataTypesExercise
              data={data}
              onEdit={(item) => setExercise(item)}
              onCancel={() => setExercise(undefined)}
              onSuccessful={() => refetch()}
            />
          ) : null}
        </div>

        <div className="col-span-2 space-y-6">
          <ExerciseTypeForm
            exercise={exercise}
            onCancel={() => setExercise(undefined)}
            onSuccessful={refetch}
          />
        </div>
      </div>
    </RootLayout>
  );
}
