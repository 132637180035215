import api from "@/services/api";
import { User } from "@/utils/entities";
import { getDateFormatText } from "@/utils/functions/date-transform";

type LoginResponseData = {
  token: string;
  user: User;
};

type UserUpdateData = Pick<User, "id" | "status">;

type UserUpdatePasswordData = { token: string; password: string };

export function parseUser(user: User) {
  const full_name = [user?.name, user?.surname].filter(Boolean).join(" ");
  const contract_date_text = getDateFormatText(user?.contract_date);

  return { ...user, full_name, contract_date_text };
}

export async function userLogin(data: any) {
  return (await api.post("/user/login/web", data)).data as LoginResponseData;
}

export async function userUpdate(data: UserUpdateData) {
  return (await api.put("/user/update", data)).data;
}

export async function userForgotPassword(email: string) {
  return (await api.post("/user/forgot-password", { email })).data;
}

export async function userUpdatePassword(data: UserUpdatePasswordData) {
  return (await api.post("/user/update-password", data)).data;
}

export async function userActivate(data: UserUpdatePasswordData) {
  return (await api.post("/user/active", data)).data;
}

export async function deleteUser(id: string) {
  return (await api.delete(`/user/${id}`)).data;
}

export const UserService = {
  login: userLogin,
  update: userUpdate,
  forgotPassword: userForgotPassword,
  updatePassword: userUpdatePassword,
  activate: userActivate,
  delete: deleteUser,
};
