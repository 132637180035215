export type DefaultErrorResponse = {
  response: {
    data: {
      message: string;
    };
  };
};

export enum UserType {
  ADMIN = "ADMIN",
  STUDENT = "STUDENT",
  PROFESSIONAL = "PROFESSIONAL",
}

export enum GenericStatus {
  ACTIVE = "ACTIVE",
  PENDING = "PENDING",
  BLOCKED = "BLOCKED",
  INACTIVE = "INACTIVE",
}

export enum UserStatus {
  ACTIVE = "ACTIVE",
  PENDING = "PENDING",
  BLOCKED = "BLOCKED",
  INACTIVE = "INACTIVE",
}

export type Professional = {
  id: string;
  birth_date: string;
  students_amount_enroll: number;
  theme?: Theme;
  user: User;
  birth_date_text?: string;
};

export type Theme = {
  primary_color?: string;
  terms_of_use?: string;
  logo_image_url?: string;
  welcome_message?: string;
  benefits_message?: string;
};

export type PhysicalAssessmentField = {
  id: string;
  title: "plaining" | "results";
  questions: { title: string; answer: string }[];
};

export type PhysicalAssessment = {
  id: string;
  front_photo: string;
  back_photo: string;
  side_photo: string;
  studentId: string;
  form_answer: string | PhysicalAssessmentField;
  createdAt: string;
};

export type Student = {
  id: string;
  sex: string;
  date_of_birth?: string;
  profession?: string;
  weekly_work_hours?: string;
  gym_name?: string;
  how_know_about_us?: string;
  terms_accepted: boolean;
  userId: string;
  professionalId: string;
  createdAt: string;
  groupId?: string;
  group?: StudentGroup;
  form_answer?: any;
  service_name?: string;
  user: User;
  physical_assessments?: PhysicalAssessment[];
};

export type StudentGroup = {
  id?: string;
  name: string;
  description: string;
};

export type User = {
  id: string;
  name: string;
  surname: string;
  full_name?: string;
  phone: string;
  email: string;
  contract_date: string;
  contract_date_text?: string;
  image_url: string;
  type: UserType;
  status: UserStatus;
  statusText: string | undefined;
  professional?: Professional;
  createdAt?: string;
};

export type StudentFoodPlan = {
  id: string;
  title: string;
  status: string;
  file_url: string;
  createdAt: string;
  studentId: string;
  isActive?: boolean;
  statusText: string;
  createdAtText?: string;
};

export type StudentAttachment = {
  id: string;
  title: string;
  file_url: string;
  createdAt: string;
  studentId: string;
  createdAtText?: string;
};

export type ExerciseType = {
  id: string;
  name: string;
  status: string;
  isActive: boolean;
  statusText: string;
  createdAt: string;
  createdAtText: string;
};

export type TrainingExerciseExecution = {
  id: string;
  date: string;
  load_used: string;
  studentId: string;
  trainingExerciseId: string;
};

export type ExerciseExecution = {
  id?: string;
  order?: number;
  sets?: string;
  load?: string;
  cadence?: string;
  interval?: string;
  repetitions?: string;
  observation?: string;
  trainingId?: string;
  exerciseId?: string;
  trainingExerciseExecutions?: TrainingExerciseExecution[] | undefined;
};

export type Exercise = {
  id: string;
  name: string;
  video_url: string;
  status: string;
  isActive: boolean;
  statusText: string;
  professionalId: string;
  exerciseTypeId: string;
  createdAt: string;
  createdAtText?: string;
  updatedAt: string;
  updatedAtText?: string;
  type: Pick<ExerciseType, "id" | "name">;
  execution?: ExerciseExecution;
  trainingExercises?: ExerciseExecution[];
};

export type Training = {
  id?: string;
  sheet?: Sheet;
  name: string;
  index: number;
  exercises: Exercise[];
};

export type Sheet = {
  id: string;
  name: string;
  expire_date: string;
  expireDateText?: string;
  status: GenericStatus;
  statusText: string;
  studentId: string;
  createdAt: string;
  createdAtText?: string;
  isActive: boolean;
  trainings: Training[];
};

export type SheetTemplate = {
  id: string;
  name: string;
  data: Training[];
};

export type MemberCategory = {
  id?: string;
  name: string;
};

export type MemberContentFile = {
  id?: string;
  type: string;
  fileUrl: string;
};

export type MemberContent = {
  id?: string;
  type: string;
  title: string;
  fileUrl: string;
  categoryId: string;
  isPublished: boolean;
  category?: MemberCategory;
  userGroupId?: string[];
  groups?: Array<{ id: string; name: string }>;
  files?: Array<MemberContentFile>;
};
