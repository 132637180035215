import { useToast } from "@/components/ui/use-toast";
import { ExerciseTypeService } from "@/services/api/exercise-type";
import { ExerciseType } from "@/utils/entities";
import { getToastErrorContent } from "@/utils/functions/api-error";
import * as React from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Button } from "@/components/ui/button";
import { EditIcon, TrashIcon } from "lucide-react";
import ModalConfirmDelete from "@/components/modal-confirm";

export type TableRecordsProps = {
  data: ExerciseType[];
  onEdit: (item: ExerciseType) => void;
  onCancel: () => void;
  onSuccessful: (item: ExerciseType) => void;
};

export default function TableDataTypesExercise({
  data,
  onEdit,
  onCancel,
  onSuccessful,
}: TableRecordsProps) {
  const { toast } = useToast();
  const [exercise, setExercise] = React.useState<ExerciseType | null>(null);

  const onSubmitDelete = async () => {
    if (!exercise) {
      return;
    }
    try {
      await ExerciseTypeService.delete(exercise.id);

      toast({
        variant: "success",
        description: "O tipo de exercício foi removido com sucesso.",
      });

      setExercise(null);

      onSuccessful?.(exercise);
    } catch (error) {
      console.log(error);

      toast(getToastErrorContent(error));
    }
  };

  const handleDelete = (item: ExerciseType) => {
    onCancel?.();

    setExercise(item);
  };

  return (
    <>
      <Card className="bg-gray-800">
        <CardHeader>
          <CardTitle className="flex text-gray-200 items-center justify-between">
            Tipos de Exercícios
          </CardTitle>
        </CardHeader>
        <CardContent>
          <Table>
            <TableCaption>Lista de tipos de exercícios.</TableCaption>
            <TableHeader>
              <TableRow>
                <TableHead className="text-gray-400 w-[250px]">Nome</TableHead>
                <TableHead className="text-gray-400 w-[100px]">
                  Status
                </TableHead>
                <TableHead />
              </TableRow>
            </TableHeader>
            <TableBody className="text-gray-200">
              {data.map((item) => (
                <TableRow key={item?.id}>
                  <TableCell>{item?.name}</TableCell>
                  <TableCell>{item?.statusText}</TableCell>
                  <TableCell className="text-right space-x-2 px-0">
                    <Button
                      size="sm"
                      variant="ghost"
                      onClick={() => onEdit(item)}
                    >
                      <EditIcon className="h-3 w-3 mr-2" /> Editar
                    </Button>
                    <Button
                      size="sm"
                      variant="ghost"
                      onClick={() => handleDelete(item)}
                    >
                      <TrashIcon className="h-3 w-3 mr-2" /> Apagar
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>

      <ModalConfirmDelete
        isVisible={!!exercise}
        onCancel={() => setExercise(null)}
        onContinue={() => onSubmitDelete()}
        title="Atenção"
        description="O tipo de exercício será removido e não poderá mais ser utilizado. Deseja continuar ? "
      />
    </>
  );
}
