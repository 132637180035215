import RootLayout from "@/components/layout";
import { useUserProvider } from "@/utils/providers/user-provider";
import StudentWithoutGroup from "./components/students-without-group";
import ProfessionalLink from "./components/professional-link";

export default function HomeScreen() {
  const { user } = useUserProvider();

  return (
    <RootLayout>
      <div className="flex flex-col">
        {user?.professional?.id && (
          <>
            <ProfessionalLink id={user?.professional?.id} />

            <StudentWithoutGroup />
          </>
        )}
      </div>
    </RootLayout>
  );
}
