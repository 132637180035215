import { cn } from "@/utils";
import { Exercise } from "@/utils/entities";
import CoverVideo from "../../../../assets/cover.webp";

type SheetExercisePropsVideo = {
  exercise?: Exercise;
} & React.VideoHTMLAttributes<HTMLVideoElement>;

export default function SheetExerciseVideo({
  exercise,
  className,
}: SheetExercisePropsVideo) {
  if (exercise?.video_url) {
    return (
      <video
        controls
        className={cn(
          "aspect-[3/4] w-[140px] h-auto transition-all rounded-md",
          className
        )}
        poster={CoverVideo}
        src={exercise.video_url}
      />
    );
  }

  return (
    <img
      src="https://placehold.co/150x300"
      alt={exercise?.name}
      className={cn(
        "h-[200px] w-[120px] object-cover portrait aspect-[3/4] transition-all rounded-md hover:rounded-md",
        className
      )}
    />
  );
}
