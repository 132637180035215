import * as React from "react";
import ModalConfirmDelete from "@/components/modal-confirm";
import { useToast } from "@/components/ui/use-toast";
import { SheetService } from "@/services/api/sheet";

type Props = {
  sheetId: string | undefined;
  studentId: string | undefined;
  isVisible: boolean;
  handleCancel: () => void;
  handleSuccess: () => void;
};

export default function ModalRemoveSheet({
  sheetId,
  isVisible,
  studentId,
  handleCancel,
  handleSuccess,
}: Props) {
  const { toast } = useToast();
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  async function handleSubmit() {
    try {
      if (!sheetId || !studentId) {
        return;
      }

      setIsSubmitting(true);

      await SheetService.removeSheetOfStudent({
        sheetId,
        studentId,
      });

      setIsSubmitting(false);

      handleSuccess();

      toast({
        variant: "success",
        description: "Ficha revmovida com sucesso!",
      });
    } catch (error) {
      toast({
        variant: "destructive",
        description: "Não foi possível excluir a ficha do aluno",
      });
    }
  }

  if (!isVisible) {
    return;
  }

  return (
    <ModalConfirmDelete
      title="Atenção"
      isVisible={true}
      onCancel={() => handleCancel()}
      isLoading={isSubmitting}
      onContinue={handleSubmit}
      description={
        <span>
          Você tem certeza que quer <strong>remover</strong> a ficha do aluno?
        </span>
      }
    />
  );
}
