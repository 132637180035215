import { applyPhoneMask } from "@/utils/functions/phone-mask";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "@/components/ui/button";
import { Input, InputPassword } from "@/components/ui/input";
import { useForm } from "react-hook-form";
import { Loader2 } from "lucide-react";
import * as z from "zod";
import {
  Card,
  CardTitle,
  CardHeader,
  CardFooter,
  CardContent,
} from "@/components/ui/card";
import {
  Form,
  FormItem,
  FormLabel,
  FormField,
  FormMessage,
  FormControl,
} from "@/components/ui/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Label } from "@/components/ui/label";

const FormSchema = z
  .object({
    email: z
      .string({ required_error: "Campo obrigatório" })
      .email("Email inválido"),
    sex: z.string({ required_error: "Campo obrigatório" }),
    phone: z
      .string({ required_error: "Campo obrigatório" })
      .min(14, { message: "Número invalido" }),
    name: z.string({ required_error: "Campo obrigatório" }),
    surname: z.string({ required_error: "Campo obrigatório" }),
    password: z.string({ required_error: "Campo obrigatório" }),
    date_of_birth: z.string({ required_error: "Campo obrigatório" }),
    confirmPassword: z.string({ required_error: "Campo obrigatório" }),
    gym_name: z.string().optional(),
    how_know_about_us: z.string().optional(),
    profession: z.string().optional(),
    service_name: z.string(),
    weekly_work_hours: z.string().optional(),
  })
  .superRefine(({ confirmPassword, password }, ctx) => {
    if (confirmPassword !== password) {
      ctx.addIssue({
        code: "custom",
        path: ["confirmPassword"],
        message: "Os senhas não coincidem",
      });
    }
  });

export type FormSchemaType = z.infer<typeof FormSchema>;

type Props = { onSubmit: (values: FormSchemaType) => void };

const AccountForm: React.FC<Props> = ({ onSubmit }) => {
  const form = useForm<FormSchemaType>({
    resolver: zodResolver(FormSchema),
  });

  const { isSubmitting } = form.formState;

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Card className="m-10">
          <div className="flex flex-row items-center">
            <CardHeader>
              <CardTitle className="text-gray-800 items-start justify-start">
                Formulário de inscrição
              </CardTitle>
            </CardHeader>
          </div>
          <CardContent className="grid gap-10">
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
              <div className="grid gap-3">
                <FormField
                  name="name"
                  control={form.control}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-gray-600">Nome</FormLabel>

                      <FormControl>
                        <Input
                          className="bg-gray-100 text-gray-600"
                          {...field}
                        />
                      </FormControl>

                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="grid gap-3">
                <FormField
                  name="surname"
                  control={form.control}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-gray-600">Sobrenome</FormLabel>

                      <FormControl>
                        <Input
                          className="bg-gray-100 text-gray-600"
                          {...field}
                        />
                      </FormControl>

                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </div>
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
              <div className="grid gap-3">
                <FormField
                  name="email"
                  control={form.control}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-gray-600">Email</FormLabel>

                      <FormControl>
                        <Input
                          placeholder="email@email.com"
                          className="bg-gray-100 text-gray-600 disabled"
                          {...field}
                        />
                      </FormControl>

                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="grid gap-3">
                <FormField
                  name="phone"
                  control={form.control}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-gray-600">Whastapp</FormLabel>

                      <FormControl>
                        <Input
                          type="tel"
                          placeholder="(85) 99999-9999"
                          className="bg-gray-100 text-gray-600"
                          {...field}
                          onChange={(event) =>
                            field.onChange(applyPhoneMask(event.target.value))
                          }
                        />
                      </FormControl>

                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </div>
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
              <div className="grid gap-3">
                <FormField
                  name="password"
                  control={form.control}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-gray-600">Senha</FormLabel>

                      <FormControl>
                        <InputPassword
                          className="bg-gray-100 text-gray-600"
                          {...field}
                        />
                      </FormControl>

                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="grid gap-3">
                <FormField
                  name="confirmPassword"
                  control={form.control}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-gray-600">
                        Confirme a senha
                      </FormLabel>

                      <FormControl>
                        <InputPassword
                          className="bg-gray-100 text-gray-600"
                          {...field}
                        />
                      </FormControl>

                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </div>

            <hr />

            <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
              <div className="grid gap-3">
                <FormField
                  name="sex"
                  control={form.control}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-gray-600">Sexo</FormLabel>

                      <RadioGroup
                        className="flex"
                        value={field.value}
                        onValueChange={field.onChange}
                      >
                        <div className="flex items-center ">
                          <RadioGroupItem value="M" id="M" />
                          <Label
                            className={`ml-1 cursor-pointer ${
                              field.value === "M" && "font-bold"
                            }`}
                            htmlFor="M"
                          >
                            Masculino
                          </Label>
                        </div>

                        <div className="flex items-center ">
                          <RadioGroupItem value="F" id="F" />
                          <Label
                            className={`ml-1 cursor-pointer ${
                              field.value === "F" && "font-bold"
                            }`}
                            htmlFor="F"
                          >
                            Feminino
                          </Label>
                        </div>
                      </RadioGroup>

                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="grid gap-3">
                <FormField
                  name="date_of_birth"
                  control={form.control}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-gray-600">
                        Data de nascimento
                      </FormLabel>

                      <FormControl>
                        <Input
                          type="date"
                          className="bg-gray-100 text-gray-600 "
                          {...field}
                        />
                      </FormControl>

                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </div>

            <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
              <div className="grid gap-3">
                <FormField
                  name="profession"
                  control={form.control}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-gray-600">
                        Qual sua profissão?
                      </FormLabel>

                      <FormControl>
                        <Input
                          type="text"
                          className="bg-gray-100 text-gray-600 "
                          {...field}
                        />
                      </FormControl>

                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="grid gap-3">
                <FormField
                  name="weekly_work_hours"
                  control={form.control}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-gray-600">
                        Qual carga horaria de trabalho?
                      </FormLabel>

                      <FormControl>
                        <Input
                          type="text"
                          className="bg-gray-100 text-gray-600 "
                          {...field}
                        />
                      </FormControl>

                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </div>

            <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
              <div className="grid gap-3">
                <FormField
                  name="gym_name"
                  control={form.control}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-gray-600">
                        Qual o nome da sua academia?
                      </FormLabel>

                      <FormControl>
                        <Input
                          type="text"
                          className="bg-gray-100 text-gray-600 "
                          {...field}
                        />
                      </FormControl>

                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="grid gap-3">
                <FormField
                  name="how_know_about_us"
                  control={form.control}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-gray-600">
                        Como ficou sabendo da consultoria?
                      </FormLabel>

                      <FormControl>
                        <Select
                          value={field.value}
                          onValueChange={field.onChange}
                        >
                          <FormControl>
                            <SelectTrigger className="data-[placeholder]:text-gray-500">
                              <SelectValue placeholder="Selecione" />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            <SelectItem value={"Facebook"}>
                              {"Facebook"}
                            </SelectItem>
                            <SelectItem value={"Instagram"}>
                              {"Instagram"}
                            </SelectItem>
                            <SelectItem value={"Youtube"}>
                              {"Youtube"}
                            </SelectItem>
                            <SelectItem value={"Indicação de um amigo"}>
                              {"Indicação de um amigo"}
                            </SelectItem>
                            <SelectItem value={"Outro canal"}>
                              {"Outro canal"}
                            </SelectItem>
                          </SelectContent>
                        </Select>
                      </FormControl>

                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </div>

            <div className="grid gap-3">
              <FormField
                name="service_name"
                control={form.control}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-gray-600">
                      Deseja contratar qual serviço da consultoria?
                    </FormLabel>

                    <FormControl>
                      <Select
                        value={field.value}
                        onValueChange={field.onChange}
                      >
                        <FormControl>
                          <SelectTrigger className="data-[placeholder]:text-gray-500">
                            <SelectValue placeholder="Selecione" />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          <SelectItem value={"Personal"}>
                            {"Personal"}
                          </SelectItem>
                          <SelectItem value={"Nutrição"}>
                            {"Nutrição"}
                          </SelectItem>
                          <SelectItem value={"Personal / Nutrição"}>
                            Personal / Nutrição
                          </SelectItem>
                        </SelectContent>
                      </Select>
                    </FormControl>

                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <hr />
          </CardContent>
          <CardFooter className="justify-end space-x-2">
            <Button disabled={isSubmitting} className="px-8 hover:bg-gray-900">
              {isSubmitting && (
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              )}
              Inscrever-se
            </Button>
          </CardFooter>
        </Card>
      </form>
    </Form>
  );
};

export default AccountForm;
