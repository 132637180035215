import React from "react";
import Spinner from "./spinner";

export const Loading: React.FC = () => {
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 999,
        backgroundColor: "rgba(255,255,255, 0.3)",
      }}
    >
      <Spinner className="h-10 w-10 text-gray-800" />
    </div>
  );
};
