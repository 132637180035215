import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import { Loading } from "@/components/ui/loading";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Separator } from "@/components/ui/separator";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from "@/components/ui/sheet";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { StudentService } from "@/services/api/student";
import { StudentGroupService } from "@/services/api/student-group";
import { cn } from "@/utils";
import { Student, StudentGroup } from "@/utils/entities";
import { getDateFormatText } from "@/utils/functions/date-transform";
import { differenceInDays, differenceInYears, format } from "date-fns";
import {
  Briefcase,
  CalendarIcon,
  ClipboardCheck,
  ClipboardX,
  Dumbbell,
  Ear,
  LayoutGridIcon,
  Mail,
  Phone,
  PlusIcon,
  TableIcon,
  TargetIcon,
} from "lucide-react";
import { useCallback, useEffect, useState } from "react";

type StudentContentType = "grid" | "table";

const formatSex = (sex: string) => {
  if (sex === "F") return "🙋‍♀️";
  return "🙋‍♂️";
};

const formatDaysOfAccessLeft = (contractDate?: string) => {
  if (!contractDate) return 0;
  const days = differenceInDays(new Date(contractDate || ""), new Date());

  if (days < 0) return 0;

  return days;
};

const StudentWithoutGroup = () => {
  const [data, setData] = useState<Student[]>();
  const [groups, setGroups] = useState<StudentGroup[]>([]);
  const [student, setStudent] = useState<Student>();
  const [groupId, setGroupId] = useState<string>();
  const [showMode, setShowMode] = useState<StudentContentType>("grid");
  const [isLoading, setIsLoading] = useState(true);
  const [userFormModal, setUserFormModal] = useState<Student>();

  const loadLists = useCallback(async () => {
    setIsLoading(true);
    const studentsResult = await StudentService.getWithoutGroup();
    const groupsResult = await StudentGroupService.getAll();
    setGroups(groupsResult);
    setData(studentsResult);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    loadLists();
  }, []);

  const closeModal = () => {
    setStudent(undefined);
    setGroupId(undefined);
  };

  const updateStudent = async () => {
    if (!student || !groupId) {
      return;
    }

    await StudentService.update({
      id: student.id,
      user: { id: student.user.id, contract_date: student.user.contract_date },
      groupId,
    } as any);

    closeModal();

    loadLists();
  };

  const onChangeDate = (day: Date | undefined) => {
    if (day && student) {
      setStudent({
        ...student,
        user: { ...student.user, contract_date: day.toJSON() },
      });
    }
  };

  function StudentsGrid() {
    return (
      <div className="grid gap-6 md:grid-cols-3">
        {data?.map((item) => (
          <Card key={item.id} className="bg-white max-w-sm">
            <CardTitle className="flex text-gray-700 items-center px-4 pt-2 text-lg">
              <p className="mr-2">{formatSex(item?.sex || "")}</p>
              {item?.user?.full_name}
              {", "}
              {item.date_of_birth
                ? differenceInYears(
                    new Date(),
                    new Date(item.date_of_birth || "")
                  )
                : 0}{" "}
              anos
            </CardTitle>
            <CardContent className="p-4 pb-2">
              <div className="flex flex-col gap-2">
                <div className="opacity-80 flex items-center text-xs">
                  <Mail className="mr-2" size={14} />
                  {item?.user?.email}
                </div>

                <div className="opacity-80 flex items-center text-xs">
                  <Phone className="mr-2" size={14} />
                  {item?.user?.phone}
                </div>

                <div className="opacity-80 flex items-center text-xs">
                  <Briefcase className="mr-2" size={14} />
                  {item?.profession || "Não informado"}
                  {` (${item.weekly_work_hours || "0"} horas por semana)`}
                </div>

                <div className="opacity-80 flex items-center text-xs">
                  <Dumbbell className="mr-2" size={14} />
                  {item?.gym_name || "Não informado"}
                </div>

                <div className="opacity-80 flex items-center text-xs">
                  <Ear className="mr-2" size={14} />
                  {item?.how_know_about_us || "Não informado"}
                </div>

                <div className="opacity-80 flex items-center text-xs">
                  <TargetIcon className="mr-2" size={14} />
                  {item?.service_name || "Não informado"}
                </div>

                <div className="opacity-80 flex items-center text-xs">
                  <CalendarIcon className="mr-2" size={14} />
                  Restam
                  <b className="mx-1">
                    {formatDaysOfAccessLeft(item.user.contract_date)}
                  </b>
                  dias de acesso
                </div>

                <div className="opacity-80 flex items-center text-xs mt-2 text-white">
                  {item.form_answer ? (
                    <div
                      className="border-[1px] bg-green-500 flex items-center px-2 py-1 mx-auto cursor-pointer rounded-full hover:opacity-75 transition-all"
                      onClick={() => setUserFormModal(item)}
                    >
                      <ClipboardCheck className="mr-2" size={16} />
                      Formulário respondido
                    </div>
                  ) : (
                    <div className="border-[1px] bg-red-500 flex items-center px-2 py-1 mx-auto rounded-full ">
                      <ClipboardX className="mr-2" size={16} /> Formulário não
                      respondido
                    </div>
                  )}
                </div>

                <Separator className="my-1" />

                <Button onClick={() => setStudent(item)} className="text-sm">
                  Adicionar a um grupo
                </Button>
              </div>
            </CardContent>
          </Card>
        ))}
      </div>
    );
  }

  function StudentTable() {
    return (
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="text-gray-400">Nome</TableHead>
            <TableHead className="text-gray-400 w-[200px]">Serviço</TableHead>
            <TableHead className="text-gray-400 w-[200px]">
              Data de nascimento
            </TableHead>
            <TableHead className="text-gray-400 w-[200px]">Telefone</TableHead>
            <TableHead className="text-gray-400 w-[200px]">Acesso</TableHead>
            <TableHead className="w-[100px] text-right" />
          </TableRow>
        </TableHeader>
        <TableBody className="text-gray-200">
          {data?.map((item) => (
            <TableRow key={item?.id}>
              <TableCell>{item?.user?.full_name}</TableCell>
              <TableCell>{item?.service_name}</TableCell>
              <TableCell>{item?.user?.phone}</TableCell>
              <TableCell>{getDateFormatText(item?.date_of_birth)}</TableCell>
              <TableCell>
                Restam {formatDaysOfAccessLeft(item.user.contract_date)} dias
              </TableCell>
              <TableCell>
                <Button
                  onClick={() => setStudent(item)}
                  size="xs"
                  variant="secondary"
                  className="p-2 gap-1"
                >
                  <PlusIcon className="w-4 h-4" /> Adicionar grupo
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }

  return (
    <div className="flex flex-row gap-4 mt-8 flex-wrap">
      {isLoading ? (
        <Loading />
      ) : (
        <Card className="bg-gray-800 w-full mb-10">
          <CardHeader className="flex flex-row justify-between">
            <div className="flex flex-col gap-2">
              <CardTitle className="flex text-gray-200 items-center justify-between">
                Inscrições de alunos
              </CardTitle>
              <CardDescription>({data?.length} alunos)</CardDescription>
            </div>

            <Tabs
              className="mt-5"
              defaultValue="grid"
              onValueChange={(value: string) =>
                setShowMode(value as StudentContentType)
              }
            >
              <TabsList>
                <TabsTrigger value="grid" className="px-3">
                  <LayoutGridIcon className="w-5 h-5" />
                </TabsTrigger>
                <TabsTrigger value="table" className="px-3">
                  <TableIcon className="w-5 h-5" />
                </TabsTrigger>
              </TabsList>
            </Tabs>
          </CardHeader>
          <CardContent>
            {showMode === "grid" ? <StudentsGrid /> : <StudentTable />}
          </CardContent>
        </Card>
      )}

      <Dialog open={!!student} onOpenChange={closeModal}>
        <DialogContent className="sm:max-w-[425px] bg-white">
          <DialogHeader className="border-b-[1px] border-black pb-2 mb-4">
            <DialogTitle>{student?.user.full_name}</DialogTitle>
          </DialogHeader>

          <Label>Grupo do usuário</Label>
          <Select value={groupId} onValueChange={setGroupId}>
            <SelectTrigger className="data-[placeholder]:text-gray-500">
              <SelectValue placeholder="Selecione um grupo" />
            </SelectTrigger>
            <SelectContent>
              {groups.map((item) => (
                <SelectItem key={item.id} value={item.id || ""}>
                  {item.name}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>

          <Label className="mt-4">Nova data de vencimento do contrato</Label>
          <Popover>
            <PopoverTrigger asChild>
              <Button
                variant={"outline"}
                className={cn(
                  "w-full justify-start text-left font-normal",
                  !student?.user.contract_date && "text-muted-foreground"
                )}
              >
                <CalendarIcon className="mr-2 h-4 w-4" />
                {student?.user.contract_date ? (
                  format(student?.user.contract_date, "dd/MM/yyyy")
                ) : (
                  <span>Selecione uma data</span>
                )}
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0" align="start">
              <Calendar
                className="w-full"
                mode="single"
                onSelect={onChangeDate}
                selected={
                  student?.user.contract_date
                    ? new Date(student?.user.contract_date)
                    : undefined
                }
                initialFocus
              />
            </PopoverContent>
          </Popover>

          <DialogFooter className="mt-4">
            <Button variant="outline" onClick={closeModal} type="button">
              Cancelar
            </Button>
            <Button disabled={!groupId} type="button" onClick={updateStudent}>
              Salvar
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <Sheet
        open={!!userFormModal}
        onOpenChange={() => setUserFormModal(undefined)}
      >
        <SheetContent className="bg-white overflow-scroll max-h-screen">
          <SheetHeader>
            <SheetTitle>{userFormModal?.user.full_name}</SheetTitle>
            {userFormModal?.form_answer &&
              Object.keys(userFormModal?.form_answer).map(
                (questionnaireKey, index) => {
                  if (questionnaireKey === "title") return "";
                  return (
                    <div key={questionnaireKey}>
                      <h3>
                        {index +
                          1 +
                          " - " +
                          userFormModal?.form_answer[questionnaireKey].title}
                      </h3>
                      <div className="pl-4">
                        {Object.keys(
                          userFormModal?.form_answer[questionnaireKey]
                        ).map((questionKey) => {
                          return (
                            <div
                              className="py-2 overflow-scroll border-t-2"
                              key={questionnaireKey + "_" + questionnaireKey}
                            >
                              <p className="font-bold text-xs">
                                {
                                  userFormModal?.form_answer[questionnaireKey][
                                    questionKey
                                  ].title
                                }
                              </p>
                              {
                                userFormModal?.form_answer[questionnaireKey][
                                  questionKey
                                ].answer
                              }
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                }
              )}
          </SheetHeader>
        </SheetContent>
      </Sheet>
    </div>
  );
};

export default StudentWithoutGroup;
