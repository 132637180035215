import RootLayout from "@/components/layout";
import { ProfessionalService } from "@/services/api/professional";
import { useQuery } from "@tanstack/react-query";
import { Loader2 } from "lucide-react";
import { useParams } from "react-router-dom";
import ProfessionalForm from "./components/form";
import { Professional } from "@/utils/entities";

export default function ProfessionalEditScreen() {
  const { id } = useParams();

  const { data, isFetching } = useQuery({
    queryKey: ["professional", id],
    queryFn: () => ProfessionalService.getOne(id as string),
  });

  const professional = data as Professional;

  return (
    <RootLayout>
      {isFetching ? (
        <Loader2 className="h-full m-auto h-12 w-12 animate-spin text-white" />
      ) : (
        <div className="grid gap-12">
          <ProfessionalForm professional={professional} mode="UPDATE" />
        </div>
      )}
    </RootLayout>
  );
}
